import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cb87851a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "alerts-container"
}
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CrowSuccessSVG = _resolveComponent("CrowSuccessSVG")!
  const _component_CrowErrorSVG = _resolveComponent("CrowErrorSVG")!
  const _component_SuccessSVG = _resolveComponent("SuccessSVG")!
  const _component_ErrorSVG = _resolveComponent("ErrorSVG")!

  return (_ctx.alerts.length !== 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alerts, (alert, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(["alert", {
                [alert.class]: true
            }]),
            role: "alert"
          }, [
            (alert.class == 'alert-success')
              ? (_openBlock(), _createBlock(_component_CrowSuccessSVG, {
                  key: 0,
                  class: "crow"
                }))
              : _createCommentVNode("", true),
            (alert.class == 'alert-danger')
              ? (_openBlock(), _createBlock(_component_CrowErrorSVG, {
                  key: 1,
                  class: "crow"
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_2, [
              (alert.class == 'alert-success')
                ? (_openBlock(), _createBlock(_component_SuccessSVG, {
                    key: 0,
                    class: "symbol"
                  }))
                : _createCommentVNode("", true),
              (alert.class == 'alert-danger')
                ? (_openBlock(), _createBlock(_component_ErrorSVG, {
                    key: 1,
                    class: "symbol"
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_3, _toDisplayString(alert.class == 'alert-success' ? 'Успешно!' : 'Ошибка!'), 1),
              _createElementVNode("span", {
                innerHTML: alert.text
              }, null, 8, _hoisted_4),
              _createElementVNode("button", {
                type: "button",
                class: "btn-close",
                onClick: ($event: any) => (_ctx.closeAlert(alert.id))
              }, "X", 8, _hoisted_5)
            ])
          ], 2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}