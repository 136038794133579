import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21ae60ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainView = _resolveComponent("MainView")!
  const _component_INeedThisView = _resolveComponent("INeedThisView")!
  const _component_HowItWorksView = _resolveComponent("HowItWorksView")!
  const _component_AdvantagesView = _resolveComponent("AdvantagesView")!
  const _component_WhatIWillHaveView = _resolveComponent("WhatIWillHaveView")!
  const _component_SubscribeView = _resolveComponent("SubscribeView")!
  const _component_ContactsView = _resolveComponent("ContactsView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MainView),
    _createVNode(_component_INeedThisView),
    _createVNode(_component_HowItWorksView),
    _createVNode(_component_AdvantagesView),
    _createVNode(_component_WhatIWillHaveView),
    _createVNode(_component_SubscribeView),
    _createVNode(_component_ContactsView)
  ]))
}