// app.ts (shared between server and client)
import { createSSRApp } from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
import { ObserveVisibility } from 'vue-observe-visibility'
import { createMetaManager } from 'vue-meta'

export function createApp() {
  const metaManager = createMetaManager(true);
  const app = createSSRApp(App).directive('observe-visibility', ObserveVisibility).use(store).use(router).use(metaManager);

  return { app: app, appRouter: router };
}