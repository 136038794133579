<template>
    <h1 style="marginTop: 100px;">Ошибка 404</h1>
    <div style="minHeight: 600px;">Страница не найдена. Пожалуйста, <router-link to='/'>перейдите на главную</router-link> и попробуйте снова.</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useMeta } from 'vue-meta';

export default defineComponent({
  name: 'Page404View',
  setup() {
    useMeta({ title: 'Страница не найдена'})
  }
});
</script>