<template>
    <div class="page container">
        <h1>Политика конфиденциальности</h1>
        <div><div>Перед началом использования сайта https://white-crows.ru (далее - Сайт), пожалуйста, ознакомьтесь с условиями настоящей политики конфиденциальности (далее - Политика конфиденциальности) и <a href="/user-agreement">Пользовательского соглашения</a> использования Сайта. Факт использования Сайта любым способом (как намеренным, так и непреднамеренным) признается акцептом, то есть полным и безоговорочным согласием любого пользователя Сайта (далее - Пользователь), использовать Сайт на изложенных ниже условиях. Использование Сайта допустимо исключительно на условиях <a href="/user-agreement">Пользовательского соглашения</a> и Политики конфиденциальности. Если Пользователь не принимает условия указанных документов в полном объеме, он не вправе использовать Сайт в каких-либо целях.</div>
<div><i>Мы очень серьезно относимся к конфиденциальности и безопасности ваших персональных данных. Мы придерживаемся необходимых мер безопасности, чтобы защитить ваши персональные данные. Поскольку при передаче данных в Интернете невозможно обеспечить абсолютную безопасность, мы не можем гарантировать сохранность персональных данных: существует риск того, что злоумышленники смогут обойти нашу систему безопасности или перехватить передачу вашей информации в Интернете. Вы обязаны хранить учетные данные в секрете и несете за это полную ответственность. Обратите внимание, что электронные письма обычно не шифруются и не считаются безопасными.</i></div>

<h2>1. Термины и определения</h2>
<div>В настоящей Политике конфиденциальности используются следующие термины:</div>
<div>1.1. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или определяемому Пользователю Сайта (физическому лицу, субъекту персональных данных). Пользователь несет ответственность за достоверность персональных данных, которые вы предоставляете IT admin. <br>
<i>Если вы предоставите недостоверные данные, то не сможете использовать все функции Сайта и получать полезную информацию, а мы не сможем связаться с вами. Например, всегда необходимо следить за актуальностью электронного адреса, поскольку это один из основных способов связи с вами.</i></div>

<div>1.2. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</div>
<div>1.3. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.</div>

<h2>2. Общие положения</h2>
<div>2.1. Настоящая Политика конфиденциальности представляет собой публичную оферту правообладателя Сайта ИП Шаламова Александра Сергеевна, ИНН 662007045961, e-mail: info@oros-it.ru (далее - Оператор, IT admin) и определяет порядок обработки и защиты информации Пользователей Сайта. </div>
<div>2.2. Целью настоящей Политики конфиденциальности является обеспечение надлежащей защиты информации о Пользователях, в том числе их персональных данных, от несанкционированного доступа и разглашения.</div>
<div>2.3. Отношения, связанные со сбором, хранением, распространением и защитой информации о Пользователях, регулируются настоящей Политикой конфиденциальности, иными официальными документами Оператора,  действующим законодательством Российской Федерации и Общим регламентом защиты персональных данных (General Data Protection Regulation).</div>
<div>2.4. Пользователь вправе использовать Сайт для изучения содержащейся на нем информации, не предоставляя Оператору каких-либо сведений о себе, и Оператор не будет обрабатывать персональные данные Пользователя, за исключением сведений, собираемых автоматически (как описано в Разделе 9 настоящей Политики). Оператор не обрабатывает биометрические персональные данные и специальные категории персональных данных.</div>
<div>2.5. При этом на Сайте представлены некоторые функции, которые Пользователь сможет использовать, только в случае если Оператор будет обрабатывать персональные данные Пользователя. В таком случае Оператор будет обрабатывать только те данные, которые Пользователь предоставит самостоятельно, за исключением сведений, собираемых автоматически (как указано в Разделе 9 настоящей Политики). </div>
<div>2.6. Пользователь может направить свою заявку с интересующим вопросом с использованием соответствующего функционала Сайта, указав свое имя, и адрес электронной почты. В данном случае Оператор обрабатывает данные Пользователя в целях предоставления полной информации Пользователю в удобном формате. </div>

<h2>3. Условия пользования  Сайтом</h2>
<div>3.1. Оператор, действуя разумно и добросовестно, считает, что при использовании Сайта Пользователь:</div>
<div>3.1.1. обладает всеми необходимыми правами, позволяющими ему использовать Сайт;</div>
<div>3.1.2. указывает достоверную информацию о себе в объемах, необходимых для пользования Сайтом;</div>
<div>3.1.3. ознакомлен с настоящей Политикой конфиденциальности, выражает свое согласие с ее положениями и принимает на себя указанные в ней права и обязанности.</div>
<div>3.2. Оператор не проверяет достоверность получаемой (собираемой) информации о Пользователях, за исключением случаев, когда такая проверка необходима в целях исполнения Оператором обязательств перед Пользователем.</div>

<h2>4. Состав информации о Пользователях</h2>
<div>4.1. Оператор может собирать следующие категории персональных данных о Пользователе во время использования Сайтов:</div>
<div>4.1.1. данные, позволяющие Оператору связываться с Пользователем («контактные данные»). Контактные данные могут включать имя, адрес электронной почты, номер телефона, почтовый адрес и/или идентификаторы учетной записи в социальных сетях. <br>
<i>Если вы войдете на наш Сайт с помощью учетной записи в социальной сети, мы получим элементы контактных данных от поставщика соответствующей учетной записи в социальной сети.</i></div>

<div>4.1.2. данные учетной записи Сайта. Данные учетной записи могут включать идентификатор учетной записи Пользователя, имя, адрес электронной почты, дату создания и изменения учетной записи, настройки Сайта и маркетинговые предпочтения.  <br>
<i>Если вы войдете на наш Сайт с помощью учетной записи в социальной сети, мы получим элементы данных учетной записи от соответствующего поставщика учетной записи в социальной сети.</i></div>
<div>4.1.3. информацию, касающуюся транзакций, включая покупки услуг, которые Пользователь заключает с Оператором, в том числе посредством сайта («данные транзакции»). Данные транзакции могут включать имя, контактные данные, данные вашей платежной карты (или другие платежные данные) и сведения о транзакции. </div>
<div>4.1.4. информацию, содержащуюся в любом сообщении, которое Пользователь отправляет Оператору или, которое Оператор отправляет Пользователю, или относящиеся к нему («коммуникационные данные»). Данные связи могут включать содержание сообщения и метаданные, связанные с сообщением. Наш Сайт будет генерировать метаданные, связанные с сообщениями, сделанными с использованием контактных форм Сайта.</div>
<div>4.1.5. данные об использовании Пользователем Сайта и услуг («данные об использовании»). Данные об использовании могут включать IP-адрес, географическое положение, тип и версию браузера, операционную систему, источник перехода, продолжительность посещения, просмотры страниц и пути навигации по Сайту, а также информацию о времени, частоте и характере использования услуг. Источником данных об использовании является система отслеживания аналитики Оператора.</div>
<div>4.1.6. данные по обучению Пользователя на Сайте.<br>
<i>Используя материалы с Сайта (в том числе обучающие), вы соглашаетесь на то, что мы можем собирать информацию о вашем обучении: например, задания, которые вы отправляете на проверку преподавателям, задания, оцененные сокурсниками, и отзывы сокурсников по оценке их заданий. Кроме того, мы собираем данные о курсе, в том числе ответы студентов на вопросы в видеоуроках, а также на вопросы тестов, экзаменов и опросов. Вы не должны указывать персональные данные или другую информацию личного либо конфиденциального характера при обращении к другому пользователю, выполнении заданий, прохождении экзаменов или опросов, кроме тех сведений, которые необходимы для выполнения указанных действий.</i></div>
<div>4.1.7. иная информация, необходимая для обработки в соответствии с условиями, регулирующими использование Сайта.</div>
<div>4.2. Пользователь предоставляет все данные лично. </div>
<div>4.3. Оператор не собирает данные с целью составления «портрета» Пользователя в той степени, при которой это может существенно повлиять на права Пользователя и свободы в соответствии с применимым законодательством.</div>

<h2>5. Цели обработки персональных данных</h2>
<div>5.1. Оператор всегда обрабатывает персональные данные в определенных целях и только те персональные данные, которые имеют отношение к достижению таких целей. В частности, Оператор, помимо указанных в настоящей Политике конфиденциальности целей, обрабатывает персональные данные Пользователей для следующих целей:</div>
<div>5.1.1. для целей работы Сайта, обработки и выполнения заказов, предоставления услуг, выставления счетов и другой документации, связанной с платежами; </div>
<div>5.1.2. для целей публикации таких данных на Сайте и в других местах в соответствии с вашими четкими инструкциями; </div>
<div>5.1.3. обрабатывать контактные данные, данные учетной записи, данные транзакций и/или данные связи для целей управления отношениями Пользователя и Оператора, общения с Пользователем по электронной почте, SMS, по почте, факсу, телефону, предоставление услуг поддержки и рассмотрение жалоб; </div>
<div>5.1.4. обрабатывать контактные данные, данные учетной записи и/или данные транзакций для целей создания, таргетинга и отправки прямых маркетинговых сообщений по электронной почте, SMS, почте и/или факсу и установления контакта по телефону для маркетинга; </div>
<div>5.1.5. обрабатывать данные об использовании и/или данные транзакций для целей исследования и анализа использования Сайта и услуг, а также исследования и анализа других взаимодействий с нашим бизнесом; </div>
<div>5.1.6. обрабатывать персональные данные Пользователя для целей создания и обслуживания баз данных Оператора, резервных копий таких баз данных и наших деловых записей в целом;</div>
<div>5.1.7. в целях безопасности и предотвращения мошенничества и другой преступной деятельности;</div>
<div>5.1.8. для целей получения или поддержания страхового покрытия, управления рисками и/или получения профессиональных консультаций;</div>
<div>5.1.9. для создания, исполнения или защиты судебных исков, будь то в судебном разбирательстве, в административной или внесудебной процедуре; </div>
<div>5.1.10. если такая обработка необходима для соблюдения юридического обязательства, которому подчиняется Оператор, или для защиты жизненно важных интересов Пользователя или жизненно важных интересов другого физического лица;</div>
<div>5.1.11. выполнение, возложенных законодательством Российской Федерации на Оператора функций, полномочий и обязанностей;</div>
<div>5.1.12. выполнение обязательств Оператора перед Пользователями в отношении использования Сайта;</div>
<div>5.1.13. осуществления связи с Пользователем для направления уведомлений, запросов и информации, относящейся к работе Сайта/Оператора, выполнения соглашений и обработки запросов Пользователя;</div>
<div>5.1.14. повышения удобства использования Сайта.</div>

<h2>6. Условия обработки персональных данных</h2>
<div>6.1. Обработка персональных данных осуществляется на основе принципов:</div>
<div>6.1.1. Законность, справедливость и прозрачность — должны быть легальные основания в рамках GDPR для сбора и использования данных, ненарушение любых законов, открытость, честность от начала и до конца об использовании персональных данных;</div>
<div>6.1.2. Ограничение целью — обработка должна сводиться к тому, что было заявлено Пользователю;</div>
<div>6.1.3. Минимизация данных — использование адекватного количества данных для выполнения поставленных целей, ограниченных только необходимым количеством;</div>
<div>6.1.4. Точность — персональные данные должны быть точными и не должны вводить в заблуждение; </div>
<div>6.1.5. Ограничение хранения данных — не хранить данные дольше, чем нужно, периодически проводить аудит данных и удалять неиспользуемые;</div>
<div>6.1.6. Целостность и конфиденциальность/безопасность — хранить данные в безопасном месте и уделять достаточное внимание сохранности данных;</div>
<div>6.1.7. Подотчётность — ответственность за обработку персональных данных и выполнение всех остальных принципов GDPR, включая записи о конфиденциальности; защите, использовании, проверки данных; назначении должностного лица по защите данных;</div>
<div>6.1.8. Добросовестность.</div>
<div>6.2. Оператор осуществляет обработку персональных данных Пользователя в целях исполнения договора между Оператором и Пользователем (<a href="/user-agreement">Пользовательское соглашение</a>). В силу статьи 6 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» отдельное согласие Пользователя на обработку его персональных данных не требуется. В силу п.п. 2 п. 2 статьи 22 указанного закона Оператор вправе осуществлять обработку персональных данных без уведомления уполномоченного органа по защите прав субъектов персональных данных. </div>

<h2>7. Хранение и использование персональных данных</h2>
<div><i>Мы будем хранить персональные данные не дольше, чем необходимо для выполнения целей, для которых они собирались и обрабатывались. Время хранения этих данных зависит от целей их сбора и использования и/или определяется применимым законодательством, а также целесообразностью установления, использования либо защиты наших законных прав.</i></div>
<div>7.1. Персональные данные пользователей хранятся исключительно на электронных носителях и обрабатываются с использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка персональных данных необходима в связи с исполнением требований законодательства.</div>
<div>7.2. Оператор принимает все необходимые меры для защиты персональных данных Пользователя от неправомерного доступа, изменения, раскрытия или уничтожения. Оператор предоставляет доступ к персональным данным Пользователя только тем лицам, которым эта информация необходима с целью исполнения назначения Сайта и для обеспечения функционирования Сайта, а также иных действий, незапрещённых российским законодательством. </div>
<div>7.3. Оператор вправе использовать предоставленную Пользователем информацию, в том числе персональные данные, в целях обеспечения соблюдения требований действующего законодательства Российской Федерации (в том числе в целях предупреждения и/или пресечения незаконных и/или противоправных действий Пользователей). </div>
<div>7.4. Раскрытие предоставленной Пользователем информации может быть произведено лишь в соответствии с действующим законодательством Российской Федерации по требованию суда, правоохранительных органов, а равно в иных предусмотренных законодательством Российской Федерации случаях. </div>
<div>7.5. Пользователь уведомлен и согласен с тем, что в целях контроля качества Оператор вправе проводить исследования переданной и полученной Пользователем информации в обезличенном агрегированном виде без возможности отнести такую информацию к прямо или косвенно определенному или определяемому Пользователю. </div>
<div>7.6. Оператор будет хранить персональные данные столько времени, сколько это необходимо для достижения цели, для которой она была собрана, или для соблюдения требований законодательства и нормативных актов.</div>

<h2>8. Права Пользователя</h2>
<div>8.1. Пользователь обладает следующими правами:</div>
<div>8.1.1. право доступа<br>
<i>вы можете запросить копии ваших личных данных;</i></div>
<div>8.1.2. право на исправление<br>
<i>вы можете попросить нас исправить неточные личные данные и заполнить неполные личные данные;</i></div>
<div>8.1.3. право на удаление<br>
<i>вы можете попросить нас удалить ваши личные данные;</i></div>
<div>8.1.4. право ограничивать обработку<br>
<i>вы можете попросить нас ограничить обработку ваших личных данных;</i></div>
<div>8.1.5. право возражать против обработки<br>
<i>вы можете возражать против обработки ваших личных данных;</i></div>
<div>8.1.6. право на переносимость данных<br>
<i>вы можете потребовать, чтобы мы передали ваши личные данные другой организации или вам;</i></div>
<div>8.1.7. право подать жалобу в надзорный орган<br>
<i>вы можете пожаловаться на обработку нами ваших личных данных;</i></div>
<div>8.1.8. право отозвать согласие<br>
<i>в той мере, в какой законным основанием для обработки нами ваших личных данных является согласие, вы можете отозвать это согласие;</i></div>
<div>8.1.9. иные права, предусмотренные применимым законодательством.</div>
<div>8.2. Для отзыва согласия на обработку персональных данных Пользователю необходимо направить соответствующий запрос по адресу: info@oros-it.ru. После отзыва согласия Оператор прекратит обработку данных, уничтожит персональные данные, а также обеспечит их уничтожение привлеченными к обработке третьими лицами (за исключением случаев, если дальнейшая обработка требуется в соответствии с применимым законодательством или может осуществляться на ином законном основании).</div>
<div>8.3. Еще одна часть нашего приоритета - это защита детей при использовании Интернета. Мы призываем родителей и опекунов наблюдать, участвовать и / или отслеживать и направлять их действия в Интернете.</div>
<div>8.4. It admin не собирает умышленно какую-либо личную информацию от детей младше 13 лет. Если вы считаете, что ваш ребенок предоставил такую &ZeroWidthSpace;&ZeroWidthSpace;информацию на нашем Cайте, мы настоятельно рекомендуем вам немедленно связаться с нами, и мы сделаем все возможное, чтобы незамедлительно удалите такую &ZeroWidthSpace;&ZeroWidthSpace;информацию из наших записей.</div>

<h2>9. Файлы cookies</h2>
<div>9.1. С целью улучшения работы Сайта и сделать его удобнее для использования, Оператор может использовать специальную технологию cookies. Оператор понимает, что в определенной степени файлы cookies должны рассматриваться в качестве персональных данных, и работа с ними должна регулироваться соответствующим законодательством. <br>
<i>Оператор попросит вас дать согласие на использование файлов cookie при первом посещении нашего Сайта.</i></div>
<div>9.2. Под файлами cookies понимаются маленькие файлы данных, которые Сайт хранит на устройстве Пользователя и используется для записи определенных действий на Сайте, путем хранения данных, которые могут быть обновлены и восстановлены. Эти файлы хранятся на устройстве Пользователя и содержат данные, которые не наносят ущерба оборудованию Пользователя. Они используются для записи предпочтений Пользователя, таких как выбранный язык и данные доступа, а также для персонализации Сайта. Cookies могут также использоваться для хранения информации о том, как Пользователь использует сайт. Например, с какой страницы или с какого рекламного «баннера» был выполнен переход на Сайт.</div>
<div>9.3. Сессионные файлы cookies - временные файлы cookies, которые хранятся среди файлов cookies браузера Пользователя до тех пор, пока Пользователь не покинет страницу; эти файлы не хранятся на жестком диске пользователя. Сведения, получаемые с помощью этих файлов, помогают нам анализировать направления сетевого трафика. В конечном итоге они позволяют Оператору улучшить содержание Сайта и упростить его использование.</div>
<div>9.4. Постоянные файлы cookies - эти файлы хранятся на жестком диске компьютера, и Сайт распознает их каждый раз, когда Пользователь заходит на него. У постоянных файлов cookies есть определенный срок действия. После истечения этого срока действия они перестают функционировать. Постоянные файлы cookies позволяют Сайту запоминать информацию и настройки для последующих визитов пользователей, делая таким образом просмотр сайтов более удобным и быстрым, ввиду, например, отсутствия необходимости повторного введения регистрационных данных.</div>
<div>9.5. Технические файлы cookies - технические файлы cookies необходимы для правильной работы Сайта и корректного использования различных предлагаемых на нем персональных возможностей и услуг. Например, такие cookies могут использоваться для технической поддержки работы сессии, контроля времени отклика, работы или подтверждения персональных предпочтений, использования элементов безопасности, передачи данных через социальные сети и т. д.</div>
<div>9.6. Файлы cookies для персонализации - эти файлы cookies позволяют пользователю выбрать или персонализировать определенные свойства Сайта, например, посредством определения языка, региональной конфигурации или типа браузера.</div>
<div>9.7. Аналитические файлы cookies - Оператор использует cookies для получения сведений о настройках браузеров и для анализа поведения Пользователя на Сайте для целей улучшения производительности Сайта.</div>
<div>9.8. Файлы cookies социальных сетей - Для того, чтобы облегчить Пользователю возможность делиться контентом с Сайта посредством различных социальных сетей на Сайте расположены специальные кнопки. Наличие таких кнопок может означать, что социальная сеть, с которой они связаны, устанавливает файлы cookies, которые не находятся под контролем Оператора, но при этом используются для запуска сессии Пользователя в социальной сети, технического обслуживания этой сессии или могут показывать, сколько раз пользователи поделились конкретными материалами в социальной сети. Оператор рекомендует Пользователю регулярно читать правила всех социальных сетей в отношении конфиденциальности и использования файлов cookies и, если применимо, изменять параметры конфиденциальности социальных сетей таким образом, чтобы ограничить количество предоставляемых данных и скорректировать эти параметры в соответствии со своими собственными интересами.</div>
<div>9.9. При посещении Сайта Пользователь может увидеть рекламу продуктов или услуг Оператора, и в браузере Пользователя будет установлен файл cookies, с помощью которого Оператор анализирует рекламу, в частности, количество ее просмотров, точное место и время ее появления, и т.д. Может оказаться, что некоторые файлы cookies, используемые на данном сайте, не имеют отношения к Оператору. Это связано с тем, что на некоторых страницах расположены элементы, полученные со страниц третьих лиц. Так как эти элементы получены с других страниц, Оператор не может контролировать конфигурацию этих файлов cookies. Если Пользователь хочет изменить свои предпочтения в отношении конфигурации файлов cookies, Пользователю следует обратиться за получением соответствующих сведений на сайты таких третьих лиц.</div>
<div>9.10. Оператор попросит отдельное согласие Пользователя на обработку файлов cookies. Если Пользователь не предоставляет такое согласие, то оператор будет обрабатывать только технические файлы cookies, которые необходимы для правильного функционирования нашего Сайта.</div>
<div>9.11. Пользователь также может в любой момент отозвать свое согласие на обработку файлов cookies и удалить файлы cookies, хранящиеся на компьютере Пользователя, изменив настройки своего браузера. Наконец, если Пользователь хочет контролировать процесс установки файлов cookies, Пользователь может установить в свой браузер специальные программы или дополнения, известные как инструменты запрета отслеживания, позволяющие выбирать Пользователю, какие файлы cookies Пользователь разрешает хранить на своем устройстве.</div>

<h2>10. Передача персональных данных</h2>
<div>10.1. Персональные данные Пользователей не передаются каким-либо иным третьим лицам, за исключением случаев, прямо предусмотренных настоящей Политикой конфиденциальности и для целей использования Сайта.</div>
<div>10.2. Оператор вправе раскрывать персональные данные Пользователя нашим страховщикам и/или профессиональным консультантам в той мере, в какой это разумно необходимо для целей получения или сохранения страхового покрытия, управления рисками, получения профессиональных консультаций.</div>
<div>10.3. Финансовые операции, связанные с Сайтом и услугами являются/ могут обрабатываться поставщиками платежных услуг Оператора. <br>
<i>Мы будем передавать данные о транзакциях нашим поставщикам платежных услуг только в той мере, в какой это необходимо для целей обработки ваших платежей, возврата таких платежей и рассмотрения жалоб и запросов, касающихся таких платежей и возвратов. Вы можете найти информацию о политике и практике конфиденциальности поставщиков платежных услуг по адресу <a href="https://money.yandex.ru/doc.xml?id=527067">https://money.yandex.ru/doc.xml?id=527067</a>.</i></div>
<div>10.4. Оператор вправе раскрыть персональные данные Пользователя, если такое раскрытие необходимо для соблюдения юридического обязательства, которому Оператор подчиняется, или для защиты жизненно важных интересов Пользователя или жизненные интересы другого физического лица. Оператор также может раскрыть персональные данные, если такое раскрытие необходимо для создания, исполнения или защиты судебных исков, будь то в судебном порядке, в административной или внесудебной процедуре.</div>
<div>10.5. Оператор вправе передавать персональные данные Пользователей поставщикам материалов и другим деловым партнерам IT admin, чтобы они могли информировать Пользователей о своих продуктах и услугах, которые могут быть им интересны.</div>
<div>10.6. Оператор вправе передавать общую информацию о курсе (в том числе о выполнении тестов или заданий, оценках), информацию о действиях Пользователя на Сайте и демографические данные из проводимых опросов поставщикам материалов и другим деловым партнерам IT admin для проведения исследований, связанных с онлайн-образованием.</div>
<div>10.7. Оператор вправе передавать персональные данные для целей осуществления технической поддержки и модернизации Сайта. </div>
<div>10.8. IT admin может раскрывать персональные данные представителям государственных органов по следующим причинам: 1) в связи с получением повестки в суд, постановлением суда или другим юридическим процессом; 2) для установления или осуществления наших юридических прав или защиты собственности; 3) для защиты от судебных исков; 4) в соответствии с другими требованиями законодательства. Кроме того, мы можем раскрывать Идентифицирующие вашу личность данные: 1) для расследования и предотвращения незаконной или предположительно незаконной деятельности и принятия мер в ее отношении; 2) для защиты прав, собственности и безопасности IT admin, Сайта, наших пользователей, клиентов или других лиц; 3) для выполнения Условий использования и других соглашений.</div>
<div>11. IT admin может раскрывать и/или передавать персональные данные пользователей покупателям, правоприобретателям или другим правопреемникам в связи с продажей, слиянием или реорганизацией всей или существенной части ценных бумаг, бизнеса или активов IT admin, которые имеют отношение к персональным данным.</div>

<h2>12. Уничтожение персональных данных</h2>
<div>12.1. Оператор обрабатывает персональные данные Пользователя не дольше, чем это требуется для достижения целей их обработки, указанных в Политике.</div>
<div>12.2. После достижения целей обработки персональных данных Оператор уничтожит персональные данные (а также обеспечит их уничтожение привлеченными к обработке третьими лицами) в течение 30 дней, если иной срок не предусмотрен применимым законодательством. Оператор также прекратит обработку персональных данных (а также обеспечит их уничтожение привлеченными к обработке третьими лицами), в случае если Пользователь отзовет свое согласие на обработку данных (в случаях, когда согласие является единственным законным основанием для обработки персональных данных).</div>
<div>12.3. Обращаем внимание на то, что Оператор может в некоторых случаях продолжить обработку данных после отзыва согласия, когда это требуется для выполнения обязанностей, возникающих в силу применимого законодательства, или, когда обработка необходима для осуществления прав и законных интересов Оператора и (или) третьих лиц.</div>

<h2>13. Прочие условия</h2>
<div>13.1. Пользователь дает Оператору согласие на обработку персональной информации Пользователя для целей предоставления функциональных возможностей Сайта, на обработку и передачу Оператором его уполномоченным представителям и третьим лицам для целей обработки персональных данных Пользователя персональной информации Пользователя для дальнейшей обработки такой информации в целях предоставления Пользователю функциональной возможности использования Сайта и получения ответа на запрашиваемую у Оператора информацию. Оператор передаёт/обрабатывает лишь ту персональную информацию Пользователя, которая необходима для указанной цели. Положения настоящего пункта в отношении обработки персональной и иной информации Пользователя распространяются на материалы и информацию, полученные или переданные Пользователем в процессе использования Сайта и его функций. </div>
<div>13.2. Настоящая Политика конфиденциальности может быть изменена Оператором без какого-либо специального уведомления, новая редакция Политики конфиденциальности вступает в силу с момента ее размещения в сети Интернет по указанному в настоящем пункте адресу, если иное не предусмотрено новой редакцией Политики конфиденциальности. Действующая редакция Политики конфиденциальности находится на странице по адресу: https://white-crows.ru/privacy-policy, если в Политике конфиденциальности не указано иное.</div>
<div>13.3. Настоящая Политика конфиденциальности регулируется и толкуется в соответствии с законодательством Российской Федерации. По всем вопросам, не урегулированным настоящей Политикой конфиденциальности, стороны руководствуются действующим законодательством Российской Федерации. </div>
<div>13.4. Если по каким-либо причинам одно или несколько условий настоящей Политики конфиденциальности будут признаны недействительными или не имеющими юридической силы, это не окажет влияния на действительность или применимость остальных условий Политики конфиденциальности.</div>
<div>13.5. В случае если Оператором были внесены какие-либо изменения в настоящую Политику конфиденциальности, с которыми Пользователь не согласен, он обязан прекратить использование Сайта.</div>
<div>13.6. Пользователь предоставляет Оператору право направлять Пользователю на электронную почту или номер мобильного телефона информационные и рекламные сообщения о запланированных Консультациях, результатах Консультаций, возможности и стоимости получения Консультаций, а также о порядке использования Сайта. Пользователь осведомлен, что вправе отказаться от получения сообщений в любой момент, уведомив Оператора с использованием указанных адреса электронной почты или номера мобильного телефона Пользователя.</div></div>
    </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import { useMeta } from 'vue-meta';

export default defineComponent({
  name: 'PrivacyPolicy',
  setup() {
    useMeta({ title: 'Политика конфиденциальности'})
  }
});
</script>


<style scoped>
.container {
  margin-top: 60px;
}


@media (max-width: 1024px) {
  .container {
    margin: 100px 0 0 0;
  }
}
</style>