<template>
    <div :class="['page-view']" id="whatiwillhave" ref="whatiwillhave" v-observe-visibility="(isVisible: Boolean) => {  if (isVisible) setActiveMenuItem('whatiwillhave') }">
      <h1>Что я могу получить в результате?</h1>
      <div class="columns">
        <div class="column">
          <div class="column-content">
            <strong>“Белая ворона” поможет вам освоить искусство общения:</strong>
            <ul>
              <li>с незнакомцами</li>
              <li>с противоположным полом</li>
              <li>с коллегами по работе и начальством</li>
              <li>с родственниками и близкими людьми</li>
              <li>с соседями и малознакомыми людьми</li>
            </ul>
            <WhatIWillHaveSVG1 class="image" />
          </div>
        </div>
        <div class="column">
          <div class="column-content">
            <WhatIWillHaveSVG2 class="image" />
            <strong>Результатом для вас станут:</strong>
            <ul>
              <li>Повышение уверенности в себе и коммуникативных навыков</li>
              <li>Расширение социальной сети и карьерных возможностей</li>
              <li>Преодоление социальной тревожности и стресса</li>
              <li>Улучшение качества жизни и отношений</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex';
import { sendMetrik } from '../../utils/metrika';
import WhatIWillHaveSVG1 from '@/assets/WhatIWillHaveSVG1.vue';
import WhatIWillHaveSVG2 from '@/assets/WhatIWillHaveSVG2.vue';

export default defineComponent({
  name: 'WhatIWillHaveView',
  methods: {
    ...mapMutations(['setActiveMenuItem']),
    sendMetrik: sendMetrik,
  },
  components: {
    WhatIWillHaveSVG1,
    WhatIWillHaveSVG2,
  }
});
</script>

<style scoped>
.columns {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.column {
  width: 50%;
}

.column-content {
  display: inline-flex;
  flex-direction: column;
  width: auto;
}

.column:first-child .image {
  width: 50%;
}

.image {
  align-self: center;
  margin-bottom: 40px;
  width: 70%;
  height: auto;
}

@media (max-width: 1024px) {
  .columns {
    flex-direction: column;
  }

  .column-content {
    width: 100%;
  }

  .column {
    width: 100%;
  }

  .image {
    width: 100%;
    margin-bottom: 0;
  }

  .column:last-child .image {
    order: 1;
    width: 80%;
  }
  
  .column:first-child {
    margin-bottom: 60px;
  }
}
</style>