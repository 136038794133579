<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="116" height="150" viewBox="0 0 116 150" fill="none">
<path d="M76.0215 102.551L45.4141 103.871L47.0009 107.919C47.0827 111.062 47.1668 114.205 47.2486 117.348C47.2798 117.473 47.3087 117.595 47.34 117.72C47.7222 118.06 48.1045 118.398 48.4868 118.738C50.1506 119.243 51.8433 119.483 53.5768 119.353C54.2404 119.303 54.904 119.255 55.5677 119.205C56.0149 119.118 56.4645 119.033 56.9117 118.945C57.5176 118.745 57.6498 118.188 57.7027 117.658C57.9263 115.33 58.1211 113 58.2942 110.667C58.3375 110.097 58.4697 109.814 59.0876 109.927C59.833 110.062 60.5831 110.222 61.3525 110.169C61.4824 110.147 61.6122 110.122 61.7444 110.099C62.949 109.319 63.252 109.387 63.4227 110.657C63.692 112.65 64.1296 114.6 64.5792 116.553C64.6513 116.868 64.6032 117.215 64.6104 117.548C64.6345 117.743 64.6585 117.938 64.6826 118.133C65.3606 118.345 66.0386 118.558 66.719 118.77C67.3033 118.77 67.8948 118.66 68.4622 118.9C69.0032 118.733 69.5466 118.755 70.0923 118.88L70.1476 118.835L70.2054 118.875C70.4073 118.783 70.6141 118.768 70.8257 118.838C70.9531 118.82 71.7201 118.778 71.9774 118.773C72.4895 118.355 73.1603 118.613 73.7037 118.338C73.7878 118.158 73.9297 118.128 74.1004 118.178C75.4853 117.753 75.769 116.715 75.6728 115.352C75.4901 112.765 75.9686 110.184 75.8412 107.594C75.8195 107.149 75.9662 106.751 76.0263 106.331V102.551H76.0215Z" fill="#F37617"/>
<path d="M66.751 135.44C67.038 135.005 68.202 134.37 68.0585 135.528C68.3177 138.011 71.7401 137.751 72.7088 136.263C72.8982 135.994 72.7866 135.509 72.7567 135.144C72.9659 133.854 74.718 135.671 74.9791 136.426C80.8613 156.814 59.7626 152.368 66.749 135.44H66.751Z" fill="#6B5203"/>
<path d="M51.4613 2.40027C51.4613 2.40027 51.4613 2.40027 51.4635 2.40027C51.4635 2.40027 51.4635 2.39571 51.4635 2.39343L51.459 2.40027H51.4613Z" fill="#8B5335"/>
<path d="M83.1763 16.0192C82.5296 11.1346 80.4339 4.79854 74.4933 4.30775C62.5167 -3.05171 37.689 9.17608 38.9357 0C31.7786 3.93094 32.7764 13.9956 38.4124 18.194C38.3937 27.926 78.915 27.063 79.4407 29.8217C83.0669 29.1076 83.153 20.2641 83.1786 16.0215L83.1763 16.0192Z" fill="#AC2C2D"/>
<path d="M21.8824 39.1377C21.8339 39.1377 21.7877 39.1448 21.7414 39.1613C21.235 39.0598 20.9796 38.7319 20.7704 38.2153C20.1407 36.663 18.9054 36.295 17.9652 37.2835C17.5953 37.6704 17.7406 38.2059 17.5667 38.6659C17.287 38.5715 17.069 38.4795 16.8445 38.4229C16.2874 38.2837 15.0984 38.8735 15.0543 39.4208C14.9883 40.2252 14.5193 40.1804 14.0789 40.0483C13.4954 39.8761 12.9823 40.0695 12.804 40.5579C12.4649 41.4897 11.7449 41.4732 11.0579 41.6289C10.8619 41.6737 10.5977 41.6501 10.4788 41.827C10.1749 42.2753 10.0671 42.9146 10.4854 43.4902C10.9302 44.1885 11.0205 44.6933 11.7515 45.7006C12.2888 46.2739 11.9849 46.2597 12.2579 46.4225C12.4715 46.8755 12.7908 47.0618 13.1849 47.2364C13.6297 47.4322 14.4598 47.661 14.5633 47.0713C14.6954 46.3022 14.854 45.8988 15.1886 46.1441C15.4044 46.3022 15.6863 47.4629 16.6419 46.8896C17.5028 46.373 17.0779 46.432 17.3135 45.8894C17.3531 45.7974 17.6393 45.3774 17.9674 45.578C18.17 45.7006 18.3351 45.7714 18.5377 45.8964C19.4229 46.4367 20.2882 45.8351 20.319 44.7429C20.3234 44.6178 20.2948 44.4834 20.3234 44.3654C20.4357 43.9054 20.4049 44.1956 20.8299 43.9526C21.213 43.7332 22.3139 43.1552 22.4549 42.7329C22.2633 41.5345 22.0717 40.3361 21.8824 39.1377Z" fill="#FFD0A7"/>
<path d="M75.7976 75.8831C99.1896 74.4091 70.0583 75.6768 82.3732 62.5824C78.9937 60.9961 72.0093 60.494 68.3278 59.694C62.7975 64.8703 63.42 65.3815 55.5414 65.8721L50.9889 59.694C47.9949 60.2258 41.0756 61.7526 38.3209 60.9502C37.0225 60.7347 35.7195 60.2762 35.4663 60.6316C36.0307 66.9106 29.8407 69.2282 28.5771 70.9705C32.4723 72.9786 39.2918 74.8607 43.893 75.7571C46.1368 84.9383 43.749 95.4353 45.6583 104.131C49.3862 107.443 60.6443 104.059 62.614 104.798C68.5183 106.042 75.0497 105.935 75.0265 102.423C74.9406 90.0275 75.8395 75.7456 75.7976 75.8831Z" fill="#98CA3B"/>
<path d="M39.2912 145.408C42.1129 140.766 45.331 137.68 49.8509 134.694C49.183 138.335 56.3577 138.516 56.5938 135.512C58.6761 134.873 59.1675 140.132 59.1889 141.745C52.8944 144.875 38.5238 151.388 39.2913 146.939L39.2912 145.408Z" fill="#6B5203"/>
<path d="M57.6593 118.056C54.6295 118.599 51.4503 118.693 48.4756 117.857C48.4678 118.246 49.5659 131.87 49.6708 134.79C49.4506 137.955 52.6481 138.032 55.0855 137.516C58.6893 137.323 57.405 127.662 57.426 127.708C57.5649 126.51 57.4784 120.463 57.6593 118.056Z" fill="#FDBF90"/>
<path d="M72.7955 117.857C71.8143 118.156 70.6816 118.302 69.7189 118.368C68.7583 118.239 67.812 118.283 66.8452 118.265C66.7858 122.56 67.8407 134.508 67.6932 135.243C68.0352 136.206 67.6481 137.431 69.3707 137.662C70.569 137.822 72.269 137.923 72.8405 136.67C73.1437 130.94 72.7996 118.392 72.7934 117.859L72.7955 117.857Z" fill="#FDBF90"/>
<path d="M66.5273 54.0862C66.5148 55.0387 66.5024 55.9944 66.4925 56.9468C66.5546 57.7513 66.5894 57.6451 66.9324 57.7062C67.171 57.7352 68.344 58.2918 68.3738 58.6072C68.3738 58.8775 67.832 59.328 67.8345 59.5982C67.4195 60.3158 66.5447 60.4896 66.0948 61.1717C64.4918 63.6075 62.5757 65.6025 60.2743 66.7127C56.0071 68.7752 53.1441 65.5446 51.6107 60.8113C51.4541 60.3287 51.5436 60.0713 51.9164 59.8074C52.3786 59.4792 53.0621 59.4406 53.1491 58.501C53.1565 57.9733 53.164 56.4674 53.1689 55.9397C53.2833 55.8624 53.4026 55.708 53.5094 55.7209C55.6741 56.004 57.7965 56.3258 59.9264 56.078C61.2486 55.9236 62.608 55.5632 63.9053 54.8778C64.8174 54.3952 65.732 53.8063 66.7261 53.5714C66.659 53.742 66.5919 53.9125 66.5248 54.083L66.5273 54.0862Z" fill="#F8BD84"/>
<path d="M43.7185 33.5443C43.1286 34.0982 42.4433 34.0515 41.8534 33.6377C41.266 33.2261 40.495 32.8301 40.9723 31.9002C41.1852 31.4842 41.5034 31.0637 41.3908 30.5521C42.1398 30.3029 42.8716 29.9959 43.6426 29.8179C45.5713 29.373 47.4829 29.6533 49.3798 30.0404C50.5204 30.2718 50.7529 30.136 50.8826 29.0815C52.5274 29.4798 53.5334 30.6344 54.4831 31.769C54.789 32.136 55.3177 32.4564 55.3569 33.0081L55.3422 33.0192C55.3153 33.0348 55.2663 33.0615 55.2687 33.066C55.2883 33.1105 55.3177 33.1505 55.3446 33.1928V33.1972C55.4841 35.7934 54.8967 38.1494 52.8065 40.0849C51.7246 41.086 51.8568 41.4197 53.3327 42.027C53.5089 42.1004 53.6631 42.2428 53.8442 42.2784C54.7156 42.4497 55.1953 43.2862 56.1132 43.3663C56.1572 43.769 56.145 44.136 55.631 44.3207C55.3862 44.4097 55.1097 44.443 55.0534 44.2072C54.7401 42.8613 53.4232 42.9925 52.4099 42.6544C51.5459 42.3652 50.562 42.2406 50.07 41.3707C49.9648 41.1839 49.7469 40.9881 49.9109 40.8123C50.4592 40.2272 50.4372 39.3841 51.1519 38.8457C52.104 38.1294 52.0379 37.0081 52.175 36.0003C52.219 35.6755 51.8543 35.5087 51.6487 35.2862C51.426 35.0437 51.005 34.957 50.9683 34.5699C51.098 33.7156 50.8949 32.9525 50.2193 32.3185C49.7445 31.8224 49.0885 31.4998 48.4619 31.3129C47.943 31.1594 47.1941 30.9258 46.6874 31.5243C46.3545 31.5421 46.0192 31.5576 45.6863 31.5754C45.4147 31.5799 45.1503 31.591 45.121 31.9225C44.301 32.2117 43.858 32.7701 43.716 33.5443H43.7185Z" fill="#FDBF90"/>
<path d="M72.8731 30.0772C73.0811 31.287 72.9771 32.3922 72.1149 33.3715C71.6687 33.8801 71.3567 34.2057 70.8782 33.4761C70.8204 32.5135 70.254 31.9882 69.4057 31.7933C69.0798 31.7196 68.8671 31.5176 68.6036 31.3702C68.4741 30.814 68.0141 30.9733 67.7113 31.0232C67.0179 31.1349 66.2204 30.6144 65.6332 31.3655C65.5246 31.3845 65.4183 31.4011 65.3096 31.4201C65.053 31.3726 64.9097 31.4819 64.8866 31.7505C64.5907 31.8931 64.2925 32.0333 63.9966 32.1759C63.7239 32.1474 63.5967 32.2758 63.6083 32.5562C63.5967 32.6109 63.5806 32.6632 63.5551 32.7131C63.2246 32.7511 63.0489 32.9413 63.0281 33.2835C62.8247 33.9086 62.8224 34.5551 62.8501 35.204C62.1312 35.1874 61.1765 34.3483 61.0355 33.4832C60.8506 32.3518 60.3606 31.1872 61.1742 30.0891C61.833 29.9655 62.4988 29.8681 63.1506 29.7136C65.3165 29.2002 67.494 28.8413 69.7108 29.2763C70.7742 29.4854 71.819 29.8063 72.8707 30.0772H72.8731Z" fill="#FDBF90"/>
<path d="M72.5478 30.5972C71.5058 30.2586 70.4707 29.8575 69.4172 29.5961C67.2211 29.0525 65.0638 29.504 62.918 30.1427C62.2722 30.3358 61.6127 30.4576 60.96 30.6121C60.4012 28.5801 60.8066 27.6236 62.286 26.9254C64.6035 25.8352 66.9646 25.8174 69.3188 26.4085C70.3905 26.6789 71.4531 27.2552 72.4195 27.9949C73.0974 28.5148 73.1455 29.6823 72.5501 30.5913L72.5478 30.5972Z" fill="#8B5335"/>
<path d="M50.0046 28.575C49.8866 30.0358 49.6685 30.2269 48.6103 29.9033C46.8504 29.3701 45.0745 28.9787 43.2873 29.5951C42.5743 29.8416 41.893 30.2669 41.1981 30.6121C40.5963 29.706 40.7394 28.3623 41.482 27.5826C42.4357 26.5749 43.5666 26.202 44.7089 26.1373C45.7081 26.0818 46.7096 25.9369 47.7247 26.0849C49.1599 26.2913 49.58 26.6704 50.0069 28.5781L50.0046 28.575Z" fill="#8B5335"/>
<path d="M50.0059 46.4843C53.2319 46.6648 56.4531 46.5445 59.6115 45.9014C61.0386 45.6099 62.4947 45.2468 63.7962 44.525C64.424 44.1757 64.9069 43.7709 64.482 42.8572C65.7473 44.0971 65.5348 45.0131 64.057 45.5336C60.7488 46.6972 57.3779 47.5346 53.8162 47.442C52.5244 47.4096 51.2736 47.1297 50.0203 46.873C50.0155 46.7434 50.0107 46.6139 50.0059 46.4843Z" fill="#EEB273"/>
<path d="M67.4832 34.6822C66.6198 35.287 65.727 35.3984 64.7902 34.8458C64.5676 34.4403 64.1346 34.2268 63.9488 33.743C63.4498 32.4434 64.1444 31.6299 64.9956 30.833C65.7637 30.7358 66.5342 30.3919 67.3046 30.8283C68.6695 32.422 68.7233 33.577 67.4856 34.6846L67.4832 34.6822ZM66.7739 32.007C66.3703 31.9951 66.0083 32.0093 65.9691 32.4979C65.9349 32.9201 66.3409 33.2047 66.5684 33.2189C66.8839 33.2355 67.3169 33.0624 67.3169 32.5477C67.3169 32.1422 67.1872 31.9429 66.7763 32.0093L66.7739 32.007Z" fill="#665552"/>
<path d="M48.9837 35.1334C48.8464 35.1485 48.7114 35.1619 48.574 35.177C48.3812 35.172 48.1812 35.1418 47.9956 35.167C46.5785 35.363 45.7976 34.7547 45.5108 33.9822C45.1541 33.0187 45.7928 32.4389 47.275 32.1473C48.1306 32.1272 48.9644 32.1524 49.5453 32.6903C50.3406 33.4209 50.008 34.2017 49.2898 34.7514C49.1404 34.8653 48.962 34.9575 48.9813 35.1334H48.9837ZM48.9789 33.3371C48.9837 32.8847 48.5282 33.0304 48.2173 33.002C47.8341 32.9668 47.6028 33.0388 47.6365 33.3572C47.6678 33.6404 47.904 33.8901 48.2342 33.8733C48.6078 33.8532 49.0392 33.6873 48.9813 33.3371H48.9789Z" fill="#665552"/>
<path d="M54.5983 42.8571C53.8993 42.7728 53.534 41.892 52.8704 41.7117C52.7325 41.6742 52.615 41.5243 52.4808 41.447C51.3569 40.8075 51.2543 40.4561 52.0801 39.402C53.6719 37.3641 54.1193 34.8835 54.0149 32.1498C54.0634 32.1498 54.1137 32.1452 54.1622 32.1428C54.8928 34.9467 54.3691 37.4461 52.8928 39.6245C52.438 40.2968 52.5442 40.5615 53.0456 40.9504C53.6701 41.4376 54.2982 41.9412 54.5983 42.8548V42.8571Z" fill="#EEB273"/>
<path d="M47.2913 31.1037C45.9388 31.5979 45.356 32.5807 45.6815 34.2138C45.941 35.5232 46.6557 36.5543 47.9488 36.2219C48.1181 36.1793 48.3007 36.2305 48.4766 36.239C47.7003 37.0229 46.7964 36.6878 46.0047 36.5031C44.5665 36.168 43.9925 35.1142 43.8848 33.1942C44.0123 32.2057 44.4104 31.4928 45.1471 31.1236C45.4066 31.1463 45.6463 31.1349 45.6551 30.6776C45.9542 30.6549 46.2555 30.635 46.5545 30.6123C46.8008 30.777 47.2165 30.5072 47.2935 31.098L47.2913 31.1037Z" fill="#FEFDFC"/>
<path d="M85.1851 30.2017C84.3801 25.1083 79.939 29.9567 79.3421 29.5765C78.1713 9.52108 55.6638 19.5293 42.672 18.0613C37.5997 31.3331 32.1936 48.6952 50.189 55.9254C61.3261 58.5499 75.4362 53.7313 80.0351 43.7804C83.0698 41.8451 83.9182 40.6153 83.7375 36.4265C83.5294 34.4386 85.4413 32.4095 85.1874 30.2017H85.1851Z" fill="#F8BD84"/>
<path d="M59.1881 49.2469C58.0189 49.9236 56.8131 50.4598 55.3271 50.5061C53.8554 50.5523 52.6089 50.2165 51.5371 49.3822C51.5919 49.3257 51.6467 49.2674 51.7015 49.2109C51.8091 49.2212 51.9147 49.2297 52.0223 49.24C52.0933 49.3599 52.2131 49.3839 52.3572 49.3548C54.6694 49.7111 56.9612 49.7951 59.1841 48.9796C59.1861 49.0687 59.1881 49.1578 59.1902 49.2452L59.1881 49.2469Z" fill="#E57876"/>
<path d="M49.9932 46.7287C49.9975 46.9688 50.0019 47.2089 50.0062 47.4489C49.462 47.3975 49.087 46.7072 48.4756 45.9183C49.1607 46.0084 49.6181 46.0341 49.991 46.7244L49.9932 46.7287Z" fill="#FDBF90"/>
<path d="M60.1768 67.2101C55.4693 68.0357 51.5147 65.0223 50.0917 60.9292C49.5248 57.6443 51.8613 58.621 54.3057 58.494C54.2667 59.4401 52.4925 59.0831 52.8826 60.0664C54.2988 63.2878 56.9429 65.4866 60.8837 64.0828C63.7963 63.0404 66.1168 60.8526 67.7418 58.3363C68.4716 57.8501 68.9031 58.5159 69.4058 58.7721C70.3101 59.2145 69.7363 60.6818 69.5848 61.4877C67.2552 64.5799 64.0717 66.356 60.1745 67.2123L60.1768 67.2101Z" fill="#7A9E18"/>
<path d="M33.9004 35.0877C28.4156 26.6862 17.9114 37.958 24.0125 44.4871C30.53 46.9341 38.3988 42.9101 33.9004 35.0877Z" fill="#034B6B"/>
<path d="M20.6125 43.4056C20.7195 44.9384 20.3939 47.6017 18.1562 45.699C16.9467 45.4245 17.5073 48.3297 15.2208 46.4433C14.2695 45.6036 14.095 48.7507 12.2482 46.2852C11.3085 45.3385 10.5549 47.169 9.38257 46.1944C6.35876 44.3941 2.97211 45.8967 1.17178 47.9064C-4.54088 59.0596 12.4529 63.3766 13.1646 50.8999C14.9347 49.8323 23.0827 45.8967 23.4409 43.8545C23.4874 43.587 22.4849 41.4773 22.3012 41.2517C22.1384 41.0517 22.985 44.843 20.6102 43.4056H20.6125Z" fill="#034B6B"/>
<path d="M101.059 94.5546C101.782 94.6499 102.173 94.3848 102.182 93.7102C102.247 92.417 104.359 92.938 104.373 91.3587C104.98 90.4399 106.66 90.7562 106.474 89.0396C106.776 87.958 109.416 88.7047 108.537 86.4694C107.476 84.5481 105.906 82.478 103.624 82.6664C102.017 83.1735 103.98 85.5227 101.436 84.062C99.4632 83.8061 99.6074 85.7437 100.331 86.9648C98.7095 86.8043 98.2048 87.2626 98.5304 88.6023C99.2631 89.8956 97.7652 89.57 97.5791 90.6306C98.1118 92.2356 98.9817 94.222 101.059 94.5569V94.5546Z" fill="#F8BD84"/>
<path d="M103.625 82.664C99.0988 83.9223 99.2755 84.6969 95.8633 87.73C96.1773 83.1943 89.8878 83.085 87.8083 79.4797C74.8921 67.3449 81.7747 55.0381 89.8017 69.0685C90.9484 73.1809 94.2397 79.9658 98.2986 80.7427C99.764 79.3401 104.076 80.659 103.623 82.664H103.625Z" fill="#F8BD84"/>
<path d="M90.9775 103.009C96.9739 103.221 100.258 97.9271 97.0414 92.3168C97.0414 92.3168 97.0321 92.6332 97.8578 91.4074C98.1602 90.9584 97.8997 90.3676 97.9532 89.9257C99.8465 89.414 96.8925 86.332 100.33 86.9624C98.8184 84.4759 100.658 83.5734 102.847 84.6945C102.77 84.4759 102.959 81.7056 105.04 83.2524C105.501 83.5943 105.957 82.6383 106.148 82.3592C106.569 81.7405 104.815 81.3311 104.682 81.2497C102.582 79.9751 95.59 88.4301 93.8641 90.0978C84.4903 87.4555 80.5896 101.486 90.9775 103.009Z" fill="#034B6B"/>
<path d="M107.958 83.9317C124.826 84.7993 110.526 60.7019 103.176 75.6604C102.201 79.8821 103.283 83.4525 107.958 83.9317Z" fill="#034B6B"/>
<path d="M67.9017 27.8004C76.177 30.2275 67.7948 31.0654 64.2451 30.3836C58.9895 29.4185 65.5211 26.7248 67.9017 27.8004Z" fill="#522A2F"/>
<path d="M49.7665 27.6679C57.9723 28.5153 48.7025 30.9417 45.4044 30.5747C40.7988 28.8509 47.9608 27.074 49.7665 27.6679Z" fill="#522A2F"/>
<path d="M33.5283 60.2615C33.0495 60.0035 31.451 59.2154 31.1318 58.9011C30.8126 58.5844 30.4184 58.378 30.0508 58.127C28.7256 57.2146 27.4343 56.2576 26.1357 55.3076C25.9567 55.1763 25.7778 55.0214 25.6617 54.8408C24.7694 53.4546 23.744 52.1575 22.8444 50.7736C22.2858 49.9127 22.4454 49.4366 23.079 48.7962C23.9278 47.9377 24.6799 47.023 24.4622 45.6719C24.3824 45.1699 23.9375 44.7946 24.0415 44.2622C23.7803 43.7204 23.7924 42.9674 22.9702 42.8572L21.8554 45.1512L14.9391 49.5234L14.8037 49.8424H14.8085C15.372 50.4921 16.1362 50.8674 16.8834 51.2685C17.4227 51.5593 17.9112 51.8807 18.0369 52.5539C18.3561 54.2662 19.2533 55.7556 20.2448 57.1419C20.9945 58.1903 21.7151 59.274 22.5325 60.292C23.3112 61.2607 24.0294 62.3327 24.7621 63.3671C25.4368 64.3217 26.4936 65.0371 26.7862 66.2428C27.3182 67.4789 27.7607 67.6431 28.1815 68.1591C29.2214 69.4281 30.121 68.8018 31.5381 67.9246C33.3252 66.8198 33.9781 65.0348 34.515 63.1865C34.5851 62.9472 34.5948 62.6939 34.6407 62.4476C34.8463 61.3241 34.5464 60.8127 33.521 60.2615H33.5283Z" fill="#F8BD84"/>
<path d="M54.9671 51.9479C52.9777 51.5482 51.1474 50.2922 50.0059 47.3435C50.496 44.0721 57.1441 47.7003 59.7185 45.9875C63.0746 46.6584 57.0977 52.8556 54.9648 51.9507L54.9671 51.9479Z" fill="#FDFDFD"/>
<path d="M80.1525 73.1463C78.142 70.3281 78.9872 63.6571 82.6407 62.922C84.5586 62.5349 86.8667 62.7515 86.7367 64.3184C79.0698 63.5465 82.7632 73.9782 85.1363 76.0636C82.2956 77.5937 81.3328 75.0335 80.155 73.1463H80.1525Z" fill="#7A9E18"/>
<path d="M68.3356 34.8527C68.8721 39.3742 63.4663 39.4709 63.7957 34.734C63.7491 31.3256 68.7234 31.193 68.3356 34.8527Z" fill="#2C2E2B"/>
<path d="M49.9727 34.7184C50.4521 38.7718 45.5824 39.9346 45.4284 35.0884C45.1029 31.0991 50.4434 31.3467 49.9727 34.7184Z" fill="#2C2E2B"/>
<path d="M47.7116 35.204C48.135 35.204 48.4769 34.8621 48.4769 34.4386C48.4769 34.0152 48.135 33.6733 47.7116 33.6733C47.2882 33.6733 46.9463 34.0152 46.9463 34.4386C46.9463 34.8621 47.2882 35.204 47.7116 35.204Z" fill="white"/>
<path d="M66.0788 35.204C66.5022 35.204 66.8441 34.8621 66.8441 34.4386C66.8441 34.0152 66.5022 33.6733 66.0788 33.6733C65.6554 33.6733 65.3135 34.0152 65.3135 34.4386C65.3135 34.8621 65.6554 35.204 66.0788 35.204Z" fill="white"/>
<path d="M54.7504 39.7959C54.699 42.1021 56.0308 42.5975 57.6599 42.7092C55.5557 43.1814 54.1138 42.6038 54.7504 39.7959Z" fill="#BB8B6C"/>
<path d="M36.1834 62.7456C36.3163 70.8649 26.3679 76.7425 27.0837 66.4325C30.2513 71.1108 32.9923 66.9827 34.0467 62.3298C34.4646 60.4889 32.3644 60.6917 31.685 59.0841C30.8385 57.0835 36.2305 58.7114 36.2305 60.4711C36.2305 61.3535 36.2305 61.7745 36.1855 62.7456H36.1834Z" fill="#7A9E18"/>
</svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MoreInPremiumSVG3',
  components: {
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>