<template>
    <div :class="['root', 'page-view']" id="main"
      v-observe-visibility="(isVisible: Boolean) => {  if (isVisible) setActiveMenuItem('main') }">
      <div class="columns">
        <CrowWithPhoneSVG class="crowsvg" />
        <div class="content">
          <h1>Белая ворона - ваша персональная поддержка для уверенного общения!</h1>
          <p>
            Страх или неуверенность в общении — это популярная проблема, которая может ограничивать наши возможности в карьере, в социальной жизни и в личных контактах.
          </p>
          <p>
            Приложение «Белая ворона» создано для того, чтобы помочь вам справляться с этой проблемой в режиме реального времени.
          </p>
          <!-- <div class="storebuttons-block">
            <div class="buttons-text">Избавьтесь от неуверенности прямо перед разговором в любом месте и в любое время, используя только свой смартфон</div>
            <div class="store-buttons">
              <button class="storebtn"><RuStoreLogoView></RuStoreLogoView></button>
              <button class="storebtn"><AppGalleryLogoView></AppGalleryLogoView></button>
            </div>
          </div> -->
          <div class="lighting-text green-text">Избавьтесь от неуверенности прямо перед разговором в любом месте и в любое время, используя только свой смартфон.</div>
          <br />
          <div><a href="#subscribe" class="btn-green">Подписаться</a></div>
        </div>
      </div>
    </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex';
import { sendMetrik } from '../../utils/metrika';
import CrowWithPhoneSVG from '@/assets/CrowWithPhoneSVG.vue';
// import RuStoreLogoView from '@/components/RuStoreLogoView.vue';
// import AppGalleryLogoView from '@/components/AppGalleryLogoView.vue';


export default defineComponent({
  name: 'MainView',
  methods: {
    ...mapMutations(['setActiveMenuItem']),
    sendMetrik: sendMetrik,
  },
  components: {
    CrowWithPhoneSVG,
    // RuStoreLogoView, 
    // AppGalleryLogoView,
  }
});
</script>

<style scoped>
.root {
  position: relative;
  padding-top: 140px;
  padding-bottom: 40px;
}

.root::after {
  content: '';
  clear: both;
}

.root .text {
  padding: 25px 800px 25px 0;
}

.green-text {
  padding: 40px 0;
}

.columns {
  display: flex;
  flex-direction: row;
  
}

.root .crowsvg {
  position: relative;
  width: 80%;
  order: 1;
  height: auto;
  margin-top: -60px;
}

.storebuttons-block {
  box-sizing: border-box;
  background: linear-gradient(341deg, var(--light-green-color) 25%, var(--green-color) 75%);
  padding: 40px;
  margin-right: 900px;
  margin-top: 40px;
  border-radius: 10px;
}

.buttons-text {
  font-family: "Odds", Arial, sans-serif;
  font-size: 30px;
  line-height: 35px;
  color: white;
}

.store-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
}

.storebtn {
  width: 237px;
  background: white;
  padding: 10px 20px;
  border: none;
  margin: 10px 0;
  border-radius: 5px;

  box-sizing: border-box;
}


@media (min-width: 1366px) and (max-width: 1920px) {
  .root {
    padding-top: 140px;
    min-height: 622px;
  }

  .root .text {
    padding: 15px 550px 15px 0;
  }

  .storebuttons-block {    
    max-width: 620px;
    margin-right: 600px;
    padding: 30px;
  }

  .buttons-text {
    font-size: 25px;
    line-height: 30px;
  }

  .storebtn {
    width: 180px;
  }

  .storebtn > svg {
    width: 100%;
    height: auto;
  }
}


@media (min-width: 1024px) and (max-width: 1366px) {
  .root {
    min-height: 502px;
  }
  
  .root {
    padding-top: 100px;
  }

  .root .text {
    padding: 10px 400px 10px 0;
  }
  
  .storebuttons-block {
    margin-right: 450px;
    padding: 20px;
  }

  .buttons-text {
    font-size: 25px;
    line-height: 30px;
  }

  .storebtn {
    width: 180px;
  }

  .storebtn > svg {
    width: 100%;
    height: auto;
  }
}



@media (max-width: 1200px) {
  .store-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .storebtn {
    width: 60%;
    margin: 10px auto;
  }

  .root .crowsvg {
    width: 100%;
  }
}


@media (max-width: 1024px) {
  .root {
    padding-top: 0;
  }
  .root .crowsvg {
    position: relative;
    top: 0;
    width: 100%;
    height: auto;
    order: 0;
    margin-top: 0;
  }
  .root h1 {
    padding-right: 0;
  }

  .root .text {
    padding: 25px 0 25px 0;
  }
  .storebuttons-block {
    margin-right: 0;
    padding: 30px;
  }
  .buttons-text {
    font-size: 16px;
    line-height: 18px;
  }
  .storebtn {
    width: 100%;
  }
  .root .columns {
    flex-direction: column;
  }
}

</style>