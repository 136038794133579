<template>
  <div class="home">

    <MainView />
    
    <INeedThisView />
    
    <HowItWorksView />

    <AdvantagesView />

    <WhatIWillHaveView />
    
    <SubscribeView />
    
    <ContactsView />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex';
import { sendMetrik } from '../utils/metrika';
import MainView from '@/views/TextViews/MainView.vue';
import INeedThisView from '@/views/TextViews/INeedThisView.vue';
import HowItWorksView from '@/views/TextViews/HowItWorksView.vue';
import AdvantagesView from '@/views/TextViews/AdvantagesView.vue';
import WhatIWillHaveView from '@/views/TextViews/WhatIWillHaveView.vue';
import SubscribeView from '@/views/SubscribeView.vue';
import ContactsView from '@/views/ContactsView.vue';
import { useMeta } from 'vue-meta';

export default defineComponent({
  name: 'HomeView',
  methods: {
    ...mapMutations(['setActiveMenuItem']),
    sendMetrik: sendMetrik,
  },
  components: {
    MainView,
    INeedThisView,
    HowItWorksView,
    AdvantagesView,
    WhatIWillHaveView,
    SubscribeView, 
    ContactsView, 
  },
  setup() {
    useMeta({ title: 'Белая ворона - ваша персональная поддержка для уверенного общения!'})
  }
});
</script>

<style scoped>
.home {
  margin-left: 300px;
}

@media (min-width: 1366px) and (max-width: 1920px) {
  .home {
    margin-left: 250px;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .home {
    margin-left: 200px;
  }
}

@media (max-width: 1024px) {
  .home {
    margin-left: 0;
    margin-top: 100px;
  }
}
</style>
