<template>
<svg xmlns="http://www.w3.org/2000/svg" width="119" height="150" viewBox="0 0 119 150" fill="none">
<path d="M4.96891 26.5315C5.0324 30.4834 8.01621 33.2927 11.2698 34.975C11.6666 38.7683 11.9206 40.2919 16.3328 40.8792C18.7294 41.5299 18.1263 38.2921 19.0468 36.9748C27.4269 32.3245 28.7124 28.1027 28.3633 18.5958C30.0139 8.53337 17.0787 3.15299 10.1588 9.78721C7.58769 11.9457 4.8102 14.6438 4.68323 18.199C4.73084 18.8974 4.55626 20.0718 3.63572 19.9449C-0.125784 17.7705 0.239256 27.6583 4.01663 25.9284L4.96891 26.5315Z" fill="#F8BD84"/>
<path d="M28.4884 12.0094C31.8848 10.2001 30.8215 5.53388 28.7423 3.07382C23.0604 0.486797 15.3786 -1.54473 9.55387 1.59779C3.85606 3.97849 1.63407 10.581 2.57048 16.3423C3.7767 18.3897 5.42732 20.326 5.50668 22.8495C5.33209 24.4208 7.03033 25.3889 7.87151 23.7859C8.44287 21.5004 7.12555 19.0721 7.42711 16.739C7.39537 9.96198 16.9499 8.72402 21.6161 11.3587C21.6161 11.3587 27.0758 12.7553 28.4884 12.0094Z" fill="#661A2E"/>
<path d="M11.552 36.0859C10.0284 37.5778 9.47287 38.8317 7.94922 40.3236C15.7579 44.1327 17.5196 58.3852 22.0747 64.4163C23.2016 57.8455 20.9637 46.9102 22.043 40.3395C22.1382 38.7206 19.1385 39.4507 18.7576 37.7683C16.6467 42.117 12.5202 39.7046 11.552 36.0701V36.0859Z" fill="#DDDDDD"/>
<path d="M14.5714 30.4987C17.8567 33.9269 21.4595 33.2762 23.9196 29.3242C20.9993 30.5939 16.841 31.197 14 29.5305L14.5714 30.4987Z" fill="#FAFFFE"/>
<path d="M17.6494 25.5787C18.189 27.388 21.1094 26.6579 21.1887 25.0073C20.3793 25.7533 18.4906 25.7215 17.6494 25.5787Z" fill="#C68F64"/>
<path d="M108.035 26.5315C107.971 30.4834 104.987 33.2927 101.734 34.975C101.337 38.7683 101.083 40.2919 96.6706 40.8792C94.2741 41.5299 94.8772 38.2921 93.9566 36.9748C85.5766 32.3245 84.291 28.1027 84.6402 18.5958C82.9895 8.53337 95.9247 3.15299 102.845 9.78721C105.416 11.9457 108.193 14.6438 108.32 18.199C108.273 18.8974 108.447 20.0718 109.368 19.9449C113.129 17.7705 112.764 27.6583 108.987 25.9284L108.035 26.5315Z" fill="#C68F64"/>
<path d="M98.4302 28.8485C95.5099 31.3721 91.3834 31.0705 89.082 27.9438C92.0182 28.9279 96.1606 29.404 99.0016 28.1026L98.4302 28.8485Z" fill="#FAFFFE"/>
<path d="M95.8137 23.6751C95.2741 25.4845 92.3538 24.7544 92.2744 23.1038C93.0839 23.8497 94.9725 23.818 95.8137 23.6751Z" fill="#9B6E50"/>
<path d="M12.4909 18.7382C11.6656 20.4523 14.9986 20.3571 15.4271 19.1667C16.3318 16.548 11.5704 16.7384 11.126 18.77C11.5545 18.4843 12.0624 18.4208 12.475 18.7382H12.4909Z" fill="#2C2E2B"/>
<path d="M24.9965 18.437C25.8218 20.1511 22.4889 20.0558 22.0603 18.8655C21.1557 16.2467 25.9171 16.4372 26.3615 18.4687C25.9329 18.183 25.4251 18.1195 25.0124 18.437H24.9965Z" fill="#2C2E2B"/>
<path d="M14.8714 18.6597C14.8714 19.7072 13.6493 19.7072 13.6652 18.6597C13.6652 17.6121 14.8873 17.6121 14.8714 18.6597Z" fill="white"/>
<path d="M24.7572 18.6597C24.7572 19.7072 23.5351 19.7072 23.5509 18.6597C23.5509 17.6121 24.773 17.6121 24.7572 18.6597Z" fill="white"/>
<path d="M10.1406 16.6914C11.3945 15.8502 17.7906 14.6757 16.0448 17.1993C14.4735 15.9931 11.9658 16.9295 10.1406 16.6914Z" fill="#2C2E2B"/>
<path d="M24.3125 85.0958C27.3598 87.6352 29.9627 90.6825 32.0101 94.0631C33.629 91.0634 37.8984 90.7619 40.3426 88.3812C32.5339 85.0482 30.4071 67.4945 25.1537 63.1934C24.1538 70.3355 25.2172 77.8902 24.3125 85.0958Z" fill="#2C2E2B"/>
<path d="M20.0781 16.1834C22.1097 15.3898 24.5221 14.628 26.506 15.977C24.4745 15.8818 22.3953 15.7707 20.6019 16.8341L20.0781 16.1992V16.1834Z" fill="#2C2E2B"/>
<path d="M88.6564 18.2785C88.0374 19.5482 90.5292 19.4847 90.8466 18.5959C91.5132 16.6437 87.9739 16.7866 87.6406 18.2944C87.9581 18.088 88.339 18.0404 88.6564 18.2785Z" fill="#2C2E2B"/>
<path d="M100.652 18.3102C101.271 19.5799 98.7787 19.5164 98.4613 18.6277C97.7947 16.6755 101.334 16.8183 101.667 18.3261C101.35 18.1198 100.969 18.0722 100.652 18.3102Z" fill="#2C2E2B"/>
<path d="M90.4312 18.231C90.4312 19.0087 89.5107 19.0087 89.5266 18.231C89.5266 17.4533 90.4471 17.4533 90.4312 18.231Z" fill="white"/>
<path d="M100.484 18.4685C100.484 19.2462 99.5634 19.2462 99.5793 18.4685C99.5793 17.6908 100.5 17.6908 100.484 18.4685Z" fill="white"/>
<path d="M85.415 15.9455C86.6689 15.1044 93.065 13.9299 91.3192 16.4534C89.7479 15.2472 87.2402 16.1836 85.415 15.9455Z" fill="#2C2E2B"/>
<path d="M98.3379 14.8823C100.496 14.6283 103.036 14.4855 104.607 16.279C102.671 15.6759 100.671 15.041 98.6871 15.6282L98.3379 14.8823Z" fill="#2C2E2B"/>
<path d="M9.6002 39.991C-11.35 45.7999 9.9335 72.3209 0.696383 96.2866C9.39388 97.4611 18.6151 94.5725 27.5348 95.8104C29.2807 95.8739 29.0902 83.5419 27.9475 82.0976C25.535 73.4953 25.8048 64.29 25.9318 55.402C26.3286 50.4502 26.7889 43.7525 21.6465 41.1337C21.0117 48.7996 23.1861 56.5924 21.9481 64.163C18.2183 58.3858 16.2027 51.7516 13.695 45.4031C13.0602 42.9748 10.6001 42.0384 9.58433 39.991H9.6002Z" fill="#2C2E2B"/>
<path d="M18.8525 139.98C19.6302 142.059 18.2494 146.297 21.4078 146.455C27.3278 146.662 33.5653 148.598 39.3742 147.677C41.8342 141.805 31.5496 142.821 28.1531 141.615C27.1691 141.488 26.4232 141.027 26.074 140.139C23.8679 139.631 21.0587 139.218 18.8525 139.98Z" fill="#54182B"/>
<path d="M37.5681 69.8444C40.9011 68.051 44.3293 64.2101 48.4082 66.2417C50.5984 67.3368 55.0107 69.4953 51.5825 71.9712C51.6618 72.5108 51.8364 73.1298 51.265 73.5425C47.567 75.2248 43.5674 74.7804 39.5679 74.3678C37.4094 74.5424 37.4728 71.3363 37.5681 69.8444Z" fill="#F8BD84"/>
<path d="M46.9211 75.4298C45.4634 75.7418 42.6039 74.9824 41.667 74.1667C40.9563 72.9609 44.2205 74.6266 45 74.1671C46.3448 74.5144 48.5145 73.2593 49.16 73.426C49.9669 73.6344 48.7432 75.0397 46.9211 75.4298Z" fill="#C68F64"/>
<path d="M45.7072 64.4003C44.9295 65.3208 42.9614 66.9079 44.5644 67.9872C46.1357 68.4633 47.2626 65.9715 48.7545 66.3048C56.9441 71.0027 49.9924 70.4949 52.3097 72.3677C57.3726 74.5579 57.4044 71.8757 57.9281 67.6221C54.8332 64.8447 49.6909 62.5433 45.7072 64.4161V64.4003Z" fill="#C68F64"/>
<path d="M10.537 48.402C9.61642 42.0376 4.72805 38.7205 1.26809 45.7515C-0.620594 57.4963 11.3464 75.3356 23.1229 77.8592C26.7257 77.8592 30.4237 79.0019 33.8996 77.6846C35.9628 76.8117 38.8038 76.7482 40.4703 75.3991C38.8514 73.4152 39.8672 70.7171 39.4386 68.2888C39.1212 67.7333 38.28 68.019 37.6928 68.2888C34.2487 69.5109 30.2968 69.3998 26.6781 69.3045C16.4728 66.9556 11.8225 58.0518 10.5528 48.402H10.537Z" fill="#162B49"/>
<path d="M32.2507 99.7296C29.6637 93.2541 38.9167 91.54 43.1543 92.7779C44.5828 94.984 46.5191 98.5075 42.3925 98.3488C41.7894 96.7458 42.2497 93.4604 39.4405 93.9683C37.6946 94.6031 35.298 94.5238 34.0759 96.0633C33.0919 97.6345 35.8377 100.793 32.2666 99.7296H32.2507Z" fill="#54182B"/>
<path d="M32.0889 92.4291C34.4696 93.5401 33.4221 97.4444 35.3901 97.4603C37.4375 97.3651 40.4213 96.5715 41.4053 94.6511C41.453 93.0163 42.7068 90.4769 40.9451 89.4294C36.3265 88.1279 35.7075 90.1753 32.0889 92.4132V92.4291Z" fill="#F8BD84"/>
<path d="M5.04583 139.805C3.58567 149.264 4.96647 147.566 13.3624 148.804C16.7906 149.058 28.0751 152.454 27.3927 146.518C23.5201 144.265 18.2032 143.757 14.0607 141.471C12.5688 141.138 13.3624 139.916 12.4736 139.487C10.0612 139.297 7.34717 139.122 5.02996 139.805H5.04583Z" fill="#661A2E"/>
<path d="M59.1809 95.7314C57.0065 92.2397 51.1976 95.4933 47.8488 96.0171C40.4051 97.8899 32.7551 98.858 25.2163 100.271C21.7404 100.493 19.3597 103.048 20.9786 106.413C23.7561 113.38 23.6609 121.522 26.4225 128.252C29.7713 130.537 53.5307 123.221 62.8631 121.411C65.4977 120.157 59.5142 101.731 59.1968 95.7314H59.1809Z" fill="#661A2E"/>
<path d="M4.07855 94.747C3.42783 109.65 4.18965 124.617 4.07855 139.536C3.91984 140.742 4.99909 141.25 6.09421 141.107C8.68124 140.869 11.4746 140.996 13.9505 140.282C16.1249 127.394 13.9823 113.904 16.1566 100.969C16.2201 100.603 16.5534 100.111 16.8232 100.365C17.4581 108.619 18.204 127.918 18.0929 135.632C18.0612 136.219 17.7438 140.647 18.3151 140.758C21.8227 140.456 24.3462 140.917 27.5999 141.123C26.4095 132.822 29.0442 100.508 26.0921 94.3819C18.9183 92.5091 11.2841 95.4612 4.07855 94.7628V94.747Z" fill="#2C2E2B"/>
<path d="M35.5347 107.317C35.4553 108.618 35.5029 111.57 37.5027 110.808C39.6929 109.618 47.2794 109.681 46.1843 106.19C45.0733 103.19 38.0423 107.285 35.5347 107.317Z" fill="#DDDDDD"/>
<path d="M17.7424 44.8313C17.5678 49.3388 18.4249 53.386 18.1551 58.1632C18.044 60.3376 20.2977 62.5437 22.0594 64.4324C23.6942 60.2741 21.9642 46.1962 17.7266 44.8472L17.7424 44.8313Z" fill="#034B6B"/>
<path d="M15.5664 41.0381C15.741 43.0378 18.1534 47.6247 19.2486 43.6568C19.2803 41.3714 17.8043 38.7685 15.5664 41.0381Z" fill="#034B6B"/>
<path d="M85.6716 46.5455C86.5922 40.1811 91.4805 36.864 94.9405 43.895C96.8292 55.6398 84.8622 73.4792 73.0856 76.0027C69.4829 76.0027 65.7848 77.1455 62.309 75.8281C60.2457 74.9552 57.4048 74.8917 55.7383 73.5427C57.3572 71.5588 56.3414 68.8606 56.7699 66.4323C57.0873 65.8768 57.9285 66.1625 58.5158 66.4323C61.9598 67.6544 65.9118 67.5433 69.5305 67.4481C79.7357 65.0991 84.386 56.1953 85.6558 46.5455H85.6716Z" fill="#98CA3B"/>
<path d="M103.176 39.9903C124.126 45.7992 102.843 72.3202 112.08 96.2859C103.383 97.4604 94.1613 94.5718 85.2416 95.8098C83.6386 95.5876 84.4957 90.6675 83.9719 89.1914C83.718 84.2396 84.4957 72.6535 84.4798 63.0831C85.6066 56.7504 82.5752 41.8631 90.8442 39.6411C91.6695 47.6561 89.7015 56.2584 90.8125 64.1623C94.5422 58.3852 96.5579 51.7509 99.0655 45.4024C99.7004 42.9741 102.16 42.0377 103.176 39.9903Z" fill="#7A9E18"/>
<path d="M93.9251 139.98C93.1474 142.059 94.5282 146.297 91.3698 146.455C85.4498 146.662 79.2124 148.598 73.4035 147.677C70.9434 141.805 81.228 142.821 84.6245 141.615C85.6085 141.488 86.3545 141.027 86.7036 140.139C88.9098 139.631 91.719 139.218 93.9251 139.98Z" fill="#2C2E2B"/>
<path d="M107.738 139.805C109.198 149.264 107.817 147.566 99.4213 148.804C95.993 149.058 84.7085 152.454 85.391 146.518C89.2636 144.265 94.5805 143.757 98.7229 141.471C100.215 141.138 99.4213 139.916 100.31 139.487C102.722 139.297 105.436 139.122 107.754 139.805H107.738Z" fill="#2C2E2B"/>
<path d="M108.699 94.7471C109.349 109.65 108.588 124.617 108.699 139.536C108.857 140.742 107.778 141.25 106.683 141.107C104.096 140.869 101.303 140.996 98.8267 140.282C96.6523 127.394 98.795 113.904 96.6206 100.969C96.5571 100.604 96.2238 100.112 95.954 100.366C95.3191 108.619 94.5732 127.918 94.6843 135.632C94.716 136.219 95.0335 140.647 94.4621 140.758C90.9545 140.457 88.431 140.917 85.1774 141.123C86.3677 132.822 84.165 101.667 84.9981 94.1674C92.1719 92.2946 101.493 95.4613 108.699 94.763V94.7471Z" fill="#034B6B"/>
<path d="M101.224 36.0859C102.748 37.5778 103.304 38.8317 104.827 40.3236C97.0185 44.1327 95.2568 58.3852 90.7017 64.4163C89.5748 57.8455 91.8127 46.9102 90.7334 40.3395C90.6382 38.7206 93.6379 39.4507 94.0188 37.7683C96.1297 42.117 100.256 39.7046 101.224 36.0701V36.0859Z" fill="#DDDDDD"/>
<path d="M110.433 95.6989C110.834 100 105.001 102.143 109.465 102.143C111.528 103.143 109.386 106.618 111.639 107.46C112.501 107.5 112.671 107.602 113.1 107.491C115.195 106.761 119.512 105.841 118.226 102.539C117.559 99.746 116.56 96.9209 115.195 94.4133C113.465 93.8737 111.735 94.5244 110.433 95.6989Z" fill="#C68F64"/>
<path d="M111.126 44.8947C119.68 59.4963 118.204 79.2085 118.125 95.7464C118.299 97.2224 108.11 98.0954 108.57 96.413C109.205 92.4293 109.824 88.4773 108.761 84.5254C108.062 76.6373 109.173 65.7655 107.348 53.5445C106.539 48.3229 109.507 43.2282 111.11 44.9105L111.126 44.8947Z" fill="#98CA3B"/>
<path d="M84.7206 20.7066C81.4511 12.7392 81.0861 5.4225 90.1962 2.96244C86.6728 0.55 97.1796 0.0897309 100.036 0.629356C102.893 1.16898 104.48 4.54958 106.607 6.53349C109.353 9.16813 111.876 12.5011 111.416 16.5166C111.369 18.4687 109.639 19.2464 108.416 20.3416C107.877 21.1192 108.067 22.2144 107.591 23.0238C106.163 22.4524 106.702 20.6114 107.369 19.5956C110.4 10.787 93.18 4.56545 87.5298 9.9617C86.1808 12.2948 84.5301 18.1196 84.7365 20.6907L84.7206 20.7066Z" fill="#522537"/>
</svg>
</template>



<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'INeedThis2SVG',
  components: {
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>