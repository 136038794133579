<template>
<svg xmlns="http://www.w3.org/2000/svg" width="118" height="150" viewBox="0 0 118 150" fill="none">
<path d="M23.5857 35.1151C33.0673 34.0906 36.0258 39.3344 34.9889 26.7804C25.5085 27.8049 22.5488 22.5611 23.5857 35.1151Z" fill="#5C341B"/>
<path d="M33.2411 28.5361C29.0837 27.7467 18.2396 31.4079 28.5231 30.8437C30.1861 30.3859 33.5133 30.7484 33.2411 28.5361Z" fill="#FDFDFB"/>
<path d="M31.2249 31.9639C30.0135 32.1915 27.8704 32.1247 27.649 33.706C29.2946 35.2874 35.6298 32.2138 31.2249 31.9639Z" fill="#D75C5C"/>
<path d="M22.0611 24.5399C22.7355 19.7428 24.8241 26.3192 25.3005 24.3419C25.418 21.4317 20.9253 21.1409 21.2111 24.3085C20.783 25.7228 22.4818 26.0668 22.0611 24.5399Z" fill="#5C341B"/>
<path d="M31.6083 23.1398C32.3544 26.0835 32.2777 21.6785 33.3406 21.8505C34.7549 21.587 34.208 23.6645 35.1496 23.8414C36.8163 21.54 31.424 19.3462 31.6083 23.1398Z" fill="#5C341B"/>
<path d="M29.6611 27.07C31.157 27.1962 32.0479 24.6845 30.9664 23.9532C30.4418 23.702 29.3641 24.2254 29.2181 23.3952C28.7528 22.6305 29.1686 20.7683 28.7355 20.2276C28.6588 20.1323 27.7259 20.1793 27.6912 20.3946C27.3757 22.3706 28.0092 24.1574 28.5648 24.5855C29.5633 25.3551 31.2015 24.0089 29.9333 25.8909C29.4903 26.2484 29.4383 25.6743 29.0164 25.8884C28.6254 26.2509 29.2206 27.2061 29.6598 27.07H29.6611Z" fill="#C68F64"/>
<path d="M40.3273 17.4833C42.6548 20.4046 38.9811 23.3458 38.9811 27.0838C38.9811 23.7937 37.3404 18.8604 37.067 14.4135C37.4666 20.9045 26.3974 10.4577 21.6337 9.54207C13.4265 7.85929 16.5965 22.5044 13.0986 26.2708C11.7474 21.7484 5.48029 22.6937 8.97081 27.2248C11.0594 29.6314 12.9241 28.1132 13.8385 31.167C12.6989 29.8059 10.7563 33.2754 9.55978 31.9638C3.0415 24.2478 6.19918 11.4599 11.9874 5.93895C16.6658 2.68971 22.4924 4.51478 27.5568 3.46552C36.9074 1.49197 44.9463 8.59922 40.3261 17.4858L40.3273 17.4833Z" fill="#2C2E2B"/>
<path d="M7.87093 24.6806C8.95855 21.5662 13.0653 21.9733 12.8747 26.153C14.8037 -4.84714 24.4265 13.4147 37.7847 14.167C38.7053 15.658 39.1297 25.8016 38.9317 29.5013C39.3339 34.4865 35.0527 36.6023 31.1365 37.8459C31.5993 39.5707 31.0066 43.253 32.7117 43.9533C34.7248 44.0239 37.1908 44.0882 37.1908 44.0882L32.9789 51.7127C32.9789 51.7127 25.3112 51.5444 25.2703 51.4602C25.2295 51.3761 19.4165 44.9308 19.4165 44.9308L23.2201 44.2256C25.6688 44.0362 25.2382 42.4376 25.2765 40.5135C25.3 39.3616 25.5227 38.5004 24.5502 37.9585C22.245 36.6778 17.219 36.5071 15.6587 33.582C14.727 32.3942 13.9203 30.9551 12.8388 29.8626C10.452 29.385 7.75709 27.5475 7.8734 24.6806H7.87093ZM30.534 24.0026C28.0382 24.4072 29.2954 21.2619 28.2968 20.3017C27.6485 21.122 28.3835 22.9112 28.6223 23.9617C28.9489 24.9664 30.1603 24.0916 30.4795 24.8736C30.6527 27.6094 28.8351 25.0432 28.9143 26.2768C30.0427 28.0919 32.1883 24.9627 30.534 24.0013V24.0026ZM34.4328 22.5722C34.4922 23.1562 35.117 24.6076 35.4944 23.3925C35.6454 20.8238 31.5671 19.8958 31.6104 23.1401C32.2736 26.6109 32.4939 19.4306 34.4328 22.5722ZM33.24 28.5349C30.5488 28.5894 26.5497 28.9086 24.5081 30.1224C25.545 36.1086 35.7382 34.8305 33.24 28.5349ZM21.2168 25.1298C22.6855 27.6626 21.4433 19.1559 24.5873 24.5618C27.6683 24.4888 20.8456 18.4148 21.2168 25.1298Z" fill="#F8BD84"/>
<path d="M31.4267 19.272L35.0236 18.9404C35.7697 18.8798 35.6089 17.4655 34.8578 17.6066C34.421 17.7872 28.9904 18.1275 31.4267 19.272Z" fill="#5C341B"/>
<path d="M23.1946 19.8587L19.6311 20.4465C18.8936 20.5764 18.692 19.1683 19.4542 19.1139C19.9231 19.1782 25.2634 18.1364 23.1946 19.8587Z" fill="#5C341B"/>
<path d="M18.8659 92.939C26.3097 91.5086 12.6991 79.4941 11.2774 76.0927C8.79897 70.1621 -0.734692 74.228 2.46382 77.5503C4.107 80.4011 6.91451 82.3833 9.14295 85.1413C12.5357 87.47 14.5377 91.8452 18.8647 92.9402L18.8659 92.939Z" fill="#F8BD84"/>
<path d="M55.5898 71.8891C55.1147 70.7656 52.9543 71.0663 52.0201 70.309C51.3421 69.6718 49.6618 69.2845 49.1866 70.1865C45.3682 74.8166 40.4486 80.4057 40.9113 86.1209C41.1365 88.9012 42.218 89.8045 43.2375 89.854C47.1735 90.042 54.2795 74.6743 55.5898 71.8891Z" fill="#F8BD84"/>
<path d="M33.9523 142.529C34.7752 141.339 37.7807 138.158 38.1197 137.315C38.4265 136.552 36.2191 138.091 34.2468 137.712C32.2312 136.762 32.4688 132.898 31.5804 133.84C31.3379 134.096 30.4099 137.208 30.056 137.824C29.5994 138.918 29.1651 140.717 28.3002 141.656C28.2878 142.474 29.6006 143.951 30.2243 142.454C30.6709 140.366 32.7682 142.016 33.9536 142.529H33.9523Z" fill="#F8BD84"/>
<path d="M19.9009 139.336C19.8551 141.551 19.8675 143.765 20.0123 145.976C21.2583 146.67 24.5211 148.489 25.5172 148.371C26.3487 145.593 26.7817 140.204 25.8327 138.799C24.6857 137.1 21.8708 139.66 19.9009 139.336Z" fill="#F8BD84"/>
<path d="M28.2989 141.656C27.924 142.659 26.5604 144.18 27.2323 145.101C25.8527 145.309 26.2969 144.727 25.5681 144.211C19.6685 143.219 24.1724 148.166 20.01 146.077C19.1414 150.276 22.7396 149.931 25.849 149.911C27.8411 149.607 31.4541 150.812 32.5628 148.824C33.5489 147.298 36.2921 151.119 37.6878 149.311C39.8544 146.77 35.739 145.008 34.1886 143.565C34.2294 142.187 32.8535 141.958 31.5333 141.465C29.7293 141.094 30.1809 145.042 28.2989 141.655V141.656Z" fill="#661A2E"/>
<path d="M0.126957 72.7791C0.290283 71.9043 1.35563 71.7471 1.43853 70.9243C1.29129 70.4529 1.3148 69.8998 1.80355 69.7909C2.55956 69.6214 2.14134 69.2712 2.06215 68.9458C3.97136 65.2066 6.0142 60.8623 7.42476 56.4722C10.0071 50.976 11.4077 45.09 18.6523 44.9427C23.4656 42.8516 24.4492 49.8129 29.4914 49.5209C30.8624 50.2286 31.7322 49.4763 32.7654 48.5607C36.6766 44.3327 32.5649 43.1177 40.594 44.2164C45.4839 45.1358 49.2937 57.9583 53.7432 61.012C54.9446 61.3003 54.1614 62.8408 55.2403 62.92C55.7736 63.2244 56.0632 63.8665 55.9815 64.4926C55.2564 65.6211 55.6907 65.532 56.594 66.2534C57.5455 67.9584 50.3442 65.7844 50.6399 62.7665C50.7241 61.9066 48.6639 62.2728 49.0178 63.5993C49.0895 65.1521 46.3909 63.4595 45.385 63.8962C39.5051 64.9641 43.0377 74.4321 42.0182 79.2565C43.837 86.6248 37.6887 88.8223 31.5132 89.1144C27.4634 88.6627 20.4774 89.9582 18.7006 85.5409C17.5474 77.2359 16.9263 68.6451 16.0329 60.23C14.5456 62.3656 13.3021 66.0838 12.9705 68.9446C13.3071 70.0804 11.0143 70.4417 12.0809 71.2559C13.2328 72.1666 10.4067 72.9399 11.4585 73.6613C12.551 75.9033 10.5626 74.8145 9.54432 74.2824C7.5089 74.4012 4.83749 73.7999 3.20792 75.13C0.0848885 75.5754 0.993095 78.7801 0.125725 72.7815L0.126957 72.7791Z" fill="#F37617"/>
<path d="M18.864 92.938C21.116 92.792 21.0541 88.6939 19.7141 87.0668C23.5114 87.6348 34.103 89.643 40.9554 85.7367C41.558 85.394 41.5852 87.495 41.7856 88.8239C42.2905 89.669 42.8992 90.0859 43.9869 89.7222C46.6014 98.438 50.6029 106.856 51.6484 115.926C48.2025 123.809 43.1591 131.184 38.5847 138.458C36.1595 139.27 29.1896 136.433 31.1483 133.167C33.9187 127.722 37.2496 122.596 39.968 117.155C42.293 111.072 35.3812 104.151 33.5821 98.266C28.2876 98.0964 30.3279 101.7 29.6697 105.407C28.8394 116.744 28.2393 127.877 27.2668 139.24C27.9745 141.3 18.5361 141.334 19.2835 139.414C18.9135 124.218 19.0348 108.578 18.8653 92.9392L18.864 92.938Z" fill="#3A1A28"/>
<path d="M84.0945 28.981H96.5693V35.1936H84.0945V28.981Z" fill="white"/>
<path d="M82.7292 14.0357C82.9198 13.542 83.0893 13.3985 83.5657 13.7858C86.4969 16.2085 90.4539 17.1723 94.1857 16.7158C97.6304 16.3668 101.153 15.3102 103.988 13.2599C104.531 12.8404 104.631 12.8776 104.717 13.542C106.857 17.4495 103.322 35.9588 106.965 25.5355C108.242 21.8804 112.304 25.5503 110.032 28.6412C108.724 30.079 106.579 30.048 105.554 31.7135C103.552 35.3005 100.379 37.5946 96.5626 38.525C95.2535 38.8443 94.2971 40.266 93.8454 42.5798C93.1463 46.168 93.5967 50.0805 93.5089 53.3916C87.9867 54.1872 84.193 53.2468 83.9666 50.9132C83.6845 48.0117 87.8011 43.144 87.8976 39.0125C86.4165 37.7393 83.2489 37.3496 81.4857 35.5604C78.4777 28.8119 79.8685 20.5515 82.7292 14.0382V14.0357ZM84.8612 17.7576C83.6535 17.751 83.0151 18.1874 82.9458 19.0667C82.8455 20.3436 82.848 20.3709 84.073 20.2199C85.2856 19.699 87.3136 20.7173 88.0461 19.6371C89.078 17.3815 85.8052 18.0014 84.8599 17.7576H84.8612ZM89.9602 24.1583C89.8291 24.7151 89.6125 24.8995 89.1003 24.8067C87.295 24.3848 87.7107 28.1661 89.391 27.7602C89.7585 27.7528 90.2881 27.2047 89.9862 26.8631C88.369 27.7652 87.9372 25.5392 89.1918 25.4105C90.8993 25.6431 90.7051 23.1932 90.981 22.0054C90.5739 19.7349 90.1124 23.4196 89.9614 24.1583H89.9602ZM98.8232 22.0066C99.8304 22.6315 99.6151 20.8089 99.9071 20.2583C99.8836 19.0927 94.1572 17.5547 94.7054 19.8079C94.7413 21.4882 97.7517 20.7878 98.8232 22.0066ZM85.4563 32.3346C86.8372 36.3659 94.1461 35.5158 94.5916 31.0206C92.9014 29.5853 89.1696 29.8649 86.9188 29.3997C84.9181 28.6313 85.5343 30.9798 85.4563 32.3359V32.3346Z" fill="#FDCD9D"/>
<path d="M84.8647 17.7579C83.657 17.7513 83.0186 18.1877 82.9493 19.067C82.849 20.3439 82.8515 20.3711 84.0765 20.2189C85.2903 19.6993 87.3158 20.7164 88.0496 19.6374C89.0803 17.3805 85.8075 18.0016 84.8634 17.7579H84.8647Z" fill="#5C341B"/>
<path d="M94.7035 19.8089C94.7357 21.488 97.7523 20.7901 98.8214 22.0077C99.8323 22.6338 99.6108 20.8075 99.9053 20.2593C99.8892 19.0937 94.1529 17.5545 94.7035 19.8077V19.8089Z" fill="#5C341B"/>
<path d="M95.682 23.773C91.433 25.4174 99.4608 27.803 98.0156 24.6601C97.9327 24.4795 99.253 24.6069 99.1057 24.3941C98.9795 24.2976 97.4205 23.8979 97.2955 23.841C96.7238 23.5812 96.1411 23.596 95.6832 23.773H95.682Z" fill="#5C341B"/>
<path d="M96.5263 24.9122C96.559 25.2831 96.4035 25.3927 96.1547 25.3581C95.9961 25.337 95.7597 25.46 95.7504 25.0986C95.7411 24.7431 95.895 24.5797 96.1811 24.5836C96.377 24.5874 96.559 24.5951 96.5263 24.9103V24.9122Z" fill="white"/>
<path d="M90.9873 22.0059C90.5802 19.7342 90.1187 23.4227 89.9677 24.1589C89.8366 24.7157 89.62 24.9001 89.1078 24.8073C87.3025 24.3841 87.7183 28.1679 89.3986 27.7608C89.7661 27.7583 90.2956 27.2015 89.9937 26.8637C88.3765 27.767 87.9447 25.5385 89.1981 25.4098C90.9056 25.6437 90.7114 23.1938 90.9873 22.0047V22.0059Z" fill="#C68F64"/>
<path d="M86.1686 24.3007C87.8242 23.3331 86.6648 22.0785 84.664 22.2467C84.5688 22.2542 82.8885 22.4855 82.8056 22.5412C82.6905 22.5981 84.2879 22.6971 84.2025 22.7615C82.9256 23.7155 84.7531 25.1273 86.1686 24.2995V24.3007Z" fill="#5C341B"/>
<path d="M82.8628 2.58466C83.844 -0.451757 98.1798 -0.658391 103.245 1.16049C109.117 2.03034 107.092 6.93884 109.27 7.12816C114.063 9.22668 112.102 16.7447 112.028 20.458C111.057 24.2059 110.17 23.9584 108.867 23.9955C105.918 24.0796 106.922 28.8446 106.508 28.7135C106.049 28.5675 104.05 29.5301 103.77 28.7159C103.243 27.1804 104.677 21.8896 104.569 19.8678C104.04 9.88618 96.7828 23.68 83.5706 13.785C80.8509 14.8825 80.476 27.1544 80.716 12.3138C80.7829 8.18731 82.1625 1.81628 82.8641 2.58343L82.8628 2.58466Z" fill="#C68F64"/>
<path d="M85.5976 23.3507C85.6303 23.7216 85.4748 23.8312 85.226 23.7966C85.0674 23.7754 84.831 23.8984 84.8217 23.5371C84.8124 23.1816 84.9663 23.0182 85.2524 23.022C85.4483 23.0259 85.6303 23.0336 85.5976 23.3488V23.3507Z" fill="white"/>
<path d="M74.1958 148.723C76.621 143.183 72.7852 147.963 73.0909 144.827C73.733 142.533 73.3556 140.888 70.7065 140.821C67.8235 140.414 69.2724 141.432 69.6758 143.282C67.3669 142.971 61.794 142.051 62.8779 146.179C66.1692 147.801 70.4665 148.996 74.1958 148.723Z" fill="#661A2E"/>
<path d="M96.9175 141.324C97.4953 135.448 94.9625 139.269 91.7046 138.493C89.8201 138.206 90.414 146.017 91.8778 146.372C94.5282 147.282 94.3277 147.913 96.6997 146.976C97.2318 146.766 96.8469 143.411 96.9175 141.324Z" fill="#FDCD9D"/>
<path d="M70.7109 140.82C75.7988 140.622 71.2998 145.669 73.965 145.987C76.3024 145.527 75.8879 142.037 76.6562 140.017C74.7916 140.241 70.1479 137.916 70.7109 140.82Z" fill="#FDCD9D"/>
<path d="M84.4266 49.9004C90.0182 54.2595 94.9762 50.2691 95.2657 52.6176C96.5104 62.7489 92.1526 88.3642 92.1303 93.243C88.4641 95.2982 79.9116 93.243 79.9116 93.243L84.4266 49.9029V49.9004Z" fill="#FDFDFB"/>
<path d="M90.6309 93.4573C90.8882 100.738 99.4246 94.8654 99.4172 86.6C99.4135 82.2421 102.162 54.1348 104.172 62.253C106.902 71.2274 107.425 68.577 115.569 69.2749C114.394 64.7537 111.176 54.3118 109.083 49.7522C107.595 47.5374 99.4729 46.3198 97.435 45.5378C98.7416 49.548 101.164 50.8101 95.4565 51.6614C90.8709 51.4127 90.767 88.0576 90.6296 93.4573H90.6309Z" fill="#98CA3B"/>
<path d="M99.4226 86.5995C98.21 89.1113 93.4178 94.4702 99.1961 94.2834C103.96 89.1559 110.342 84.6049 114.749 78.7783C118.513 74.8671 109.774 72.6449 107.725 74.1915C107.558 74.3177 101.834 84.1459 99.4238 86.5995H99.4226Z" fill="#FDCD9D"/>
<path d="M107.356 74.5566C111.511 73.3118 118.345 79.3562 117.986 72.4135C117.341 68.8488 113.534 69.0529 110.609 68.9774C106.377 68.1002 106.672 71.4731 107.356 74.5578V74.5566Z" fill="#7A9E18"/>
<path d="M64.2371 68.8167C60.2702 68.1028 55.3902 66.8778 51.8996 64.9117C51.177 63.9416 50.6437 61.2937 49.0426 62.2465C48.2903 62.6536 49.4089 64.5888 48.0812 63.8538C45.025 61.7503 40.4493 64.9414 43.2111 68.0818C44.9965 69.9984 47.8399 69.5554 50.3307 69.8821C53.2137 71.7814 56.1585 72.5325 59.1541 74.136C62.2178 74.5233 66.1624 78.1326 68.9489 75.6444C72.8613 71.9299 67.5433 69.6544 64.2359 68.8155L64.2371 68.8167Z" fill="#FDCD9D"/>
<path d="M97.4366 45.5375C92.6581 38.0083 93.0886 48.5776 93.6207 52.0149C99.7554 50.6799 100.156 51.5213 97.4366 45.5375Z" fill="#7A9E18"/>
<path d="M85.1148 51.8821C88.8862 43.5721 87.2207 37.5995 80.1481 48.9409C80.8076 50.4517 83.8007 50.6769 85.1148 51.8821Z" fill="#7A9E18"/>
<path d="M74.5693 68.4344C74.3701 65.2879 69.2364 64.9847 66.8013 63.61C65.4564 63.3366 62.0302 68.0644 63.9802 68.6546C70.6185 71.649 70.9514 76.7753 74.5693 68.4344Z" fill="#7A9E18"/>
<path d="M71.1699 132.375C72.3603 119.106 73.2338 105.701 74.4996 92.5527C75.4548 92.6777 81.9867 94.0511 82.0783 92.8584C84.4713 93.8779 89.9527 94.1043 90.6481 93.7567C90.6481 93.7567 90.6592 93.9621 90.7099 94.3184C90.8423 95.2563 91.3831 96.3179 93.0213 94.9569C97.8902 90.2154 94.0606 93.8878 98.4062 94.2924C99.5297 95.7401 98.3282 99.3321 98.7835 101.459C98.6883 112.146 97.5425 130.604 97.4646 141.325C93.5781 142.838 90.1086 143.055 90.0368 141.035C89.8475 135.638 89.2722 129.53 88.135 123.594C87.3988 116.327 86.8618 108.962 86.6107 101.783C86.8655 100.371 84.0803 100.073 84.3983 101.797C83.0682 112.803 78.9788 130.864 76.6489 141.703C76.9805 142.831 70.1244 140.247 70.1974 139.934C70.6552 137.975 71.0821 135.195 71.1675 132.373L71.1699 132.375Z" fill="#52545E"/>
<path d="M69.2664 58.6819C72.3585 53.5742 74.4212 46.3259 81.4715 45.9386C84.034 45.1022 78.9671 48.9194 80.4507 49.4576C86.4035 51.3891 85.0239 53.9442 84.6452 59.3006C84.232 65.3499 83.9858 73.3431 83.1963 79.1685C83.2062 84.5274 81.9355 89.8517 81.8984 95.1945C81.7091 94.9383 75.2959 94.1811 74.5016 92.5503C74.436 83.0055 75.7797 73.4582 76.4058 63.9926C76.5815 60.5627 74.7651 68.4396 73.0242 66.2074C67.7507 63.4568 65.3453 65.0233 69.2677 58.6795L69.2664 58.6819Z" fill="#98CA3B"/>
<path d="M94.9425 149.897C98.4875 150.149 108.936 149.773 101.191 145.533C100.304 144.967 96.2108 145.675 97.2984 144.042C99.8052 141.723 95.8717 142.542 94.2088 142.375C92.9454 142.641 93.3575 144.847 93.1917 145.88C93.1558 147.65 91.8269 145.054 90.9694 144.966C90.7937 148.287 91.8108 150.097 94.9437 149.895L94.9425 149.897Z" fill="#661A2E"/>
</svg>
</template>



<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'INeedThis1SVG',
  components: {
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>