<template>
    <div class="root" id="contacts" ref="contacts" v-observe-visibility="(isVisible: Boolean) => {  if (isVisible) setActiveMenuItem('contacts') }">
      <CrowWithListView class="crow" />
      <div class="contacts">
        <h1>Наши контакты</h1>
        <p>E-mail: info@oros-it.ru</p>
      </div>

      <div> 
        <div class="form">
          <p class="text">Напишите нам свой вопрос и мы ответим вам в ближайшее время</p>
          <div class="form-content">
            <div class="form-fields"> 
              <input placeholder="Имя" v-model="name" />
              <input placeholder="E-mail" v-model="email"  />
              <div class="checkbox first">
                <input type="checkbox" class="checkbox" id="politics2" v-model="politics"  />
                <label class="politics" for="politics2">Я согласен с политикой конфиденциальности сайта</label>
              </div>
            </div>
            <div class="form-fields"> 
              <textarea placeholder="Текст сообщения" v-model="message"></textarea>
              <div class="checkbox second">
                <input type="checkbox" class="checkbox" id="politics-contacts-second" v-model="politics"  />
                <label class="politics" for="politics-contacts-second">Я согласен с политикой конфиденциальности сайта</label>
              </div>
              <button class="btn-yellow" type="submit" @click.prevent="onSend" :disabled="name === '' || email === '' || !isEmailCorrect() || message === '' || !politics">Отправить</button>
            </div>
          </div>
        </div>
        <h1 class="bottom-title">«Белая ворона» — ваш надежный спутник на пути к успешному общению!</h1>
      </div>
    </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import { mapMutations, mapActions } from 'vuex';
import { sendMetrik } from '../utils/metrika';
import { checkEmail } from '@/utils/email';
import CrowWithListView from '@/components/crows/CrowWithListView.vue';

export default defineComponent({
  name: 'ContactsView',
  data: () => {
    return {
      name: '',
      email: '',
      message: '',
      politics: false,
    }
  },
  methods: {
    ...mapMutations(['setActiveMenuItem']),
    sendMetrik: sendMetrik,
    ...mapMutations([
        'showAlert'
    ]),
    ...mapActions([
        'sendContactUsMessage',
    ]),
    isEmailCorrect() {
        return checkEmail(this.email);
    },
    async onSend() {
        const result = await this.sendContactUsMessage({
            name: this.name,
            email: this.email,
            message: this.message,
        })
        
        if (result.status === 201) {
          this.sendMetrik('send-fos');
            this.showAlert({
                text: 'Ваше сообщение отправлено. Спасибо за ваше сообщение!',
                class: 'alert-success'
            });

            this.name = '';
            this.email = '';
            this.message = '';
        }
    }
  },
  components: {
    CrowWithListView,
  }
});
</script>

<style scoped>
.root {
  display: flex;
  flex-direction: row;
}
.contacts {
  width: 40%;
  margin-bottom: 50px;
}
.form {
  background: linear-gradient(45deg, var(--light-green-color) 25%, var(--green-color) 75%);
  padding: 40px 60px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.form-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-fields {
  display: flex;
  flex-direction: column;
  width: 48%;
}

.text {
  font-family: "Odds", Arial, sans-serif;
  font-size: 1.5em;
  margin-top: 10px;
}

.checkbox.second {
  display: none;
}

.crow {
  width: 20%;
  left: 350px;
  bottom: -250px;
  z-index: 3;
}

@media (min-width: 1366px) and (max-width: 1920px) {
  .crow {
    left: 350px;
  }
}


@media (min-width: 960px) and (max-width: 1366px) {
  .crow {
    left: 280px;
  }
}


@media (min-width: 1024px) and (max-width: 1120px) {
  .crow {
    left: 200px;
  }
}


@media (max-width: 1024px) {
  .root {
    flex-direction: column;
  }

  .crow {
    width: 30%;
    top: -110%;
    left: 40px;
  }

  .form {
    padding: 20px 30px;
  }

  .contacts {
    width: 100%;
    margin: 40px 0;
  }
  .form-content {
    flex-direction: column;
  }

  .form-fields {
    width: 100%;
  }

  .checkbox.first {
    display: none;
  }

  .checkbox.second {
    display: flex;
  }

  .root h1.bottom-title {
    width: 65%;
    right: 0;
    position: relative;
    text-align: right;
    float: right;
    font-size: 3em !important;
    line-height: 1em !important;
  }
}
</style>