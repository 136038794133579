<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="48" viewBox="0 0 45 48" fill="none">
<g opacity="0.8" filter="url(#filter0_f_244_14024)">
<ellipse cx="22.5641" cy="43.3608" rx="15.4059" ry="0.840323" fill="#315613"/>
</g>
<path d="M22.4258 43.6171C34.4115 43.6171 44.1279 33.9008 44.1279 21.915C44.1279 9.92926 34.4115 0.212891 22.4258 0.212891C10.44 0.212891 0.723633 9.92926 0.723633 21.915C0.723633 33.9008 10.44 43.6171 22.4258 43.6171Z" fill="url(#paint0_linear_244_14024)"/>
<path d="M11.7871 21.9136L19.9688 30.8509L33.0637 12.9785" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<filter id="filter0_f_244_14024" x="4.1582" y="39.5205" width="36.8115" height="7.68066" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_244_14024"/>
</filter>
<linearGradient id="paint0_linear_244_14024" x1="44.1279" y1="43.6171" x2="6.91702" y2="19.2736" gradientUnits="userSpaceOnUse">
<stop stop-color="#6DC527"/>
<stop offset="1" stop-color="#9DEC1C"/>
</linearGradient>
</defs>
</svg>
</template>



<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SuccessSVG',
  components: {
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
