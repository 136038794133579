<template>
    <div class="head"><router-link to="/"><LogoView @click="sendMetrik('logo-click')" /></router-link></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LogoView from '@/components/LogoView.vue';
import { sendMetrik } from '@/utils/metrika';

export default defineComponent({
  name: 'HeaderView',
  props: {
  },
  components: {
    LogoView,
  },
  methods: {
    sendMetrik: sendMetrik,
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.head {
  position: fixed;
  text-align: left;
  top: 60px;
}

@media (max-width: 1024px) {
  .head {
    width: 100%;
    top: 0;    
    left: 0;
    padding: 20px 0 15px 20px;
    background: white;
    z-index: 1;
  }
}
</style>