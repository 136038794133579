<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="92" height="150" viewBox="0 0 92 150" fill="none">
<path d="M26.5707 63.1684C26.3311 88.1411 39.0604 84.2288 8.41005 84.3267C6.68174 80.1443 11.3468 72.6842 10.5941 67.3103C10.7392 65.3862 6.33743 67.0943 6.10789 64.8664C10.2768 65.0655 6.53659 63.1617 5.26062 62.0984C4.00827 54.7598 7.81594 51.445 14.7798 50.51C16.9807 52.8729 20.2179 52.8695 22.4728 50.5066C29.1328 52.5488 32.8122 49.6796 36.2081 57.4468C33.457 59.4688 26.9016 59.4654 26.5741 63.1684H26.5707Z" fill="#C68F64"/>
<path d="M10.2936 61.326C10.6953 67.0342 7.94753 77.7821 7.94078 83.4598C7.93403 89.1376 5.59137 89.3198 7.6201 95.882C7.90365 96.9588 7.22178 96.6719 6.66143 96.0001C6.11796 97.212 7.68423 97.9782 7.12051 98.9099C2.40143 93.1511 5.9458 91.1528 6.09433 83.9358C6.32725 72.4925 4.6192 63.456 5.05802 60.4889C4.91625 61.4509 8.82856 60.8703 10.2902 61.3294L10.2936 61.326Z" fill="#F8BD84"/>
<path d="M4.6001 143.663C4.6136 146.107 5.11319 146.677 2.24056 146.394C2.22706 143.95 1.72747 143.383 4.6001 143.663Z" fill="white"/>
<path d="M2.19922 144.652C2.36462 145.314 2.06082 145.726 2.82033 145.797C3.82625 146.057 4.15031 145.598 4.57563 144.76C5.7841 144.656 4.25495 148.666 3.41443 148.764C-1.70297 149.368 -0.1637 146.458 2.19584 144.656L2.19922 144.652Z" fill="#661A2E"/>
<path d="M22.9585 144.304C22.972 146.748 23.4716 147.318 20.599 147.035C20.5855 144.591 20.0859 144.024 22.9585 144.304Z" fill="white"/>
<path d="M20.2348 144.868C18.6381 127.089 20.3428 109.009 18.6584 91.2401C18.6854 86.0687 8.22441 131.366 5.69947 143.596C5.33153 145.381 2.77958 144.264 2.21248 143.822C1.71627 143.471 1.87155 142.343 1.88505 142.131C3.41757 134.579 3.54584 123.372 6.4151 113.843C9.28435 104.314 3.75175 83.5471 11.6338 82.7944C15.6035 83.9353 28.64 80.1142 28.2721 85.0324C27.4754 94.7609 26.2737 104.459 25.3083 113.941C25.3083 118.154 23.796 137.303 22.9589 144.301C22.8576 145.165 21.4534 145.604 20.2382 144.865L20.2348 144.868Z" fill="#98CA3B"/>
<path d="M20.3534 145.168C21.6598 145.709 20.5256 147.001 21.8589 146.708C23.4995 147.379 21.6496 144.878 23.1788 145.614C27.108 151.879 16.9913 151.167 20.3568 145.165L20.3534 145.168Z" fill="#661A2E"/>
<path d="M1.51024 31.8673C1.66552 31.5298 1.82417 31.3914 2.25625 31.55C3.2453 31.9112 4.09932 31.6277 4.69343 30.75C4.82507 30.5542 5.41243 30.1289 5.94577 29.7204C5.87151 29.6158 5.831 29.5584 5.831 29.5584L13.2708 22.2975C13.2708 22.2975 20.1942 20.819 20.4743 20.954C20.7545 21.0891 27.4753 21.3591 27.5665 21.4907C27.6576 21.6258 30.8374 26.0613 31.2425 26.8242C31.6475 27.5871 34.6046 31.3036 34.6923 31.6209C34.7801 31.9382 35.7219 35.2058 35.7219 35.7898C35.7219 36.3737 32.3598 40.4076 32.272 40.5392C32.1843 40.6709 25.4162 48.0229 25.4162 48.0229L22.682 48.32L23.2356 50.639H22.2668C22.2836 51.1217 21.7874 51.1859 21.8043 51.6686C21.4229 52.0635 21.4803 52.6205 21.3452 53.0931C21.1427 53.7952 20.7815 54.423 20.552 55.1049C19.9612 55.564 19.6878 56.2425 19.3334 56.8636C18.9114 57.5995 18.7798 57.6062 18.341 56.8636C17.9831 56.2627 17.6523 55.6416 17.3114 55.0307C16.984 53.8559 16.1266 52.9884 15.924 51.7732C15.9173 51.277 15.6067 51.0036 15.6033 50.5074C15.6033 50.2981 15.6033 50.0888 15.6033 49.8795C15.6033 49.8154 15.6033 49.7546 15.6033 49.6905C15.6067 49.4238 15.5493 49.2753 15.2253 49.1875C14.4995 48.985 14.351 48.2795 13.6523 47.9925C9.33487 46.217 6.59051 42.9933 5.06474 38.6523C4.9601 38.3552 4.9466 38.0345 4.48414 38.0042C1.4461 37.8117 0.473933 34.0918 1.50349 31.8673H1.51024Z" fill="#F8BD84"/>
<path d="M4.18051 54.2201C7.08015 62.8988 10.1351 52.8361 14.3714 51.7323C15.0871 51.4724 15.8432 51.8403 16.1807 51.5568C19.3234 48.9002 6.99914 46.0714 6.08097 38.4426C5.75354 35.7151 2.88091 31.7724 5.14593 31.0534C6.89112 31.7758 8.85571 36.5658 7.45146 32.1066C8.13671 34.2872 10.5401 27.6643 10.9418 25.8179C14.6347 27.5799 16.6871 24.0862 18.0441 22.8811C20.3023 25.1664 24.029 22.6988 25.7877 22.6617C25.5413 26.4559 29.2375 27.8736 31.1684 29.1597C31.0131 30.331 30.7363 32.3598 31.9785 32.9674C36.4546 29.7606 35.9043 38.2637 31.5296 38.9523C31.5532 46.1086 15.4381 47.5364 24.4037 52.9981C25.7202 53.3897 30.3279 58.048 32.0832 56.7349C33.1904 54.6657 40.7584 55.621 37.5314 52.0766C32.0629 46.0714 39.7626 39.4721 39.1584 37.6865C39.8504 36.7413 39.236 30.4087 36.4647 27.6238C35.847 23.1478 37.1769 17.8008 30.4865 16.0523C29.4232 12.7239 25.494 11.9138 22.3986 12.0826C20.6872 12.5585 20.1336 14.0134 18.3445 12.913C9.88527 9.19982 12.6533 21.1359 6.16536 20.4878C3.53578 20.2245 -1.88543 35.4721 1.1121 36.7953C2.07077 37.2172 0.163558 55.1585 4.18051 54.2235V54.2201Z" fill="#522537"/>
<path d="M25.4537 29.6695C20.1844 30.7767 25.8082 31.2256 27.7525 31.4079C30.0513 31.094 26.1491 29.6189 25.4537 29.6695Z" fill="#522A2F"/>
<path d="M13.2542 29.6695C18.5235 30.7767 12.8997 31.2256 10.9554 31.4079C8.65661 31.094 12.5588 29.6189 13.2542 29.6695Z" fill="#522A2F"/>
<path d="M25.39 35.4379C25.7714 35.2759 26.6862 34.6278 25.7883 33.3552C26.5681 32.6429 28.3672 32.8421 28.3672 32.8421C28.3672 32.8421 25.39 32.302 24.6608 32.5315C23.2262 33.2235 23.449 35.762 25.39 35.4413V35.4379Z" fill="#2C2E2B"/>
<path d="M13.0116 35.4113C12.6268 35.2628 11.685 34.6552 12.5255 33.3454C11.7188 32.6669 9.92969 32.937 9.92969 32.937C9.92969 32.937 12.8833 32.272 13.6158 32.4745C15.0775 33.1058 14.9627 35.651 13.0116 35.4113Z" fill="#2C2E2B"/>
<path d="M24.8094 34.5439C24.5832 34.5439 24.4043 34.3717 24.4043 34.1624C24.4043 33.9532 24.5866 33.781 24.8094 33.781C25.0322 33.781 25.2144 33.9532 25.2144 34.1624C25.2144 34.3717 25.0322 34.5439 24.8094 34.5439Z" fill="white"/>
<path d="M13.3484 34.5439C13.1256 34.5439 12.9434 34.3717 12.9434 34.1624C12.9434 33.9532 13.1256 33.781 13.3484 33.781C13.5712 33.781 13.7535 33.9532 13.7535 34.1624C13.7535 34.3717 13.5712 34.5439 13.3484 34.5439Z" fill="white"/>
<path d="M16.1884 41.741C13.8863 42.8617 17.991 45.2415 19.564 44.8769C20.9075 45.2043 24.0232 43.6954 22.4805 41.9334C21.6029 44.1478 16.9446 44.1073 16.1884 41.741Z" fill="#AC2C2D"/>
<path d="M22.5874 42.7738C25.1765 41.9671 22.1283 39.9721 19.1983 40.6843C15.9678 39.908 13.7737 41.3696 15.5088 42.6354C16.4978 41.0422 21.7401 41.0725 22.5874 42.7738Z" fill="#AC2C2D"/>
<path d="M15.4922 41.3727C16.3935 44.7888 22.5134 44.2926 23.2358 41.609C20.7109 41.609 18.0138 41.3761 15.4922 41.3727Z" fill="#FDFDFD"/>
<path d="M17.3828 37.3088C18.8444 38.9291 19.9314 38.9831 20.4715 37.4202C19.4588 38.7232 18.4225 38.2371 17.3828 37.3088Z" fill="#BB8B6C"/>
<path d="M60.0524 82.679C60.7512 85.9466 60.6398 89.3222 60.525 92.6606C60.5047 93.2311 60.4845 93.7982 60.4676 94.3687C60.417 95.8506 60.3663 97.3325 60.309 98.811C60.2955 99.1485 60.2786 99.4962 60.13 99.7967C59.968 100.121 59.671 100.354 59.4144 100.61C58.8878 101.137 58.5165 101.815 58.3477 102.541C58.3241 102.636 58.3106 102.75 58.3747 102.825C58.4659 102.929 58.638 102.879 58.7629 102.814C59.3165 102.541 59.8499 102.227 60.3596 101.876C60.3967 102.585 59.8262 103.169 59.5967 103.841C59.3334 104.617 59.5359 105.468 59.7419 106.264C59.7689 106.376 59.8229 106.507 59.9343 106.524C60.0052 106.534 60.0693 106.494 60.13 106.457C60.9469 105.927 61.7436 105.333 62.3174 104.546C63.0904 103.49 63.4145 102.16 63.4955 100.85C63.5765 99.5401 63.4348 98.2304 63.3774 96.9206C63.1951 92.9172 63.7824 88.9137 64.6263 84.998C64.6938 84.6875 64.7614 84.3533 64.6466 84.0563C64.5723 83.8638 64.4306 83.7052 64.2787 83.5634C63.6002 82.9288 62.7022 82.5372 61.7773 82.4697L60.049 82.679H60.0524Z" fill="#F8BD84"/>
<path d="M52.1297 137.681C52.0251 138.083 52.1533 138.518 52.3896 138.859C52.6259 139.2 52.9601 139.464 53.301 139.703C54.0909 140.26 54.9416 140.723 55.8361 141.087C56.0657 141.182 56.2986 141.27 56.5079 141.401C56.6969 141.523 56.8623 141.678 57.0277 141.833C57.1965 141.992 57.3653 142.154 57.5037 142.34C57.9627 142.961 57.9965 143.845 57.6623 144.54C57.3248 145.236 56.653 145.739 55.907 145.948C55.1644 146.154 54.3576 146.08 53.6285 145.82C53.4293 145.749 53.2302 145.661 53.0242 145.617C52.5044 145.509 48.7744 141.867 47.8258 140.672C47.5625 140.341 47.2959 139.994 47.1743 139.588C47.0528 139.183 47.0967 138.697 47.3971 138.4C47.6841 138.113 48.1263 138.059 48.5313 138.022C49.679 137.914 50.8267 137.806 51.9744 137.698" fill="#AC2C2D"/>
<path d="M36.1428 137.681C36.2475 138.083 36.1192 138.518 35.8829 138.859C35.6466 139.2 35.3124 139.464 34.9715 139.703C34.1816 140.26 33.331 140.723 32.4364 141.087C32.2069 141.182 31.974 141.27 31.7647 141.401C31.5757 141.523 31.4102 141.678 31.2448 141.833C31.0761 141.992 30.9073 142.154 30.7689 142.34C30.3098 142.961 30.276 143.845 30.6102 144.54C30.9478 145.236 31.6195 145.739 32.3655 145.948C33.1082 146.154 33.9149 146.08 34.6441 145.82C34.8432 145.749 35.0424 145.661 35.2483 145.617C35.7681 145.509 39.4982 141.867 40.4433 140.672C40.7066 140.341 40.9733 139.994 41.0948 139.588C41.2163 139.183 41.1725 138.697 40.872 138.4C40.5851 138.113 40.1429 138.059 39.7378 138.022C38.5901 137.914 37.4424 137.806 36.2947 137.698" fill="#AC2C2D"/>
<path d="M65.8815 75.7595C65.2098 70.9594 64.5245 66.3247 63.5355 61.5753C63.2688 58.7533 61.6249 56.6435 59.478 54.9254C55.9269 53.2814 51.7985 52.7616 48.9023 49.9295C48.2981 49.8586 48.163 50.6282 47.6128 50.6552C46.9681 51.8198 45.6651 51.7354 44.7604 52.4274C43.2144 52.5388 41.2667 50.797 40.0177 49.9227C38.6337 50.9489 37.4995 52.3329 35.6868 52.6232C24.8309 55.2325 28.0512 57.5212 26.728 67.0134C25.9246 70.1155 29.7795 84.3032 28.9897 82.45C29.5027 78.6423 30.7551 70.8683 31.5619 67.1113C34.5121 93.7886 25.5938 90.6661 47.8592 90.656C50.2491 90.6121 52.6492 90.8012 55.029 90.6358C56.1497 90.4299 55.4712 90.9024 56.3657 90.4096C56.6729 83.0913 57.0105 74.4565 57.2062 67.0842C58.0569 70.9628 59.505 75.3511 59.1067 79.2161C58.4653 79.7022 58.0096 83.5031 59.1371 83.5504C61.1827 83.4626 63.3903 83.709 65.3246 84.1074C67.6875 84.3234 66.668 82.1124 67.0495 80.6035C67.1305 79.979 67.3499 79.1993 66.4216 78.9022C65.9052 78.0583 66.1279 76.7553 65.8849 75.7595H65.8815Z" fill="#034B6B"/>
<path d="M45.1626 57.3188C47.1778 55.9652 48.8217 51.425 48.842 49.4469C48.8589 47.6477 49.5508 45.9498 53.6556 45.0046C59.2726 41.6054 58.8371 35.985 62.8574 35.4652C65.2237 33.2576 65.5039 27.887 61.3688 25.3958C61.5781 26.4828 61.3688 30.2567 60.3764 28.8355C64.3393 -1.25788 46.3137 0.207127 29.2973 11.5525C27.0593 12.9162 28.8247 25.9157 28.2137 29.4566C26.8061 29.1056 28.0011 36.0897 28.8247 36.9403C30.58 41.4062 34.337 45.6831 39.8663 46.419C40.4199 46.7599 40.4739 48.4039 40.4908 50.1187C40.5076 51.7693 44.0385 59.5636 45.1626 57.3154V57.3188Z" fill="#F8BD84"/>
<path d="M47.8567 90.6557C43.7081 90.649 39.5562 90.6557 35.4076 90.6557C35.1139 90.6557 34.6784 90.4599 34.3848 90.4802C34.2295 90.5409 33.7164 89.9502 33.5611 90.011C32.6564 92.9511 33.6117 98.8652 33.4733 98.8348C33.4733 99.6821 35.7957 138.059 36.1029 138.312C37.892 139.781 39.9916 140.061 42.206 139.896C42.0507 126.451 41.8954 113.002 41.7368 99.5572C41.7233 98.4702 41.7165 98.8922 41.6457 97.8086C41.6018 97.1706 43.4212 95.3951 44.1065 95.4187C45.4905 95.4626 43.8398 95.4491 45.2204 95.4187C45.747 95.4086 47.3167 97.3023 47.3065 97.866C47.2188 102.75 46.8002 137.671 46.7968 139.933C47.6677 139.899 48.5386 139.953 49.4129 139.845C51.1311 139.636 52.4712 138.667 53.8552 137.762C54.1894 135.7 55.5328 100.381 55.5464 100.148C55.4215 99.7124 55.5801 99.2837 55.5767 98.8517C55.462 98.8584 55.7894 90.8346 56.181 90.5038C56.0628 90.4093 50.034 90.6591 47.8567 90.6557Z" fill="#2C2E2B"/>
<path d="M35.5078 25.0074C35.1432 28.2682 38.8361 28.3358 38.6099 24.923C38.6403 22.4656 35.2445 22.3711 35.5078 25.0074Z" fill="#2C2E2B"/>
<path d="M50.3234 24.9467C49.9959 27.8497 53.3344 28.6835 53.439 25.2134C53.6618 22.3576 49.9993 22.5331 50.3234 24.9467Z" fill="#2C2E2B"/>
<path d="M51.8062 26.1755C51.4855 26.1755 51.2256 25.8177 51.2256 25.3721C51.2256 24.9265 51.4855 24.5687 51.8062 24.5687C52.1269 24.5687 52.3868 24.9265 52.3868 25.3721C52.3868 25.8177 52.1269 26.1755 51.8062 26.1755Z" fill="white"/>
<path d="M36.9126 26.0982C36.592 26.0982 36.332 25.7404 36.332 25.2948C36.332 24.8493 36.592 24.4915 36.9126 24.4915C37.2333 24.4915 37.4932 24.8493 37.4932 25.2948C37.4932 25.7404 37.2333 26.0982 36.9126 26.0982Z" fill="white"/>
<path d="M42.249 34.9013C46.0769 35.168 47.5048 33.8042 46.2727 30.9856C46.5968 34.0844 44.6963 34.7865 42.249 34.9013Z" fill="#BB8B6C"/>
<path d="M47.2218 41.2171C54.5738 35.8567 42.793 38.6787 39.4748 37.5479C39.0663 40.8931 44.5348 42.7058 47.2218 41.2171Z" fill="#FDFDFD"/>
<path d="M56.6837 5.1017C58.6145 5.27723 60.2955 6.72198 61.369 8.48404C62.439 10.2461 62.996 12.3086 63.5395 14.3373C63.7656 15.1846 63.9952 16.0487 63.9884 16.9298C63.9817 17.8749 63.7049 18.7897 63.4483 19.691C63.0365 21.1425 62.6686 22.6177 62.5167 24.1265C62.3411 25.8582 62.4424 27.6304 62.0779 29.3283C61.4702 32.1672 59.5934 34.6483 59.5124 37.5614C58.7596 32.2077 58.547 26.7663 58.8744 21.3619C59.0027 19.2387 59.1478 16.869 57.8955 15.2352C56.9031 13.939 55.276 13.4664 53.7368 13.2605C48.6092 12.5753 43.0091 13.9458 38.5128 11.1643C37.8175 10.7356 37.1592 10.209 36.3997 9.93892C34.614 9.30431 32.5853 10.263 31.4005 11.8495C30.2156 13.436 29.7397 15.5222 29.581 17.561C29.419 19.5999 29.5371 21.6556 29.3684 23.6945C29.2131 25.5713 28.8148 27.4211 28.646 29.298C28.4029 31.9782 28.6358 34.709 29.3211 37.2947C27.1742 35.6002 26.3269 32.5892 25.8206 29.7537C25.0915 25.6894 24.791 21.551 24.4906 17.4192C24.3455 15.4108 24.2003 13.3618 24.6223 11.3972C25.456 7.51525 28.6629 4.37932 32.295 3.90336C33.0781 3.80209 33.9051 3.80209 34.6005 3.38689C35.1204 3.07634 35.5086 2.56663 35.9575 2.14468C37.0715 1.10162 38.5432 0.625659 39.988 0.345484C45.5206 -0.727955 51.4312 0.730302 56.0018 4.29831L56.6837 5.09832V5.1017Z" fill="#F37617"/>
<path d="M36.0319 20.4204C38.6649 19.7149 42.5941 21.504 39.1273 21.7132C37.4361 20.7748 35.5796 21.8888 33.912 22.3884C32.0352 21.7639 35.2116 20.4643 36.0319 20.4204Z" fill="#F37617"/>
<path d="M55.2753 22.7997C53.6719 22.1212 51.9504 20.8081 50.1647 21.5541C46.7418 20.9634 50.8465 19.6199 53.385 20.6123C54.1951 20.744 57.2095 22.3879 55.2753 22.7997Z" fill="#F37617"/>
<path d="M48.6264 49.909L51.0636 51.9242C51.2594 52.0862 51.4754 52.309 51.4011 52.5521C51.3606 52.6871 51.2357 52.7816 51.1142 52.8559C50.618 53.1664 50.0509 53.3555 49.5479 53.6559C49.045 53.9563 48.5825 54.4188 48.5049 54.9994C49.0517 55.0736 49.5648 54.7665 50.0678 54.5403C50.5707 54.3141 51.2087 54.1724 51.6341 54.5234C52.0324 54.8475 52.056 55.4348 52.1742 55.9344C52.3969 56.8627 53.0518 57.6391 53.8248 58.1961C54.5978 58.753 55.489 59.121 56.3734 59.4822C56.4949 59.5328 56.6333 59.5834 56.7582 59.5362C56.819 59.5126 56.8696 59.4687 56.9169 59.4248C57.6764 58.6822 57.835 57.4737 57.5413 56.4509C57.2477 55.4281 56.5692 54.5606 55.8367 53.7875C53.9025 51.7419 51.4855 50.1554 48.8391 49.1934L48.623 49.909H48.6264Z" fill="#F8BD84"/>
<path d="M85.728 125.914C86.0318 128.729 86.3323 131.545 86.6361 134.36C86.7103 135.052 86.7846 135.744 86.8589 136.436C87.0006 137.756 87.1424 139.079 87.2842 140.399C87.3416 140.946 87.2977 141.661 86.7711 141.827C86.0521 142.049 85.2656 140.919 84.6748 141.388C83.7837 136.929 83.3752 132.388 82.9702 127.855C82.9499 127.646 82.933 127.433 82.9904 127.231C83.1963 126.484 84.1854 126.356 84.9584 126.373L85.7247 125.911L85.728 125.914Z" fill="#F8BD84"/>
<path d="M76.5098 127.075C76.5638 128.196 76.3747 129.316 76.1958 130.424C76.0709 131.193 75.946 131.963 75.865 132.736C75.8043 133.33 75.7671 133.927 75.73 134.525C75.6929 135.102 75.6591 135.679 75.622 136.26C75.5578 137.293 75.4937 138.326 75.4329 139.359C75.3992 139.926 75.3452 140.537 74.9671 140.962C74.5114 141.475 73.7283 141.539 73.0599 141.404C72.7899 141.35 72.5063 141.256 72.3341 141.043C72.1451 140.81 72.1249 140.49 72.1147 140.193C72.0844 139.386 72.0506 138.576 72.1249 137.769C72.1856 137.094 72.324 136.425 72.4388 135.757C72.8034 133.661 72.9755 131.534 72.9553 129.408C72.9485 128.695 72.9215 127.976 73.0768 127.281C73.097 127.193 73.124 127.099 73.1916 127.035C73.2692 126.964 73.384 126.95 73.4886 126.943C74.0895 126.903 74.6903 126.923 75.2878 126.997L76.5131 127.075H76.5098Z" fill="#F8BD84"/>
<path d="M88.2432 112.115C89.6002 115.926 90.9774 119.858 90.7682 123.899C90.7141 124.905 90.5454 125.951 89.9546 126.768C89.688 125.496 89.4247 124.223 89.158 122.95C89.2086 123.251 89.0263 123.578 88.7428 123.69C88.3276 123.345 88.2905 122.717 88.4086 122.191C88.5267 121.664 88.7664 121.161 88.7934 120.625C88.8306 119.899 88.4761 119.22 88.1487 118.569C87.3689 117.013 86.6972 115.399 86.147 113.749C86.0491 113.451 85.9546 113.124 86.0659 112.834C86.1773 112.543 86.5959 112.361 86.812 112.587L88.2432 112.118V112.115Z" fill="#F8BD84"/>
<path d="M73.1748 111.312C71.9528 113.074 70.5958 114.742 69.1274 116.301C68.6751 116.781 68.1721 117.27 67.5274 117.402C66.8489 117.54 66.1569 117.247 65.5797 116.865C64.5501 116.183 63.7299 115.194 63.2539 114.057C62.7509 112.855 61.2117 104.888 60.2024 104.068C59.8682 103.795 59.4665 103.619 59.0986 103.393C58.7306 103.167 58.3964 102.893 58.0994 102.58C57.8968 102.364 57.7078 102.1 57.7281 101.807C57.7652 101.334 58.312 101.091 58.7779 101.01C60.1382 100.77 60.4589 101.283 61.6336 102.016C61.7619 102.097 61.9003 102.185 62.0522 102.168C62.221 102.147 62.3459 102.002 62.4471 101.867C62.6801 101.564 62.9298 101.246 63.291 101.118C63.6522 100.99 64.1417 101.169 64.1991 101.543C64.2362 101.786 64.0911 102.012 63.9695 102.225C63.0615 103.829 65.421 112.683 66.7038 114.006C66.8118 114.117 66.9299 114.225 67.0785 114.269C67.3688 114.357 68.0675 113.807 68.297 113.604C69.016 112.97 70.2988 110.289 70.903 109.543C71.5072 108.797 71.568 109.948 72.5132 110.107L73.1748 111.309V111.312Z" fill="#F8BD84"/>
<path d="M85.3033 120.126C86.1573 122.094 86.6974 124.2 86.8966 126.337C86.9303 126.681 86.9472 127.046 86.7953 127.36C86.6535 127.643 86.3868 127.842 86.11 128.001C84.9185 128.676 83.4805 128.723 82.11 128.75C81.3572 128.767 80.5437 128.764 79.9395 128.311C79.1496 127.717 79.0314 126.597 78.9808 125.608C78.6837 126.303 78.3833 126.998 78.0863 127.694C78.012 127.869 77.931 128.052 77.7791 128.166C77.6407 128.274 77.4584 128.308 77.2862 128.335C75.6288 128.605 73.9208 128.561 72.2802 128.207C71.8785 128.119 71.4566 128.001 71.1865 127.69C70.8456 127.299 70.8524 126.705 71.0076 126.208C71.1629 125.712 71.4532 125.27 71.6287 124.781C72.0034 123.751 71.8752 122.607 72.1418 121.547C72.1992 121.31 72.2836 121.071 72.4625 120.905C72.7089 120.679 73.0701 120.645 73.4043 120.625C76.8137 120.416 80.2298 120.321 83.6459 120.345L85.3033 120.126Z" fill="#034B6B"/>
<path d="M84.4692 140.096C84.7223 140.413 85.1814 140.447 85.5865 140.457C86.0591 140.467 86.562 140.467 86.957 140.207C87.038 140.153 87.1258 140.086 87.2203 140.096C87.2912 140.102 87.3519 140.153 87.4093 140.197C88.027 140.666 88.8203 140.815 89.5359 141.118C90.022 141.324 90.4744 141.605 90.8727 141.949C91.0617 142.111 91.2406 142.293 91.3723 142.506C91.4904 142.705 91.568 142.928 91.5883 143.157C91.6254 143.556 91.4668 144.011 91.0988 144.17C90.9773 144.221 90.8457 144.238 90.714 144.251C89.4617 144.379 88.1958 144.238 86.9604 144.001C86.7477 143.961 86.5317 143.917 86.3224 143.853C85.9477 143.735 85.6 143.549 85.2557 143.363C84.8236 143.13 84.3747 142.884 84.0979 142.479C83.7637 141.989 83.7535 141.301 84.0708 140.798L84.4624 140.092L84.4692 140.096Z" fill="#661A2E"/>
<path d="M72.0741 140.017C72.4859 140.399 73.0665 140.571 73.6269 140.557C74.1872 140.544 74.734 140.362 75.237 140.112C75.3079 140.075 75.3855 140.038 75.4665 140.044C75.6286 140.061 75.7096 140.247 75.7568 140.402C75.875 140.821 75.9628 141.249 76.0269 141.678C76.0708 141.995 76.0978 142.333 75.9628 142.62C75.8142 142.941 75.4936 143.14 75.1898 143.315C74.9602 143.447 74.7307 143.572 74.4876 143.676C73.9138 143.916 73.2927 144.011 72.6783 144.105C72.2192 144.173 71.7601 144.244 71.3011 144.311C70.4605 144.439 69.6166 144.564 68.766 144.578C68.5027 144.581 68.2327 144.574 67.993 144.466C67.7533 144.358 67.5508 144.129 67.5508 143.865C67.5508 143.697 67.6318 143.535 67.7229 143.39C68.3407 142.39 69.3804 141.742 70.3829 141.128C70.8049 140.871 71.2234 140.611 71.6623 140.382L72.0808 140.014L72.0741 140.017Z" fill="#661A2E"/>
<path d="M82.0318 107.197C81.5998 106.069 81.3331 104.877 81.2386 103.672C81.2217 103.443 81.215 103.196 81.3399 103.004C81.4749 102.798 81.7247 102.71 81.9576 102.626C84.2766 101.772 86.1636 99.8951 87.1999 97.6504C88.2362 95.4056 88.4522 92.8233 87.9965 90.3928C87.8649 89.6907 87.6691 88.9785 87.2573 88.3945C86.7577 87.689 85.9847 87.2333 85.2319 86.8079C83.804 86.0046 82.3323 85.2248 80.7255 84.9075C79.0141 84.5699 77.1946 84.7927 75.6115 85.5286C74.4536 86.0687 73.4376 86.8653 72.4823 87.716C71.3211 88.7523 70.2173 89.9135 69.6265 91.3515C69.0864 92.668 69.0156 94.1701 69.4341 95.5305C69.9506 97.2115 71.1456 98.5888 72.3574 99.8647C73.3599 100.921 74.4503 101.988 75.8376 102.43C76.5566 102.66 77.5187 102.923 77.5288 103.679C77.5322 104.013 77.3229 104.304 77.1845 104.604C76.8166 105.404 76.9583 106.417 77.5356 107.082C78.1128 107.747 79.0917 108.037 79.939 107.787L82.0386 107.19L82.0318 107.197Z" fill="#F8BD84"/>
<path d="M87.4803 86.5013C88.6314 87.8043 89.3267 89.4988 89.4212 91.2339C89.5158 92.9689 89.0128 94.731 88.0136 96.1521C87.217 94.8255 86.4001 93.4449 86.2279 91.9056C86.1942 91.6119 86.1874 91.3149 86.0963 91.0347C85.9916 90.7039 85.7824 90.4136 85.6034 90.1165C85.3131 89.6271 85.1072 89.0904 84.9891 88.5334C84.422 88.665 83.8515 88.7967 83.2844 88.9283C83.1156 88.9688 82.9266 89.006 82.7713 88.9182C82.4979 88.7663 82.5452 88.3477 82.7072 88.0811C82.8692 87.8144 83.119 87.5747 83.146 87.2642C81.789 87.8211 80.3409 88.1655 78.8793 88.2802C78.7139 88.2937 78.5316 88.3005 78.3999 88.1992C78.167 88.027 78.2311 87.649 78.3999 87.4127C78.5687 87.1764 78.8253 86.9975 78.9434 86.7308C77.6742 87.0718 76.4286 87.5038 75.2201 88.0203C74.9703 87.649 75.0142 87.1055 75.318 86.7781C74.6429 87.1021 73.9644 87.4228 73.2893 87.7469C72.918 87.9224 72.5433 88.1047 72.2293 88.368C71.6386 88.8676 71.3314 89.617 71.0411 90.336C70.3761 91.9934 69.7111 93.6542 69.0462 95.3116C68.4993 94.1335 67.9423 92.9082 67.9356 91.6119C67.9322 90.7984 68.1415 90.0018 68.3542 89.2186C68.5229 88.5874 68.6985 87.9427 69.0664 87.4026C69.3263 87.0211 69.6706 86.7072 70.0251 86.4135C71.5137 85.1679 73.215 84.1755 75.0345 83.4903C74.7543 83.2708 74.437 83.0953 74.1028 82.9772C74.9062 82.5485 75.8547 82.5113 76.7661 82.4945C78.4371 82.4607 80.108 82.4539 81.7789 82.4506C82.373 82.4506 82.9739 82.4472 83.5545 82.562C84.5773 82.7679 85.4988 83.3248 86.2954 84C86.8052 84.4287 87.2946 84.9553 87.3993 85.6135L87.4701 86.5013H87.4803Z" fill="#F37617"/>
<path d="M80.5947 92.6241C81.0133 94.1904 85.6041 92.1718 81.8066 92.03C80.5002 92.0537 80.7838 92.398 80.5947 92.6241Z" fill="#2C2E2B"/>
<path d="M81.9274 92.7934C81.8059 92.7934 81.708 92.6956 81.708 92.574C81.708 92.4525 81.8059 92.3546 81.9274 92.3546C82.0489 92.3546 82.1468 92.4525 82.1468 92.574C82.1468 92.6956 82.0489 92.7934 81.9274 92.7934Z" fill="white"/>
<path d="M74.6696 92.03C70.8721 92.1718 75.4629 94.1904 75.8815 92.6241C75.6924 92.398 75.976 92.0537 74.6696 92.03Z" fill="#2C2E2B"/>
<path d="M74.3291 92.574C74.3291 92.4525 74.427 92.3546 74.5485 92.3546C74.67 92.3546 74.7679 92.4525 74.7679 92.574C74.7679 92.6956 74.67 92.7934 74.5485 92.7934C74.427 92.7934 74.3291 92.6956 74.3291 92.574Z" fill="white"/>
<path d="M79.9356 96.6448C77.8394 96.79 77.0562 96.0439 77.7314 94.4979C77.5525 96.1958 78.5955 96.5807 79.9356 96.6448Z" fill="#BB8B6C"/>
<path d="M77.2124 100.108C73.1853 97.171 79.6394 98.7171 81.4555 98.0959C81.6783 99.9289 78.6841 100.921 77.2124 100.108Z" fill="#FDFDFD"/>
<path d="M81.4717 104.503C80.8675 105.134 80.9586 106.164 80.162 106.522C79.3653 106.879 78.3459 106.883 77.6944 106.299C76.9518 105.63 77.5155 104.648 76.5197 104.746C76.226 104.773 73.9914 104.925 73.7585 105.111C72.1146 106.4 70.9837 108.584 70.0521 110.454C69.7888 110.984 70.7677 111.065 71.2065 111.467C71.6994 111.916 72.6175 111.514 73.0867 111.99C73.4142 112.321 73.3129 112.868 73.1948 113.317C72.658 115.383 72.3036 117.496 72.1314 119.622C72.0808 120.26 72.1011 121.023 72.631 121.384C72.8268 121.519 73.0665 121.573 73.2994 121.621C77.0969 122.414 81.0565 122.427 84.8608 121.658C85.2625 121.577 85.698 121.465 85.9444 121.138C86.2583 120.723 86.1402 120.135 86.0119 119.629C85.6406 118.191 85.2726 116.756 84.9013 115.318C84.7865 114.869 84.6718 114.38 84.8541 113.951C85.3401 112.814 87.1427 113.29 88.1554 112.581C88.8102 112.122 89.0128 111.214 88.8575 110.431C88.7022 109.647 88.2533 108.959 87.8145 108.294C86.7984 106.751 85.5663 105.063 83.7367 104.793L81.4683 104.5L81.4717 104.503Z" fill="#AC2C2D"/>
</svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MoreInPremiumSVG2',
  components: {
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>