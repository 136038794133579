<template>
    <div class="footer">
        <div class="content">
            <div class="store-buttons">
                <!-- <button class="storebtn"><RuStoreLogoView></RuStoreLogoView></button>
                <button class="storebtn"><AppGalleryLogoView></AppGalleryLogoView></button> -->
                <router-link to="/#subscribe" class="storebtn">Подписаться</router-link>
            </div>
            <div class="info">
                <div>ИП Шаламова Александра Сергеевна</div>
                <div>ИНН 662007045961 / ОГРН 320774600352647</div>
                <div>Для связи: info@oros-it.ru</div>
                <div></div>
            </div>
            <div class="links">
                <div><router-link to="/user-agreement">Пользовательское соглашение</router-link></div>
                <div><router-link to="/privacy-policy">Политика конфиденциальности</router-link></div>
                Copyright © 2024 Орос IT

            </div>
        </div>
    </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
// import RuStoreLogoView from '@/components/RuStoreLogoView.vue';
// import AppGalleryLogoView from '@/components/AppGalleryLogoView.vue';

export default defineComponent({
  name: 'FooterView',
  methods: {
  },
  components: {
    // RuStoreLogoView, 
    // AppGalleryLogoView,
  }
});
</script>

<style scoped>
.footer {
    clear: both;
    position: relative;
    background: linear-gradient(var(--light-green-color), var(--green-color));
    padding: 40px;
    margin-top: 50px;
}

.content {
    margin: 0 auto;
    display: flex;
    flex-grow: 4;
    color: black;
    font-size: 0.8em;
    line-height: 2.5em;
}


.store-buttons {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.storebtn {
    width: 237px;
    background: white;
    padding: 10px 20px;
    border: none;
    margin: 10px 0;
    border-radius: 5px;
    font-family: "Odds", Arial, sans-serif;
    font-size: 30px;
    text-decoration: none;
    color: var(--green-color);
    text-align: center;

    box-sizing: border-box;
}

.info, .links {
    flex-grow: 1;
}

@media (min-width: 1920px) {
  .content {
      width: 100%;
  }
}

@media (min-width: 1366px) and (max-width: 1920px) {
  .content {
      width: 100%;
  }
  .crow {
    left: 350px;
  }
}


@media (min-width: 960px) and (max-width: 1366px) {
  .content {
      width: 100%;
  }

  .crow {
    left: 280px;
  }

  .storebtn {
    width: 160px;
  }

  .storebtn > svg {
    width: 100%;
  }
}


@media (min-width: 1024px) and (max-width: 1120px) {
  .crow {
    left: 200px;
  }
  
  .storebtn {
    width: 160px;
  }

  .storebtn > svg {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .content {
    width: 100%;
    flex-direction: column;
    text-align: center;
    font-size: 1em;
  }

  .crow {
    width: 30%;
    top: -110%;
    left: 40px;
  }

  .store-buttons {
    align-self: center;
    width: 100%;
  }

  .storebtn {
    width: 100%;
  }
}

</style>