<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="55" viewBox="0 0 51 55" fill="none">
<g opacity="0.8" filter="url(#filter0_f_244_3398)">
<ellipse cx="25.6625" cy="50.6983" rx="18.102" ry="0.987379" fill="#315613"/>
</g>
<path d="M25.5 51C39.5833 51 51 39.5833 51 25.5C51 11.4167 39.5833 0 25.5 0C11.4167 0 0 11.4167 0 25.5C0 39.5833 11.4167 51 25.5 51Z" fill="url(#paint0_linear_244_3398)"/>
<path d="M17 34L34 17" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34 34L17 17" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<filter id="filter0_f_244_3398" x="4.56055" y="46.7109" width="42.2041" height="7.97461" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_244_3398"/>
</filter>
<linearGradient id="paint0_linear_244_3398" x1="51" y1="51" x2="7.27723" y2="22.3963" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFF118"/>
<stop offset="1" stop-color="#F1CD11"/>
</linearGradient>
</defs>
</svg>
</template>


<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ErrorSVG',
  components: {
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
