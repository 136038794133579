<template>
    <div id="advantages" ref="advantages" :class="'root'" v-observe-visibility="(isVisible: Boolean) => {  if (isVisible) setActiveMenuItem('advantages') }">
      <h1>Уникальные преимущества приложения «Белая ворона»</h1>
      <CrowWithHandUpSVG class="crow" />
      <div>
        <div class="green-list">
          <div class="green-list-item">Большой набор сценариев и шаблонов для самых разных ситуаций</div>
          <div class="green-list-item">Уникальные методики и техники для снятия социальной тревожности</div>
          <div class="green-list-item">Интерактивные упражнения и тренировки для постепенного улучшения навыков общения</div>
          <div class="green-list-item">Тщательно подобранная библиотека алгоритмов для тренировки</div>
        </div>
        <p class="big-gray-head" :class="'grey-header'">«Белая ворона» не имеет аналогов по своему функционалу и эффективности!</p>
      </div>
    </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex';
import { sendMetrik } from '../../utils/metrika';
import CrowWithHandUpSVG from '@/assets/CrowWithHandUpSVG.vue';

export default defineComponent({
  name: 'AdvantagesView',
  methods: {
    ...mapMutations(['setActiveMenuItem']),
    sendMetrik: sendMetrik,
  },
  components: {
    CrowWithHandUpSVG,
  }
});
</script>

<style scoped>
.root {
  position: relative;
  margin-bottom: 100px;
}

.grey-header { 
  margin-top: 60px;
}

.crow {
  width: 30%;
  height: auto;
  float: right;
  transform: scale(-1, 1);
}

.crow::after {
  clear: both;
}

@media (min-width: 1920px) {
  .crow {
    width: 25%;
  }
}

@media (min-width: 1366px) and (max-width: 1920px) {
  .crow {
    width: 25%;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {

}

@media (max-width: 1024px) {
  .crow {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40%;
    float: none;
  }

  .grey-header{
    width: 58%;
    font-size: 2em !important;
    line-height: 2em !important;
  }
}
</style>