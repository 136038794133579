import { createSSRApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vue from 'vue'
import { ObserveVisibility } from 'vue-observe-visibility'
import { createApp } from './app';

const app = createApp().app.mount('#app')

export default app;