import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00378b4d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "head" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoView = _resolveComponent("LogoView")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, { to: "/" }, {
      default: _withCtx(() => [
        _createVNode(_component_LogoView, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sendMetrik('logo-click')))
        })
      ]),
      _: 1
    })
  ]))
}