<template>
    <div class="page-view" id="ineedthis" ref="ineedthis" v-observe-visibility="(isVisible: Boolean) => {  if (isVisible) setActiveMenuItem('ineedthis') }">
      <h1>Нужно ли мне это?</h1>
      <div>По статистике около 75% процентов людей испытывают трудности общения в той или иной ситуации. В разной степени, проблемы общения — непременный спутник жизни для большинства людей.</div>
      <div class="list-with-images">
        <div class="list-with-images-item">
          <INeedThis1SVG />
          <div>Вам бывает сложно или страшно заговорить с незнакомым человеком?</div>
        </div>
        <div class="list-with-images-item">
          <INeedThis2SVG />
          <div>А убедительно представить и обосновать свою позицию перед начальством или в ходе собеседования при устройстве на работу?</div>
        </div>
        <div class="list-with-images-item">
          <INeedThis3SVG />
          <div>Или решиться на откровенный разговор с кем-то, кто вам нравится?</div>
        </div>
      </div>
      <div class="lighting-text">Если подобные ситуации вам знакомы — тогда наше приложение непременно будет вам полезно</div>
    </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex';
import { sendMetrik } from '../../utils/metrika';
import INeedThis1SVG from '@/assets/INeedThis1SVG.vue';
import INeedThis2SVG from '@/assets/INeedThis2SVG.vue';
import INeedThis3SVG from '@/assets/INeedThis3SVG.vue';
 
export default defineComponent({
  name: 'INeedThisView',
  methods: {
    ...mapMutations(['setActiveMenuItem']),
    sendMetrik: sendMetrik,
  },
  components: {
    INeedThis1SVG,
    INeedThis2SVG,
    INeedThis3SVG,
  }
});
</script>

<style scoped>

</style>