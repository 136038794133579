<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="17" viewBox="0 0 22 17" fill="none">
        <rect width="22" height="3" rx="1" fill="#6DC527"/>
        <rect y="7" width="22" height="3" rx="1" fill="#6DC527"/>
        <rect y="14" width="22" height="3" rx="1" fill="#6DC527"/>
    </svg>
</template>


<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MobileMenuSVG',
  components: {
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>