<template>
    <div class="container">
        <h1>Подписка на новости проекта "Белая ворона"</h1>
        <div v-if="isActivated">
            Подписка на новости проекта "Белая ворона" активирована. Вы получите первые новости в ближайшее время! 
        </div>
        <div v-if="!isActivated && !isError">Активация подписки... </div>
        <div v-if="isError" class="error-text">Во время активации произошла ошибка. Возможно вы уже активировали подписку ранее. Если нет, пожалуйста, попробуйте еще раз перейти по ссылке из письма. Если проблема сохранится, обратитесь в техподдержку: info@oros-it.ru</div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions } from 'vuex'
import { useMeta } from 'vue-meta';

export default defineComponent({
    name: 'SubscribtionActivationView',
    data: () => {
        return {
            isActivated: false,
            isError: false
        }
    },
    methods: {
        ...mapActions([
            'activateSubcribtion',
        ]),
    },
    async mounted() {
        const result = await this.activateSubcribtion(this.$route.params.code);

        if (result.status == 201) {
            this.isActivated = true;
        } else {
            this.isError = true;
        }
    },
    setup() {
        useMeta({ title: 'Подтверждение подписки'})
    }
})

</script>

<style scoped>
.container {
    margin-top: 60px;
    min-height: 600px;
}


@media (max-width: 1024px) {
    .container {
        margin: 100px 0 0 0;
    }
}
</style>