<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | Белая ворона` : `Белая ворона` }}</template>
  </metainfo>
  <div class="rootelement" :style="cssVars">
    <HeaderView/>
    <MenuView/>
    <AlertsView />
    <router-view />
  </div>
  <FooterView />
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import HeaderView from '@/components/HeaderView.vue'; 
import MenuView from '@/components/MenuView.vue'; 
import FooterView from '@/components/FooterView.vue';
import { CDN_URL } from '@/constants/api.config';
import AlertsView from '@/components/AlertsView.vue';

export default defineComponent({
  name: 'App',
  data: function() {
    return {
    }
  },
  computed: {
    cssVars() {
      return {
        '--odds-url':  `url(${CDN_URL}odds-narrow.woff)`,
        '--brushscriptMT_italic': `url(${CDN_URL}brushscriptmtrusbymeitalic.woff)`
      }
    },
  },
  components: {
    HeaderView,
    MenuView,
    FooterView,
    AlertsView,
  },
});
</script>

<style scoped>
.rootelement {
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
}

@media (min-width: 1920px) {
  .rootelement {
      width: 1920px;
      padding: 0 40px;
  }
}

@media (min-width: 1366px) and (max-width: 1920px) {
  .rootelement {
      min-width: 1366px;
      padding: 0 40px;
  }
}


@media (min-width: 1024px) and (max-width: 1366px) {
  .rootelement {
      width: 100%;
      padding: 0 40px;
  }
}

@media (max-width: 1024px) {
  .rootelement {
      width: 100%;
      padding: 0 20px;
  }
}
</style>

<style>
body {
  margin: 0;
}

@font-face {
  font-family: "Odds";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("Odds"),
      var(--odds-url) format("woff");
}

@font-face {
  font-family: "Brush Script MT";
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: local("Brush Script MT"),
      var(--brushscriptMT_italic) format("woff");
}

#app {
  font-family: "Brush Script MT", Arial, sans-serif;
  font-size: 20px;
  line-height: 30px;

  --green-color: #6DC527;
  --light-green-color: #9DEC1C;
  --bright-green-color: #9fff528f;
  --medium-green-color: #98CA3B;
  --yellow-bright-color: #FFF118;
  --yellow-dark-color: #F1CD11;
  --gray-1-color: #606060;
  --gray-transparent-color: #252525b3;
  --orange-color: #FD9C09;
}

#app h1 {
  font-family: "Odds", Arial, sans-serif;
  color: var(--green-color);
  font-size: 60px;
  line-height: 65px;
}

#app input,
#app textarea {
  padding: 20px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  font-size: 1em;
}

#app .btn-yellow {
  font-family: "Odds", Arial, sans-serif;
  background: linear-gradient(45deg, var(--yellow-bright-color) 10%, var(--yellow-dark-color) 90%);
  border: none;
  padding: 20px;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 30px;
  cursor: pointer;
}

#app .btn-green {
  font-family: "Odds", Arial, sans-serif;
  background: var(--medium-green-color);
  color: white;
  border: none;
  padding: 20px;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 30px;
  cursor: pointer;
  text-decoration: none;
}

#app input[type="checkbox"] {
  width: 2.5em;
  height: 2.5em;
  float: left;
  margin-right: 20px;
}

#app .error-text {
  color: red;
}

.page {
  margin-left: 300px;
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox label {
  font-size: 0.8em;
  line-height: 1.2em;
  color: var(--gray-1-color);
}

#app .big-gray-head {
  font-family: "Odds", Arial, sans-serif;
  color: var(--gray-1-color);
  font-size: 60px;
  line-height: 65px;
}

#app ul {
  font-family: "Odds", Arial, sans-serif;
  font-size: 25px;
  line-height: 30px;
}

#app ul li {
  padding: 15px 0;
}

#app ul li::marker {
  color: var(--green-color);
}

.page-view {
  margin-bottom: 60px;
}

.lighting-text {
  font-family: "Odds", Arial, sans-serif;
  color: var(--green-color);
  font-size: 30px;
  line-height: 40px;
}

.list-with-images {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 70px 0;
}

.list-with-images-item {
  display: flex;
  flex-direction: column;
  font-family: "Odds", Arial, sans-serif;
  color: var(--gray-1-color);
  text-align: center;
  align-items: center;
  max-width: 375px;
}

.list-with-images-item > svg {
  margin-bottom: 30px;
}

.green-list {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
}

.green-list-item {
  display: inline;
  font-family: "Odds", Arial, sans-serif;
  color: var(--gray-1-color);
  background: linear-gradient(90deg, var(--bright-green-color), transparent);
  margin: 10px 0;
  padding: 10px 20px;
  font-size: 25px;
  line-height: 35px;
}

@media (min-width: 1366px) and (max-width: 1920px) {
  #app h1 {
    font-size: 40px;
    line-height: 45px;
  }
  
  #app .big-gray-head {
    font-size: 40px;
    line-height: 45px;
  }

  #app {
    font-size: 18px;
    line-height: 26px;
  }

  .lighting-text {
    font-size: 25px;
    line-height: 30px;
  }
  
  .list-with-images-item {
    min-width: 200px;
    max-width: 375px;
    font-size: 18px;
    margin: 0 20px;
  }

  .list-with-images-item > svg {
    margin-bottom: 20px;
  }
}


@media (min-width: 1024px) and (max-width: 1366px) {
  #app h1 {
    font-size: 30px;
    line-height: 35px;
  }

  #app .big-gray-head {
    font-size: 30px;
    line-height: 35px;
  }

  #app {
    font-size: 14px;
    line-height: 20px;
  }
  
  #app ul {
    font-family: "Odds", Arial, sans-serif;
    font-size: 20px;
    line-height: 26px;
  }

  #app ul li {
    padding: 10px 0;
  }

  .lighting-text {
    font-size: 24px;
    line-height: 30px;
  }

  .list-with-images {
    margin: 50px 0;
  }

  .list-with-images-item {
    min-width: 200px;
    max-width: 375px;
    font-size: 18px;
    margin: 0 20px;
  }

  .list-with-images-item > svg {
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) { 
  .page {
    margin-left: 0;
  }

  .list-with-images {
    flex-direction: column;
  }

  .list-with-images-item {
    margin-bottom: 50px;
    width: 100%;
    max-width: 100%;
  }

  #app {
    font-size: 14px;
    line-height: 16px;
  }

  #app h1 {
    font-size: 30px;
    line-height: 35px;
  }

  .list-with-images {
    margin: 40px 0;
  }

  .green-list {
    margin: 20px 0;
  }

  .green-list-item,
  .list-with-images-item,
  #app ul {
    font-size: 20px;
    line-height: 25px;
  }

  
  .list-with-images-item {
    margin-bottom: 20px;
  }

  #app ul li {
    padding: 5px 0;
  }

  .checkbox label {
    font-size: 1em;
  }
}
</style>

