import { createStore } from 'vuex'
import { PageType } from '@/types/routing';
import { DOMAIN } from '@/constants/site';
import { API_URL, OROS_API_URL } from '@/constants/api.config';

export type AlertType = {
  text: string,
  class: 'alert-success' | 'alert-danger',
  id: string,
}

export default createStore({
  state: {
    activePage: 'main' as PageType,
  alerts: [/*{id: '2', text: 'Что-то пошло не так! Попробуйте снова.', class: 'alert-danger'}, {id: '1', text: 'Данные отправлены успешно!', class: 'alert-success'} */] as AlertType[],
  },
  getters: {
  },
  mutations: {
    setActiveMenuItem(state, payload: PageType) {
      state.activePage = payload;
    },
    showAlert(state, payload : AlertType) {
      const id = String((new Date()).getMilliseconds() + payload.class);

      state.alerts = [{
        id: id,
        text: payload.text,
        class: payload.class,
      }].concat(state.alerts);
      
      if (typeof window !== 'undefined') {
        window.setTimeout(() => {
          let alerts = [ ...state.alerts ];
          alerts = alerts.filter(alert => (alert.id !== id));
           
          state.alerts = alerts;
        }, 10000);
      }
    },
    closeAlert(state, id: string) {
      let alerts = [ ...state.alerts ];
      alerts = alerts.filter(alert => (alert.id !== id));
        
      state.alerts = alerts;
    },
  },
  actions: {
    async subscribe({ dispatch, commit }, payload: {email: string}) {
      const headers: { 'authorization'? : string, lang: string } = { lang: 'ru' };

      const params = {
        email: payload.email,
        category_id: 19,
        lang: 'ru'
      }

      try {
        const response = await fetch(`${OROS_API_URL}/v2/subscription`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(params)
        });
        
        return dispatch('responseParser', { response });
      } catch {
        commit('showAlert', {
          text: 'Что-то пошло не так.',
          class: 'alert-danger'
        });
      }
    },

    async activateSubcribtion({ dispatch, commit }, code: string) {
      const headers: { 'authorization'? : string, lang: string } = { lang: 'ru' };
      try {
        const response = await fetch(`${OROS_API_URL}/v2/subscription_activate?act=${code}`, {
          method: 'GET',
          headers: headers,
        });
        
        return dispatch('responseParser', { response });
      } catch {
        commit('showAlert', {
          text: 'Что-то пошло не так.',
          class: 'alert-danger'
        });
      }
      
    },

    async sendContactUsMessage({ dispatch, commit }, payload: {name: string, email: string, message: string}) {
      const headers: { 'authorization'? : string, lang: string } = { lang: 'ru' };

      const params = {
        info: 'Проект "Белая ворона": обратная связь',
        name: payload.name,
        email: payload.email,
        message: payload.message,
      }

      try {
        const response = await fetch(`${OROS_API_URL}/v2/contact`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(params)
        });
        
        return dispatch('responseParser', { response });
      } catch {
        commit('showAlert', {
          text: 'Что-то пошло не так.',
          class: 'alert-danger'
        });
      }
    },

    async responseParser({ dispatch, commit },  payload: { response: any, notThrowError: boolean }) {
      const response = payload.response;
      if (!response.ok) {
      
        try {
          const result = await response.json();

          if (response.status === 401) {
            commit('clearAuth')
  
            if (typeof window !== 'undefined') {
              window.location.href = DOMAIN;
            } else {
              commit('showAlert', {
                text: 'У вас нет доступа к этим данным. Пожалуйста, авторизуйтесь.',
                class: 'alert-warning'
              })
            }
            return response;
          } else if (response.status === 402) {
            commit('showAlert', {
              text: "Превышен лимит. Пожалуйста, <a href='/tariffs' target='_blank' onclick='event.preventDefault(); app._router.push('/tariffs');'>расширьте ваш тариф</a> для добавления новых данных. Ваши текущие лимиты вы можете посмотреть в своем <a href='/profile/info' target='_blank' onclick='event.preventDefault(); app._router.push('/profile/info');'>профиле</a>.",
              class: 'alert-danger'
            })
            return response;
          } else if (!payload.notThrowError) {
            commit('showAlert', {
              text: 'Что-то пошло не так.',
              class: 'alert-danger'
            });

            return response;
          }

          return response;
        } catch {
          commit('showAlert', {
            text: 'Что-то пошло не так.',
            class: 'alert-danger'
          });
        }
      } else {
        let result;
        try {
          result = await response.json();
        } catch {
          result = response;
        }
        return response;
      }
    },

    // методы обертки для основных HTTP-методов
    async get({ dispatch, commit }, payload: { url : string, params: any, notThrowError: boolean }) {
      const headers: { 'authorization'? : string } = {};

      // if (this.state.authKey) {
      //   headers['authorization'] = this.state.authKey;
      // }

      let getParams = '';
      
      if (payload?.params && payload?.params?.length !== 0) {
        getParams = '?' + Object.entries(payload.params).map(item => (`${item[0]}=${item[1]}`)).join('&');
      }

      try {
        const response = await fetch(`${API_URL}${payload.url}${getParams}`, {
          method: 'GET',
          headers: headers,
        });
        
        return dispatch('responseParser', { response, notThrowError: payload.notThrowError });
      } catch {
        commit('showAlert', {
          text: 'Что-то пошло не так.',
          class: 'alert-danger'
        });
      }
    },

    async post({ dispatch, commit }, payload : {url : string, params: any, notThrowError: boolean}) {
      const headers: {authorization? : string} = {};

      // if (this.state.authKey) {
      //   headers.authorization = this.state.authKey;
      // }

      try {
        const response = await fetch(`${API_URL}${payload.url}`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(payload.params)
        });

        return dispatch('responseParser', { response, notThrowError: payload.notThrowError });
      } catch {
        commit('showAlert', {
          text: 'Что-то пошло не так.',
          class: 'alert-danger'
        });
      }
    },

    async put({ dispatch, commit }, payload : {url : string, params: any, notThrowError: boolean}) {
      const headers: {authorization? : string} = {};

      // if (this.state.authKey) {
      //   headers.authorization = this.state.authKey;
      // }

      try {
        const response = await fetch(`${API_URL}${payload.url}`, {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(payload.params)
        });

        return dispatch('responseParser', { response, notThrowError: payload.notThrowError });
      } catch {
        commit('showAlert', {
          text: 'Что-то пошло не так.',
          class: 'alert-danger'
        });
      }
    },

    async delete({ dispatch, commit }, payload : {url : string, params: any, notThrowError: boolean}) {
      const headers: {authorization? : string} = {};

      // if (this.state.authKey) {
      //   headers.authorization = this.state.authKey;
      // }

      try {
        const response = await fetch(`${API_URL}${payload.url}`, {
          method: 'DELETE',
          headers: headers,
          body: JSON.stringify(payload.params)
        });
        
        return dispatch('responseParser', { response, notThrowError: payload.notThrowError });
      } catch {
        commit('showAlert', {
          text: 'Что-то пошло не так.',
          class: 'alert-danger'
        });
      }
    }
  },
  modules: {
  }
})
