import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_HeaderView = _resolveComponent("HeaderView")!
  const _component_MenuView = _resolveComponent("MenuView")!
  const _component_AlertsView = _resolveComponent("AlertsView")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterView = _resolveComponent("FooterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_metainfo, null, {
      title: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content} | Белая ворона` : `Белая ворона`), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", {
      class: "rootelement",
      style: _normalizeStyle(_ctx.cssVars)
    }, [
      _createVNode(_component_HeaderView),
      _createVNode(_component_MenuView),
      _createVNode(_component_AlertsView),
      _createVNode(_component_router_view)
    ], 4),
    _createVNode(_component_FooterView)
  ], 64))
}