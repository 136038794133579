<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="409" height="328" viewBox="0 0 409 328" fill="none">
<path d="M94.7005 32.3568C94.6478 35.6387 92.1699 37.9716 89.468 39.3687C89.1385 42.5187 88.9276 43.784 85.2636 44.2717C83.2734 44.812 83.7742 42.1233 83.0098 41.0293C76.0507 37.1676 74.9831 33.6617 75.273 25.7668C73.9023 17.4106 84.6441 12.9425 90.3906 18.4518C92.5258 20.2443 94.8323 22.4849 94.9378 25.4373C94.8982 26.0172 95.0432 26.9925 95.8076 26.8871C98.9313 25.0814 98.6282 33.2926 95.4913 31.856L94.7005 32.3568Z" fill="#F8BD84"/>
<path d="M75.1671 20.2977C72.3466 18.7952 73.2296 14.9202 74.9562 12.8773C79.6747 10.729 86.0539 9.04191 90.891 11.6516C95.6227 13.6286 97.4679 19.1115 96.6902 23.8959C95.6886 25.5961 94.3178 27.2041 94.2519 29.2997C94.3969 30.6046 92.9866 31.4086 92.2881 30.0774C91.8136 28.1794 92.9075 26.1629 92.6571 24.2254C92.6835 18.5975 84.7491 17.5694 80.8741 19.7573C80.8741 19.7573 76.3401 20.9172 75.1671 20.2977Z" fill="#661A2E"/>
<path d="M89.2308 40.292C90.4961 41.5309 90.9574 42.5721 92.2227 43.8111C85.738 46.9743 84.275 58.8101 80.4924 63.8185C79.5566 58.3619 81.415 49.2808 80.5187 43.8243C80.4396 42.4799 82.9307 43.0862 83.247 41.6891C85 45.3004 88.4268 43.2971 89.2308 40.2788V40.292Z" fill="#DDDDDD"/>
<path d="M86.7267 35.6521C83.9984 38.499 81.0065 37.9586 78.9636 34.6768C81.3887 35.7312 84.8419 36.232 87.2012 34.8481L86.7267 35.6521Z" fill="#FAFFFE"/>
<path d="M84.1689 31.5663C83.7208 33.0688 81.2957 32.4625 81.2298 31.0918C81.902 31.7113 83.4704 31.6849 84.1689 31.5663Z" fill="#C68F64"/>
<path d="M9.10814 32.3568C9.16086 35.6387 11.6387 37.9716 14.3407 39.3687C14.6702 42.5187 14.881 43.784 18.5451 44.2717C20.5353 44.812 20.0345 42.1233 20.7989 41.0293C27.758 37.1676 28.8256 33.6617 28.5356 25.7668C29.9064 17.4106 19.1646 12.9425 13.418 18.4518C11.2829 20.2443 8.97634 22.4849 8.8709 25.4373C8.91044 26.0172 8.76546 26.9925 8.00101 26.8871C4.87732 25.0814 5.18047 33.2926 8.31734 31.856L9.10814 32.3568Z" fill="#C68F64"/>
<path d="M17.0836 34.2811C19.5087 36.3767 22.9356 36.1263 24.8467 33.5298C22.4084 34.347 18.9683 34.7424 16.6091 33.6616L17.0836 34.2811Z" fill="#FAFFFE"/>
<path d="M19.2571 29.9847C19.7052 31.4873 22.1304 30.881 22.1963 29.5103C21.5241 30.1297 19.9557 30.1034 19.2571 29.9847Z" fill="#9B6E50"/>
<path d="M88.4515 25.8857C89.1368 27.3092 86.369 27.2301 86.0131 26.2416C85.2619 24.0668 89.2159 24.225 89.585 25.9121C89.2291 25.6748 88.8073 25.6221 88.4647 25.8857H88.4515Z" fill="#2C2E2B"/>
<path d="M78.0676 25.6357C77.3822 27.0591 80.15 26.9801 80.5059 25.9916C81.2572 23.8168 77.3031 23.975 76.9341 25.6621C77.2899 25.4248 77.7117 25.3721 78.0544 25.6357H78.0676Z" fill="#2C2E2B"/>
<path d="M86.4757 25.8199C86.4757 26.6898 87.4906 26.6898 87.4774 25.8199C87.4774 24.95 86.4625 24.95 86.4757 25.8199Z" fill="white"/>
<path d="M78.2658 25.8199C78.2658 26.6898 79.2806 26.6898 79.2675 25.8199C79.2675 24.95 78.2526 24.95 78.2658 25.8199Z" fill="white"/>
<path d="M90.4033 24.1852C89.3621 23.4867 84.0505 22.5113 85.5003 24.607C86.8051 23.6053 88.8876 24.3829 90.4033 24.1852Z" fill="#2C2E2B"/>
<path d="M78.6338 80.9918C76.1032 83.1006 73.9417 85.6312 72.2414 88.4386C70.8971 85.9475 67.3516 85.6971 65.3219 83.7201C71.8065 80.9522 73.5726 66.375 77.9352 62.8032C78.7656 68.7343 77.8825 75.008 78.6338 80.9918Z" fill="#2C2E2B"/>
<path d="M82.1523 23.7643C80.4653 23.1053 78.4619 22.4727 76.8144 23.593C78.5015 23.5139 80.228 23.4216 81.7174 24.3047L82.1523 23.7775V23.7643Z" fill="#2C2E2B"/>
<path d="M25.2014 25.5036C25.7154 26.558 23.6461 26.5053 23.3825 25.7672C22.829 24.1461 25.7681 24.2647 26.0449 25.5168C25.7813 25.3455 25.465 25.3059 25.2014 25.5036Z" fill="#2C2E2B"/>
<path d="M15.2379 25.53C14.7239 26.5844 16.7931 26.5317 17.0567 25.7936C17.6103 24.1724 14.6711 24.2911 14.3944 25.5432C14.658 25.3718 14.9743 25.3323 15.2379 25.53Z" fill="#2C2E2B"/>
<path d="M23.7261 25.4643C23.7261 26.1102 24.4906 26.1102 24.4774 25.4643C24.4774 24.8185 23.7129 24.8185 23.7261 25.4643Z" fill="white"/>
<path d="M15.3824 25.6621C15.3824 26.3079 16.1468 26.3079 16.1336 25.6621C16.1336 25.0163 15.3692 25.0163 15.3824 25.6621Z" fill="white"/>
<path d="M27.8906 23.5666C26.8494 22.868 21.5378 21.8927 22.9876 23.9883C24.2925 22.9866 26.3749 23.7643 27.8906 23.5666Z" fill="#2C2E2B"/>
<path d="M17.1621 22.6835C15.3696 22.4727 13.2608 22.354 11.956 23.8434C13.5639 23.3425 15.2246 22.8153 16.8721 23.303L17.1621 22.6835Z" fill="#2C2E2B"/>
<path d="M90.8519 43.5337C108.25 48.3576 90.5751 70.3816 98.2459 90.2836C91.0232 91.2589 83.3656 88.8601 75.9583 89.8882C74.5085 89.9409 74.6667 79.6999 75.6157 78.5005C77.619 71.3569 77.395 63.7124 77.2895 56.3316C76.96 52.2194 76.5778 46.6574 80.8482 44.4827C81.3754 50.8487 79.5697 57.3201 80.5977 63.607C83.6951 58.8094 85.3689 53.3002 87.4514 48.0281C87.9786 46.0116 90.0215 45.2339 90.8651 43.5337H90.8519Z" fill="#2C2E2B"/>
<path d="M83.167 126.569C82.5212 128.295 83.6678 131.814 81.045 131.946C76.1288 132.117 70.949 133.725 66.1251 132.961C64.0822 128.084 72.6229 128.928 75.4434 127.926C76.2606 127.821 76.8801 127.439 77.17 126.7C79.0021 126.279 81.335 125.936 83.167 126.569Z" fill="#54182B"/>
<path d="M67.6284 68.326C64.8606 66.8367 62.0137 63.6471 58.6264 65.3341C56.8076 66.2436 53.1435 68.0361 55.9904 70.0922C55.9245 70.5403 55.7795 71.0543 56.254 71.397C59.325 72.7941 62.6463 72.425 65.9677 72.0824C67.7602 72.2273 67.7075 69.565 67.6284 68.326Z" fill="#F8BD84"/>
<path d="M59.8598 72.9643C61.0704 73.2234 63.445 72.5928 64.223 71.9154C64.8133 70.9141 62.1025 72.2973 61.4552 71.9157C60.3385 72.2042 58.5366 71.1618 58.0006 71.3003C57.3305 71.4734 58.3467 72.6404 59.8598 72.9643Z" fill="#C68F64"/>
<path d="M60.8656 63.8048C61.5115 64.5692 63.1458 65.8872 61.8146 66.7835C60.5098 67.1789 59.574 65.1096 58.3351 65.3864C51.5341 69.2877 57.307 68.8659 55.3827 70.4212C51.1782 72.24 51.1519 70.0126 50.7169 66.4803C53.2871 64.1738 57.5574 62.2627 60.8656 63.818V63.8048Z" fill="#C68F64"/>
<path d="M90.0731 50.5197C90.8376 45.2344 94.8971 42.4798 97.7703 48.3186C99.3388 58.0719 89.401 72.8863 79.6213 74.982C76.6294 74.982 73.5584 75.9309 70.672 74.837C68.9586 74.1121 66.5993 74.0594 65.2154 72.939C66.5598 71.2915 65.7163 69.0509 66.0721 67.0344C66.3357 66.5731 67.0343 66.8103 67.522 67.0344C70.382 68.0492 73.6639 67.957 76.669 67.8779C85.1438 65.9272 89.0055 58.5332 90.06 50.5197H90.0731Z" fill="#162B49"/>
<path d="M72.0432 93.1441C74.1915 87.7666 66.5075 86.3432 62.9884 87.3712C61.8022 89.2033 60.1942 92.1292 63.6211 91.9974C64.1219 90.6663 63.7397 87.938 66.0726 88.3597C67.5224 88.8869 69.5126 88.821 70.5275 90.0995C71.3446 91.4043 69.0645 94.0272 72.03 93.1441H72.0432Z" fill="#54182B"/>
<path d="M72.1758 87.0815C70.1988 88.0041 71.0687 91.2464 69.4343 91.2596C67.7341 91.1805 65.2562 90.5215 64.4391 88.9267C64.3995 87.5692 63.3583 85.4603 64.8213 84.5904C68.6567 83.5097 69.1707 85.2099 72.1758 87.0683V87.0815Z" fill="#F8BD84"/>
<path d="M94.6358 126.424C95.8484 134.279 94.7017 132.869 87.7294 133.897C84.8825 134.108 75.5115 136.928 76.0782 131.999C79.2941 130.127 83.7095 129.705 87.1495 127.807C88.3884 127.531 87.7294 126.516 88.4675 126.16C90.4709 126.002 92.7247 125.857 94.649 126.424H94.6358Z" fill="#661A2E"/>
<path d="M49.6768 89.8228C51.4825 86.9232 56.3064 89.6251 59.0874 90.06C65.2689 91.6153 71.6217 92.4193 77.8822 93.5923C80.7687 93.7768 82.7457 95.8988 81.4013 98.693C79.0948 104.479 79.1739 111.24 76.8805 116.829C74.0995 118.727 54.3689 112.651 46.619 111.148C44.4311 110.107 49.4 94.8049 49.6636 89.8228H49.6768Z" fill="#661A2E"/>
<path d="M95.4382 89.006C95.9786 101.382 95.346 113.811 95.4382 126.2C95.57 127.202 94.6738 127.624 93.7644 127.505C91.616 127.307 89.2963 127.413 87.2402 126.82C85.4345 116.118 87.2138 104.914 85.4082 94.1726C85.3554 93.8695 85.0787 93.4609 84.8546 93.6718C84.3274 100.525 83.7079 116.552 83.8002 122.958C83.8266 123.446 84.0902 127.123 83.6157 127.215C80.7029 126.965 78.6072 127.347 75.9053 127.518C76.8938 120.625 74.7059 93.7904 77.1574 88.7029C83.1148 87.1476 89.4545 89.5991 95.4382 89.0192V89.006Z" fill="#2C2E2B"/>
<path d="M69.3146 99.4442C69.3805 100.525 69.341 102.976 67.6803 102.344C65.8614 101.355 59.5613 101.408 60.4707 98.5084C61.3934 96.0173 67.2322 99.4178 69.3146 99.4442Z" fill="#DDDDDD"/>
<path d="M84.0907 47.5532C84.2357 51.2964 83.5239 54.6573 83.748 58.6245C83.8403 60.4302 81.9687 62.2622 80.5057 63.8307C79.1481 60.3775 80.5848 48.6867 84.1039 47.5664L84.0907 47.5532Z" fill="#034B6B"/>
<path d="M85.8955 44.4039C85.7505 46.0646 83.7471 49.8736 82.8377 46.5786C82.8114 44.6806 84.0371 42.5191 85.8955 44.4039Z" fill="#034B6B"/>
<path d="M27.6794 48.9777C26.915 43.6925 22.8555 40.9378 19.9822 46.7766C18.4138 56.5299 28.3516 71.3443 38.1312 73.44C41.1231 73.44 44.1941 74.3889 47.0805 73.295C48.794 72.5701 51.1532 72.5174 52.5371 71.3971C51.1927 69.7495 52.0363 67.5089 51.6804 65.4924C51.4168 65.0311 50.7183 65.2683 50.2306 65.4924C47.3705 66.5072 44.0887 66.415 41.0836 66.3359C32.6088 64.3852 28.747 56.9912 27.6926 48.9777H27.6794Z" fill="#162B49"/>
<path d="M13.1415 43.5336C-4.25622 48.3575 13.4183 70.3815 5.74749 90.2835C12.9702 91.2588 20.6278 88.86 28.0351 89.8881C29.3663 89.7036 28.6545 85.6177 29.0895 84.392C29.3004 80.2798 28.6545 70.6583 28.6677 62.7107C27.7319 57.4518 30.2493 45.0889 23.3825 43.2437C22.6971 49.8996 24.3315 57.0432 23.4088 63.6069C20.3115 58.8094 18.6376 53.3001 16.5552 48.028C16.028 46.0115 13.9851 45.2339 13.1415 43.5336Z" fill="#034B6B"/>
<path d="M20.8255 126.569C21.4713 128.295 20.3246 131.814 22.9475 131.946C27.8636 132.117 33.0434 133.725 37.8673 132.961C39.9103 128.084 31.3696 128.928 28.549 127.926C27.7318 127.821 27.1124 127.439 26.8224 126.7C24.9904 126.279 22.6575 125.936 20.8255 126.569Z" fill="#2C2E2B"/>
<path d="M9.35917 126.424C8.1466 134.279 9.29327 132.869 16.2655 133.897C19.1125 134.108 28.4835 136.928 27.9168 131.999C24.7008 130.127 20.2855 129.705 16.8455 127.807C15.6065 127.531 16.2655 126.516 15.5275 126.16C13.5241 126.002 11.2703 125.857 9.34599 126.424H9.35917Z" fill="#2C2E2B"/>
<path d="M8.55603 89.0059C8.01565 101.382 8.64829 113.811 8.55603 126.2C8.42423 127.202 9.32048 127.624 10.2299 127.505C12.3783 127.307 14.698 127.413 16.7541 126.82C18.5597 116.117 16.7804 104.914 18.5861 94.1725C18.6388 93.8694 18.9156 93.4608 19.1397 93.6717C19.6669 100.525 20.2863 116.552 20.1941 122.958C20.1677 123.446 19.9041 127.123 20.3786 127.215C23.2914 126.965 25.387 127.347 28.089 127.518C27.1005 120.625 28.9296 94.7524 28.2378 88.5245C22.2804 86.9692 14.5398 89.599 8.55603 89.0191V89.0059Z" fill="#2C2E2B"/>
<path d="M14.7628 40.292C13.4975 41.5309 13.0362 42.5721 11.7709 43.8111C18.2555 46.9743 19.7185 58.8101 23.5012 63.8185C24.437 58.3619 22.5786 49.2808 23.4748 43.8243C23.5539 42.4799 21.0629 43.0862 20.7465 41.6891C18.9936 45.3004 15.5667 43.2971 14.7628 40.2788V40.292Z" fill="#DDDDDD"/>
<path d="M7.11823 89.7964C6.78512 93.3683 11.629 95.1476 7.92222 95.1476C6.2088 95.9779 7.98813 98.8644 6.11655 99.5629C5.40106 99.5965 5.25983 99.6815 4.90397 99.5893C3.16419 98.983 -0.420791 98.2185 0.646798 95.4771C1.20036 93.1574 2.0307 90.8113 3.1642 88.7289C4.60083 88.2807 6.03746 88.8211 7.11823 89.7964Z" fill="#C68F64"/>
<path d="M6.53824 47.6067C-0.565842 59.7324 0.659904 76.1022 0.725804 89.8358C0.580823 91.0616 9.04245 91.7865 8.66023 90.3894C8.13303 87.0812 7.61901 83.7993 8.50207 80.5175C9.082 73.967 8.15939 64.9386 9.6751 54.7899C10.3473 50.4536 7.88261 46.2228 6.55141 47.6199L6.53824 47.6067Z" fill="#162B49"/>
<path d="M28.4693 27.5208C31.1844 20.9044 31.4875 14.8283 23.9221 12.7854C26.8481 10.782 18.1229 10.3998 15.7505 10.8479C13.378 11.2961 12.06 14.1034 10.2939 15.7509C8.01372 17.9388 5.91809 20.7067 6.30031 24.0412C6.33985 25.6624 7.77648 26.3082 8.79135 27.2176C9.23948 27.8635 9.08131 28.7729 9.47671 29.4451C10.6629 28.9706 10.2148 27.4417 9.66123 26.5982C7.14383 19.2832 21.4443 14.1166 26.1364 18.5978C27.2567 20.5353 28.6274 25.3724 28.4561 27.5076L28.4693 27.5208Z" fill="#522537"/>
<path d="M284.852 83.7065C287.948 84.7869 287.453 86.0042 290.548 87.0846C292.031 87.603 293.775 86.3129 294.966 87.3409C293.758 93.0487 282.595 108.879 292.657 110.594C295.004 110.842 297.086 108.305 299.329 108.963C301.201 110.251 301.283 113.777 300.531 115.74C292.395 123.978 280.036 121.986 279.643 108.908C278.909 102.848 283.99 86.2954 284.855 83.7065H284.852Z" fill="#F8BD84"/>
<path d="M360.842 100.856C374.331 114.959 382.496 99.5976 387.264 87.288C384.928 88.6072 382.191 86.6357 380.274 85.0107C379.835 84.8098 380.767 82.3869 380.35 82.6344C378.955 83.9769 375.717 98.5521 370.688 98.8259C364.994 97.6494 363.999 93.6248 360.845 100.859L360.842 100.856Z" fill="#F8BD84"/>
<path d="M322.678 71.7575C324.119 74.2008 324.585 84.7514 328.717 80.1124C334.111 79.8882 329.745 73.6329 329.716 70.4907" fill="#F8BD84"/>
<path d="M314.644 198.086C312.754 201.604 296.501 204.417 299.661 211.246C306.936 211.456 313.706 206.395 319.906 203.019C321.616 198.712 318.523 197.189 314.644 198.089V198.086Z" fill="#661A2E"/>
<path d="M325.912 198.392C323.926 201.732 322.199 207.437 328.3 205.433C334.765 204.641 343.449 212.559 349.064 207.329C349.119 201.216 329.235 203.066 330.126 198.409L325.912 198.392Z" fill="#661A2E"/>
<path d="M315.553 137.458C316.305 144.354 312.085 150.589 312.318 157.532C312.21 164.346 311.7 172.745 311.415 179.751C310.882 185.82 312.629 191.871 313.299 197.719C315.341 199.05 318.558 198.098 320.981 198.243C315.163 183.717 319.723 140.755 341.119 147.261C346.451 157.8 332.03 187.585 324.852 198.354H331.765C344.476 179.014 352.403 155.161 353.702 132.546C356.611 122.644 333.9 143.926 315.556 137.458H315.553Z" fill="#2C2E2B"/>
<path d="M314.689 135.897C315.094 138.052 313.918 141.98 317.197 141.969C325.668 142.857 334.233 141.628 342.529 139.697C346.647 138.395 355.456 137.082 354.516 131.485C354.638 130.052 354.519 127.845 352.474 128.736C340.523 133.614 327.742 139.651 314.689 135.894V135.897Z" fill="#AC2C2D"/>
<path d="M337.59 134.185C334.931 142.155 340.563 141.695 346.111 139.397C348.77 130.253 344.358 129.755 337.59 134.185Z" fill="#034B6B"/>
<path d="M322.992 78.0646C318.72 86.766 306.157 86.3962 302.529 95.6917C301.108 100.304 299.017 104.547 296.641 108.741C295.653 110.293 297.188 111.152 298.091 112.233C298.472 114.187 299.643 116.374 301.804 114.559C310.01 99.8385 304.955 133.902 309.521 138.232C313.254 143.864 354.435 131.537 359.77 127.096C361.572 126.173 361.537 124.3 360.664 122.655C358.748 113.776 359.199 104.323 355.597 95.7383C357.248 96.7954 360.614 103.121 362.172 101.647C363.395 99.5153 364.653 96.9963 363.751 94.7103C360.029 90.4411 357.428 85.4759 354.641 80.5952C348.826 73.5508 337.606 81.0262 331.682 75.167C330.672 78.8392 325.992 78.4024 322.992 78.0616V78.0646Z" fill="#F37617"/>
<path d="M387.832 73.2079C388.257 70.3481 388.874 64.6578 384.451 65.3305C383.341 67.3865 384.608 69.9288 384.343 72.1886C383.708 75.8579 381.64 79.1573 380.35 82.6344C377.502 86.3416 384.392 93.4908 386.772 87.9898C390.284 83.4993 396.015 80.0776 401.519 78.6623C412.422 77.314 407.133 74.9901 399.783 76.1112C402.212 74.1572 405.695 74.5503 408.537 73.5049C410.121 71.0296 404.702 71.3557 403.298 71.8887C391.195 75.456 402.96 70.022 407.162 68.6737C409.795 64.4715 398.671 69.987 397.366 69.8793C397.619 67.8117 407.521 66.2187 404.448 64.2123C397.704 63.758 393.921 72.0663 387.829 73.2108L387.832 73.2079Z" fill="#F8BD84"/>
<path d="M322.081 77.3111C317.935 79.9 308.118 76.9704 303.255 76.4869C296.528 77.4654 291.341 75.2842 287.911 69.3814C284.742 59.7888 284.075 49.4187 283.111 39.3923C282.395 33.8446 288.309 30.3879 291.731 26.8934C301.702 20.1547 316.138 15.2769 328.214 17.4406C332.891 19.3277 335.498 24.2754 337.274 28.993C339.968 36.1452 348.154 45.7378 347.298 53.3326C345.588 70.5054 335.259 73.8136 322.079 77.3111H322.081Z" fill="#F8BD84"/>
<path d="M285.513 60.9538C297.1 53.4463 277.723 55.4498 288.681 30.1522C297.494 47.2318 355.258 16.6283 340.497 0C340.159 19.1793 314.78 6.50861 303.609 6.96581C296.087 7.0124 288.917 10.0498 282.251 13.5327C255.937 22.866 266.503 70.9482 285.513 60.9538Z" fill="#2C2E2B"/>
<path d="M284.508 49.125C282.743 49.6434 282.169 51.7838 281.24 53.3709C278.873 56.5102 273.849 60.9162 278.564 64.4195C286.001 68.9479 292.015 75.3895 298.503 81.0973C305.524 84.9063 310.204 73.8664 310.868 68.5693C306.506 65.9717 303.337 61.5627 298.503 59.6553C293.224 57.113 290.466 50.3161 284.502 49.125H284.508Z" fill="#522537"/>
<path d="M310.433 36.9786C306.863 35.5604 295.945 39.0316 298.636 43.691C300.223 42.9717 301.239 40.741 303.426 40.4964C305.57 39.1073 310.008 39.7393 310.43 36.9786H310.433Z" fill="#2C2E2B"/>
<path d="M334.758 27.8077C330.774 27.5602 324.927 28.6435 322.509 32.0448C322.396 33.134 323.604 33.4048 324.143 32.537C326.633 29.1473 332.909 31.0693 334.758 27.8048V27.8077Z" fill="#2C2E2B"/>
<path d="M302.483 45.5116C308.721 37.0315 316.884 45.5466 308.94 45.2233C306.837 42.8936 304.568 43.1703 302.483 45.5116Z" fill="#2C2E2B"/>
<path d="M333.029 30.9764C330.146 30.8396 323.579 33.676 326.864 37.0628C328.821 38.0325 331.384 35.4553 329.278 33.9352C328.786 32.0481 332.744 32.8664 333.029 30.9764Z" fill="#2C2E2B"/>
<path d="M323.431 49.2147C325.915 52.1443 331.786 49.736 330.883 45.79C329.634 48.8303 326.273 49.0633 323.431 49.2147Z" fill="#C68F64"/>
<path d="M319.355 63.091C319.023 62.9133 319.175 62.4095 319.548 62.4445C326.327 62.9803 333.494 59.8789 338.15 55.4932C340.544 55.2574 337.46 60.7234 336.846 62.3222C333.261 68.5308 324.507 65.8459 319.355 63.0939V63.091Z" fill="white"/>
<path d="M312.145 43.9968C312.212 45.9566 306.798 45.9566 306.868 43.9968C306.801 42.0369 312.215 42.0369 312.145 43.9968Z" fill="#2C2E2B"/>
<path d="M331.371 34.7387C331.936 36.617 326.703 37.9974 326.269 36.087C325.704 34.2116 330.937 32.8284 331.371 34.7387Z" fill="#2C2E2B"/>
<path d="M287.917 71.373C286.435 68.8715 282.893 62.0483 279.952 66.4952C283.619 74.2822 286.315 75.7033 284.597 85.9453C285.415 92.7189 295.261 90.3775 296.478 84.6814C298.389 80.718 302.125 77.8641 302.678 73.2659C302.839 72.0399 304.35 67.3863 302.483 67.9279C301.106 69.4626 302.218 72.4709 300.483 73.5804C300.885 70.3188 302.748 67.162 302.186 63.6063C302.646 61.0378 299.402 60.3244 299.72 63.318C300.352 65.2226 297.958 73.3969 298.977 67.2319C299.536 66.0001 299.592 59.1799 297.323 60.1846C295.299 61.1514 298.08 71.7661 295.235 68.6356C294.766 67.2669 297.728 58.6441 294.661 57.5142C290.59 62.6657 297.62 65.6623 287.914 71.373H287.917Z" fill="#F8BD84"/>
<path d="M311.565 43.6349C311.589 45.3298 309.093 45.3298 309.116 43.6349C309.093 41.9401 311.589 41.9401 311.565 43.6349Z" fill="white"/>
<path d="M329.73 35.4103C329.753 37.1052 327.257 37.1052 327.28 35.4103C327.257 33.7154 329.753 33.7154 329.73 35.4103Z" fill="white"/>
<path d="M323.641 77.2639C314.086 86.2507 335.383 81.8009 335.121 75.6301C334.905 74.3721 332.476 74.3168 331.655 74.9196C330.228 77.8579 326.413 78.7607 323.641 77.2668V77.2639Z" fill="#2C2E2B"/>
<path d="M295.268 109.449C297.297 110.96 298.902 118.296 301.928 115.558C304.723 112.736 301.24 110.934 299.95 108.231C297.766 105.517 296.581 107.329 295.268 109.449Z" fill="#2C2E2B"/>
<path d="M363.926 94.4673C362.205 96.4941 361.754 99.1704 360.813 101.695C360.755 103.253 364.319 105.583 365.071 103.836C365.449 100.032 369.768 95.2856 363.926 94.4702V94.4673Z" fill="#2C2E2B"/>
<path d="M211.183 309.768C210.952 310.641 211.238 311.594 211.747 312.333C212.263 313.071 212.986 313.643 213.724 314.167C215.44 315.374 217.298 316.383 219.236 317.177C219.736 317.384 220.244 317.574 220.697 317.86C221.11 318.122 221.467 318.464 221.825 318.797C222.19 319.147 222.563 319.496 222.865 319.901C223.858 321.251 223.937 323.173 223.207 324.682C222.476 326.191 221.007 327.295 219.395 327.74C217.774 328.193 216.019 328.026 214.439 327.462C214.002 327.303 213.573 327.12 213.121 327.025C211.993 326.795 203.876 318.869 201.819 316.272C201.248 315.549 200.668 314.795 200.406 313.921C200.136 313.04 200.239 311.983 200.89 311.332C201.518 310.705 202.471 310.594 203.352 310.506C205.846 310.268 208.348 310.038 210.841 309.799L211.175 309.768H211.183Z" fill="#661A2E"/>
<path d="M176.405 309.768C176.635 310.641 176.349 311.594 175.841 312.333C175.325 313.071 174.602 313.643 173.863 314.167C172.148 315.374 170.289 316.383 168.352 317.177C167.851 317.384 167.343 317.574 166.89 317.86C166.477 318.122 166.12 318.464 165.763 318.797C165.397 319.147 165.024 319.496 164.722 319.901C163.729 321.251 163.65 323.173 164.381 324.682C165.111 326.191 166.581 327.295 168.193 327.74C169.813 328.193 171.568 328.026 173.148 327.462C173.585 327.303 174.014 327.12 174.467 327.025C175.595 326.795 183.711 318.869 185.768 316.272C186.34 315.549 186.92 314.795 187.182 313.921C187.452 313.04 187.348 311.983 186.697 311.332C186.07 310.705 185.117 310.594 184.235 310.506C181.742 310.268 179.24 310.038 176.746 309.799L176.413 309.768H176.405Z" fill="#661A2E"/>
<path d="M241.091 175.09C239.63 164.655 238.137 154.569 235.984 144.236C235.405 138.097 231.831 133.507 227.161 129.774C219.434 126.201 210.459 125.073 204.154 118.91C202.835 118.759 202.549 120.435 201.35 120.49C199.944 123.024 197.117 122.841 195.148 124.342C191.78 124.58 187.547 120.792 184.831 118.894C181.821 121.126 179.351 124.136 175.412 124.771C151.801 130.449 152.691 143.395 149.808 164.043C147.695 169.618 149.141 176.583 146.631 181.722C143.915 184.358 146.059 188.059 145.686 191.323C146.631 196.001 158.512 190.585 162.562 192.046C162.983 191.776 163.714 191.609 163.666 191.085C164.087 186.622 163.801 184.962 162.078 180.928C163.198 172.652 164.675 164.44 166.43 156.276C172.847 214.299 153.445 207.509 201.874 207.485C207.068 207.39 212.294 207.803 217.472 207.437C219.902 206.993 218.433 208.017 220.379 206.945C221.046 191.03 221.776 172.247 222.205 156.221C224.056 164.655 227.209 174.201 226.335 182.611C224.937 183.668 223.953 191.935 226.407 192.038C230.854 191.848 235.659 192.388 239.868 193.253C245.006 193.722 242.791 188.917 243.616 185.629C243.791 184.271 244.268 182.579 242.25 181.928C241.131 180.094 241.607 177.258 241.083 175.09H241.091Z" fill="#98CA3B"/>
<path d="M196.029 134.984C200.413 132.046 203.987 122.166 204.026 117.862C204.058 113.946 205.567 110.261 214.494 108.204C226.708 100.811 225.763 88.5881 234.507 87.4604C239.653 82.6635 240.265 70.9811 231.275 65.5569C231.727 67.9235 231.275 76.1274 229.115 73.038C237.739 7.58952 171.244 12.1304 161.562 38.5229C156.693 41.4931 160.489 66.6767 159.163 74.3882C156.098 73.6257 157.94 68.9321 156.868 66.6846C149.506 69.7342 147.775 80.146 152.921 86.1897C154.803 88.8025 158.702 88.8184 160.489 90.6689C164.309 100.382 172.474 109.682 184.505 111.286C185.713 112.032 185.824 115.606 185.863 119.331C185.903 122.921 193.583 139.876 196.029 134.984Z" fill="#F8BD84"/>
<path d="M185.8 54.8134C179.452 54.0167 171.983 53.5686 167.362 59.1953C173.057 57.3031 178.938 55.3114 184.773 57.1538L185.8 54.8134Z" fill="#2C2E2B"/>
<path d="M201.327 54.8134C207.675 54.0167 215.144 53.5686 219.765 59.1953C214.07 57.3031 208.189 55.3114 202.354 57.1538L201.327 54.8134Z" fill="#2C2E2B"/>
<path d="M216.098 207.596C201.43 207.874 186.412 206.611 171.902 208.009C173.03 209.486 176.866 208.787 176.278 211.241C174.126 215.426 173.538 222.407 170.607 225.29C171.322 253.801 173.927 281.995 174.046 310.379C181.591 316.589 185.927 315.358 188.278 309.775C188.58 289.876 188.58 239.446 188.58 226.855C189.302 224.25 191.397 217.963 195.026 217.606C198.004 218.519 200.371 223.511 201.38 226.855C200.99 258.773 199.595 292.049 199.571 309.767C201.017 313.746 210.436 316.216 213.835 310.553C215.201 308.274 215.447 302.103 215.518 296.417C215.82 272.655 217.663 248.861 218.663 225.314C215.272 223.209 215.002 215.776 212.747 211.86C210.761 208.692 218.37 209.478 216.082 207.58V207.596H216.098Z" fill="#2C2E2B"/>
<path d="M218.671 225.33C226.915 216.086 235.055 206.5 239.129 194.691C239.161 193.928 240.313 192.856 239.471 192.92C232.156 192.237 228.924 190.791 226.954 192.006C224.937 193.253 224.222 197.24 220.672 203.609C220.768 208.676 210.928 208.573 212.755 211.869C215.01 215.824 215.232 223.051 218.671 225.322V225.33Z" fill="#F8BD84"/>
<path d="M148.601 193.126C152.723 204.999 162.626 217.047 170.6 225.076C173.649 222.328 174.11 215.387 176.278 211.241C176.651 210.217 176.127 209.899 175.349 209.55C167.876 207.874 166.351 197.859 162.562 192.054C158.457 190.275 152.81 192.229 148.593 193.126H148.601Z" fill="#F8BD84"/>
<path d="M175.03 64.7069C174.236 71.799 182.265 71.9499 181.773 64.5163C181.844 59.1715 174.45 58.965 175.03 64.7069Z" fill="#2C2E2B"/>
<path d="M207.251 64.5802C206.536 70.894 213.795 72.7047 214.025 65.16C214.509 58.9495 206.544 59.3307 207.251 64.5802Z" fill="#2C2E2B"/>
<path d="M210.476 67.2478C209.777 67.2478 209.213 66.4695 209.213 65.5006C209.213 64.5317 209.777 63.7534 210.476 63.7534C211.175 63.7534 211.738 64.5317 211.738 65.5006C211.738 66.4695 211.175 67.2478 210.476 67.2478Z" fill="white"/>
<path d="M178.08 67.0813C177.381 67.0813 176.817 66.303 176.817 65.3341C176.817 64.3652 177.381 63.5869 178.08 63.5869C178.779 63.5869 179.343 64.3652 179.343 65.3341C179.343 66.303 178.779 67.0813 178.08 67.0813Z" fill="white"/>
<path d="M189.692 86.2296C198.015 86.8093 201.129 83.8391 198.444 77.708C199.151 84.4506 195.021 85.9754 189.692 86.2296Z" fill="#BB8B6C"/>
<path d="M200.508 99.9683C216.495 88.3098 190.883 94.4488 183.664 91.9868C182.782 99.2695 194.663 103.201 200.508 99.9683Z" fill="#FDFDFD"/>
<path d="M221.086 21.416C225.287 21.7972 228.94 24.9421 231.275 28.7701C233.602 32.598 234.817 37.0852 236 41.5008C236.493 43.3433 236.993 45.2176 236.977 47.1395C236.961 49.1964 236.358 51.1819 235.802 53.1435C234.904 56.2964 234.11 59.5049 233.777 62.7928C233.388 66.5573 233.61 70.409 232.824 74.102C231.497 80.2807 227.415 85.6732 227.241 92.0108C225.605 80.3681 225.144 68.5268 225.851 56.7809C226.129 52.1587 226.446 47.0045 223.722 43.4545C221.562 40.6352 218.02 39.6107 214.677 39.1659C203.526 37.6729 191.344 40.651 181.567 34.6073C180.058 33.6702 178.629 32.5266 176.977 31.9389C173.093 30.557 168.686 32.6378 166.105 36.0924C163.523 39.5392 162.491 44.0819 162.142 48.5134C161.792 52.945 162.046 57.4242 161.681 61.8557C161.339 65.9378 160.474 69.9564 160.108 74.0385C159.584 79.8678 160.085 85.8082 161.578 91.431C156.908 87.746 155.065 81.194 153.961 75.0312C152.381 66.1919 151.722 57.1859 151.071 48.2117C150.753 43.8437 150.443 39.3804 151.357 35.1156C153.167 26.6734 160.148 19.8514 168.042 18.811C169.75 18.5887 171.545 18.5887 173.054 17.6912C174.189 17.0162 175.031 15.9043 176.008 14.991C178.43 12.7197 181.631 11.6872 184.776 11.0757C196.808 8.74082 209.665 11.9175 219.601 19.6688L221.078 21.408L221.086 21.416Z" fill="#522537"/>
<path d="M185.839 115.756C184.044 119.457 180.208 121.014 176.896 123.452C178.326 123.714 180.613 126.438 181.622 127.487C183.711 129.663 185.482 132.14 186.856 134.825C187.022 135.158 187.3 135.555 187.666 135.468C187.777 135.444 187.872 135.373 187.959 135.309C189.746 134.031 191.533 132.752 193.32 131.473C193.463 131.37 193.622 131.251 193.67 131.084C193.725 130.886 193.614 130.679 193.503 130.496C191.351 126.939 188.73 123.635 187.046 119.831C186.617 118.854 185.839 115.749 185.839 115.749V115.756Z" fill="#2C2E2B"/>
<path d="M204.21 115.756C206.005 119.457 209.84 121.014 213.152 123.452C211.723 123.714 209.435 126.438 208.427 127.487C206.338 129.663 204.567 132.14 203.193 134.825C203.026 135.158 202.748 135.555 202.383 135.468C202.272 135.444 202.177 135.373 202.089 135.309C200.302 134.031 198.515 132.752 196.728 131.473C196.586 131.37 196.427 131.251 196.379 131.084C196.323 130.886 196.435 130.679 196.546 130.496C198.698 126.939 201.319 123.635 203.003 119.831C203.431 118.854 204.21 115.749 204.21 115.749V115.756Z" fill="#2C2E2B"/>
<path d="M146.265 181.897C145.709 181.984 145.296 182.477 145.137 183.001C144.978 183.525 145.026 184.081 145.058 184.629C145.169 186.392 145.161 188.155 145.034 189.91C144.978 190.712 144.907 191.57 145.344 192.253C145.741 192.881 146.495 193.222 147.242 193.373C148.775 193.683 150.363 193.341 151.896 193.008C154.516 192.436 157.153 191.864 159.838 191.777C160.552 191.753 161.283 191.769 161.958 191.991C162.307 192.11 162.649 192.277 163.014 192.34C163.38 192.404 163.808 192.325 164.023 192.031C164.15 191.856 164.19 191.634 164.221 191.419C164.626 188.687 164.412 185.876 163.61 183.231C163.483 182.81 163.308 182.358 162.911 182.151C162.387 181.881 161.767 182.167 161.196 182.294C160.775 182.389 160.338 182.389 159.901 182.397C156.272 182.429 152.65 182.461 149.021 182.493L146.265 181.897Z" fill="#2C2E2B"/>
<path d="M242.981 181.897C243.537 181.984 243.95 182.477 244.109 183.001C244.268 183.525 244.22 184.081 244.189 184.629C244.077 186.392 244.085 188.155 244.212 189.91C244.268 190.712 244.339 191.57 243.903 192.253C243.506 192.881 242.751 193.222 242.005 193.373C240.472 193.683 238.883 193.341 237.351 193.008C234.73 192.436 232.093 191.864 229.409 191.777C228.694 191.753 227.963 191.769 227.288 191.991C226.939 192.11 226.597 192.277 226.232 192.34C225.867 192.404 225.438 192.325 225.223 192.031C225.096 191.856 225.057 191.634 225.025 191.419C224.62 188.687 224.834 185.876 225.636 183.231C225.764 182.81 225.938 182.358 226.335 182.151C226.86 181.881 227.479 182.167 228.051 182.294C228.472 182.389 228.908 182.389 229.345 182.397C232.975 182.429 236.596 182.461 240.226 182.493L242.981 181.897Z" fill="#2C2E2B"/>
</svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'WhatIWillHaveSVG2',
  components: {
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
