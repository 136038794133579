<template>
    <div :class="['page-view']" id="howitworks" ref="howitworks" v-observe-visibility="(isVisible: Boolean) => {  if (isVisible) setActiveMenuItem('howitworks') }">
      <h1>Как это работает?</h1>
      <div class="hot-it-works">
        <div class="text">
          <p>Основной функционал доступен уже в базовой версии приложения. Вы просто выбираете одно из 5 направлений.</p>
          <div class="green-list">
            <div class="green-list-item">Романтическое знакомство</div>
            <div class="green-list-item">Знакомство с одним человеком</div>
            <div class="green-list-item">Общение в кругу большой компании</div>
            <div class="green-list-item">Телефонный звонок</div>
            <div class="green-list-item">Рабочие контакты</div>
          </div>
          <p>Затем приложение в формате сменяющихся экранов проводит для вас небольшой экспресс-тренинг. Он проходит в форме успокаивающе-мотивирующего диалога с определенными визуальными образами.</p>
        </div>
        <HowItWorksSVG class="right-col" />
      </div>
      <h1>Как выглядит работа с приложением?</h1>
      <div class="hot-it-works">
        <div class="text">
          <p>В основе метода — современные когнитивные техники, используемые ведущими психологами, работающими в области социальной коммуникации.</p>
          <p>Общение с приложением проходит максимально просто:</p>
          <ul>
            <li>Вы выбираете в диалоге варианты своего состояния.</li>
            <li>Приложение на основе вашего выбора предлагает индивидуальные варианты действий, психологических техник для успокоения и концентрации или полезные советы.</li>
            <li>И так — шаг за шагом.</li>
            <li>После такого диалога вы подходите непосредственно к разговору подготовленными и уравновешенными.</li>
          </ul>
          <p>Воспользуйтесь преимуществами нашей передовой технологии, которая адаптируется к вашим конкретным потребностям и грамотно рекомендует правильные шаги и техники для получения уверенности.</p>
        </div>
        <HowItWorks2SVG class="right-col-2" />
      </div>
      <h1>Еще больше возможностей в премиум версии</h1>
      <p>Полная версия приложения значительно расширяет его функционал. Белая ворона — становиться не просто просто приложением для снятия чувства тревоги при общении. Теперь это полноценный тренер по эффективной коммуникации.</p>
      <p>Вам становится доступным:</p>
      <div class="list-with-images">
        <div class="list-with-images-item">
          <MoreInPremiumSVG1/>
          <strong>Генерация тем и фраз для начала разговора, в зависимости от ситуации</strong>
          <p>Когда не знаете с чего начать разговор или как его продолжить и развить — просто сгенерируйте тему, или несколько, пока какая-то не понравится, и используйте ее.</p>
        </div>
        <div class="list-with-images-item">
          <MoreInPremiumSVG2/>
          <strong>Дополнительные темы, которых нет в бесплатной версии</strong>
          <p>Например, “Как наладить общение со свекровью”, “Как попросить повышения у начальника” и другие.</p>
        </div>
        <div class="list-with-images-item">
          <MoreInPremiumSVG3/>
          <strong>Треннинг для повышения уверенности</strong>
          <p>Проходите специальные задания и получайте опыт, который прокачает ваши навыки общения и поможет добиваться нужного результата в любом сценарии коммуникации.</p>
        </div>
      </div>
    </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex';
import { sendMetrik } from '../../utils/metrika';
import HowItWorksSVG from '@/assets/HowItWorksSVG.vue';
import HowItWorks2SVG from '@/assets/HowItWorks2SVG.vue';
import MoreInPremiumSVG1 from '@/assets/MoreInPremiumSVG1.vue';
import MoreInPremiumSVG2 from '@/assets/MoreInPremiumSVG2.vue';
import MoreInPremiumSVG3 from '@/assets/MoreInPremiumSVG3.vue';

export default defineComponent({
  name: 'HowItWorksView',
  methods: {
    ...mapMutations(['setActiveMenuItem']),
    sendMetrik: sendMetrik,
  },
  components: {
    HowItWorksSVG,
    HowItWorks2SVG,
    MoreInPremiumSVG1,
    MoreInPremiumSVG2,
    MoreInPremiumSVG3,
  }
});
</script>

<style scoped>
.hot-it-works {
  display: flex;
  flex-direction: row;
  align-items: self-start;
}

.right-col {
  min-width: 40%;
  height: auto;
  margin-left: 30px;
}

.right-col-2 {
  min-width: 35%;
  height: auto;
}

@media (min-width: 1366px) and (max-width: 1920px) {
  
  .right-col {
    margin-right: -50px;
  }

}

@media (min-width: 1024px) and (max-width: 1366px) {
}

@media (max-width: 1024px) {
  .hot-it-works {
    flex-direction: column;
  }

  .right-col {
    min-width: 100%;
    width: 100%;
    margin: 40px 0 0 0;
  }

  .right-col-2 {
    min-width: 100%;
    width: 100%;
  }
}
</style>