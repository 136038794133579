import { 
  createRouter, 
  createWebHistory, 
  createMemoryHistory, 
  RouteRecordRaw, 
  RouteLocationNormalized, 
  RouteLocationNormalizedLoaded 
} from 'vue-router'
import HomeView from '../views/HomeView.vue';
import Page404View from '../views/Page404View.vue';
import SubscribtionActivationView from '../views/SubscribtionActivationView.vue';
import UserAgreementView from '@/views/UserAgreementView.vue';
import PrivacyPolicyView from '@/views/PrivacyPolicyView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },  
  {
    path: '/subscription-activate/:code',
    name: 'subscription-activate',
    component: SubscribtionActivationView,
  }, 
  
  {
    path: '/user-agreement',
    name: 'user-agreement',
    component: UserAgreementView,
  },
  
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicyView,
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'View404',
    component: Page404View
  },
]

const router = createRouter({
  history: typeof window !== 'undefined'
   ? createWebHistory()
   : createMemoryHistory(), 
  routes,
  scrollBehavior(to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, savedPosition: any | null) {
    if (to.hash) {
      return { el: to.hash }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
