<template>
    <div class="alerts-container" v-if="alerts.length !== 0">
        <div 
            v-for="(alert, index) in alerts" 
            :key="index" 
            class="alert" 
            :class="{
                [alert.class]: true
            }"
            role="alert" >
            <CrowSuccessSVG v-if="alert.class == 'alert-success'" class="crow" />
            <CrowErrorSVG v-if="alert.class == 'alert-danger'" class="crow" />
            <div class="content">
                <SuccessSVG v-if="alert.class == 'alert-success'" class="symbol" />
                <ErrorSVG v-if="alert.class == 'alert-danger'" class="symbol" />
                <div class="title">{{ alert.class == 'alert-success' ? 'Успешно!' : 'Ошибка!' }}</div>
                <span v-html="alert.text"></span>
                <button type="button" class="btn-close" @click="closeAlert(alert.id)">X</button>
            </div>
        </div>
    </div>
    
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapMutations } from 'vuex';
import CrowSuccessSVG from '@/assets/CrowSuccessSVG.vue';
import CrowErrorSVG from '@/assets/CrowErrorSVG.vue';
import SuccessSVG from '@/assets/SuccessSVG.vue';
import ErrorSVG from '@/assets/ErrorSVG.vue';

export default defineComponent({
    name: 'AlertsView',
    computed: {
        ...mapState([
            'alerts'
        ]),
    },
    methods: {
        ...mapMutations([
            'closeAlert'
        ])
    },
    components: {
        CrowSuccessSVG,
        CrowErrorSVG,
        SuccessSVG,
        ErrorSVG,
    },
})

</script>

<style scoped>
.alerts-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: var(--gray-transparent-color);
}

.alert {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
}

.crow {
    width: 20%;
}

.symbol {

}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: white;
    width: 20%;
    border-radius: 10px;
    padding: 40px;
    margin-top: -150px;
}

.title {
    color: var(--green-color);
    font-family: "Odds";
    font-size: 1.5em;
    padding: 0.5em 0;
}

.alert-danger .title {
    color: var(--orange-color);
}

.alert-danger .content {
    margin-top: -200px;

}

.btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
}
</style>
