<template>
    <div class="page container">
        <h1>Пользовательское соглашение</h1>
        <div><div>Перед началом использования Сайта, пожалуйста, ознакомьтесь с условиями настоящего Пользовательского соглашения. Факт использования Сайта любым способом (как намеренным, так и непреднамеренным) признается акцептом, то есть полным и безоговорочным согласием Пользователя, использовать Сайт на изложенных ниже условиях. Использование Сайта допустимо исключительно на условиях настоящего Пользовательского соглашения. Если Пользователь не принимает условия Пользовательского соглашения в полном объеме, он не вправе использовать Сайт в каких-либо целях.</div>
<div><i>Настоящий документ в случае приобретения Услуги также представляет собой публичную оферту (ст. 437 Гражданского Кодекса Российской Федерации), то есть предложение IT admin заключить договор с Пользователем на оказание платных консультационных и/или информационных услуг по предложенным на Сайте программам. Надлежащим принятием (акцептом) оферты считается осуществление Пользователем действий по оплате Услуги. </i></div>

<h2>1. Порядок использования Сайта</h2>
<div>1.1. Пользователь обязуется использовать Сайт только в пределах тех прав и теми способами, которые предусмотрены настоящим Соглашением.</div>
<div>1.2. Исключительное право на настоящий сайт https://white-crows.ru (далее - Сайт) принадлежит ИП Шаламова Александра Сергеевна, ИНН 662007045961, e-mail: info@oros-it.ru  (далее -  IT admin).</div>
<div>1.3. Данное Пользовательское соглашение регламентирует использование Сайта, приложений, а также других продуктов и услуг IT admin, в том числе онлайн-курсов и консультаций по разбору ситуации Пользователя (далее - Услуги). </div>
<div>1.4. IT admin обязуется на условиях, установленных настоящим Пользовательским соглашением, предоставлять Пользователю доступ к Услугам в личном кабинете, а Пользователь обязуется оплачивать данные Услуги и надлежащим образом выполнять условия, установленные настоящим Пользовательским соглашением.</div>
<div>1.5. Для получения Пользователем доступа к Услугам требуется регистрация Пользователя на Сайте.<br>
Поскольку некоторые Услуги предоставляются с помощью программного обеспечения, загружаемого на компьютер, телефон, планшет или другие устройства, вы соглашаетесь с автоматическим обновлением этого ПО и с тем, что данное Пользовательское соглашение будет распространяться на такие обновления.</div>
<div>1.6. IT admin обладает исключительным правом на все материалы, размещенные на Сайте, и/или имеет разрешение правообладателя на использование таких материалов на Сайте. IT admin также является правообладателем товарных знаков, знаков обслуживания, коммерческих обозначений, логотипов и доменных имен, используемых на Сайте, и/или имеет соответствующее разрешение правообладателя на их использование.</div>
<div>1.7. Пользователи Сайта вправе просматривать, копировать, цитировать информацию, содержащуюся на Сайте, распечатывать страницы Сайта с информацией на бумажном носителе, если такое использование информации осуществляется исключительно в личных, информационных, некоммерческих целях и при условии сохранения всех знаков охраны авторского права, смежных прав, товарных знаков и иных уведомлений об авторстве и со ссылкой на Сайт. Любые формы распространения или размещения в широком доступе какого-либо (распечатанного или электронного) содержания Сайта без предварительного письменного согласия IT admin запрещены. </div>
<div>1.8.  Любое использование в коммерческих целях или изменение опубликованных на Сайте материалов является нарушением прав IT admin на результаты интеллектуальной деятельности и средства индивидуализации и может привести к применению в отношении правонарушителя мер ответственности, предусмотренных, в том числе законодательством Российской Федерации.</div>
<div>1.9. Использование Сайта осуществляется в соответствии с законодательством Российской Федерации и иных применимых правил в зависимости от пользователя. Любые вопросы, касающиеся данного Сайта, можно направлять на электронный адрес IT admin: info@oros-it.ru .</div>

<h2>2. Правила использования</h2>
<div>2.1.  IT admin предоставляет Пользователю право использовать Сайт, личный кабинет, сервисы Сайта по их прямому назначению, в том числе путем осуществления доступа к Сайту, Личному кабинету с помощью персональных компьютеров и мобильных устройств и использования явных функций Сайта, личного кабинета на условиях безвозмездной простой (неисключительной) лицензии на территории доступа к Сайту, личному кабинету и к их функциям, на срок, в течение которого&ZeroWidthSpace;  &ZeroWidthSpace; Сайт,&ZeroWidthSpace;  &ZeroWidthSpace; личный&ZeroWidthSpace; &ZeroWidthSpace; кабинет&ZeroWidthSpace; и&ZeroWidthSpace; &ZeroWidthSpace; их&ZeroWidthSpace; функции&ZeroWidthSpace; остаются&ZeroWidthSpace; доступны&ZeroWidthSpace; для&ZeroWidthSpace;  &ZeroWidthSpace; Пользователя.<br>
<i>Создавая аккаунт, вы должны предоставить точную и полную информацию, а затем поддерживать ее актуальность на протяжении всего срока использования определенных функций IT admin. Лицам, не достигшим 13 лет, запрещено использовать Услуги. Кроме того, к некоторым регионам или Предлагаемым материалам могут применяться дополнительные требования и/или ограничения. </i><br>
<i>Вы также соглашаетесь создать и использовать только один аккаунт и не передавать третьим лицам доступ или учетные данные для доступа к нему, если только компанией IT admin не разрешено иное. Использование Услуг не дает вам права интеллектуальной собственности на используемые Услуги или материалы. </i></div>
<div>2.2. По завершении регистрации Пользователь получает доступ к личному кабинету. В личном кабинете Пользователя отображается информация о начатых и завершенных Пользователем курсах и программах обучения, о произведенных платежах.</div>
<div>2.3. Пользователю доступна информация о каждом курсе, его описание, о темах и продолжительности занятий, о продолжительности курса, преподавателях курса, о стоимости курсов, а также доступна возможность оплаты&ZeroWidthSpace;&ZeroWidthSpace; каждого&ZeroWidthSpace; курса.</div>
<div>2.4. Доступ к каждому курсу предоставляется Пользователям, оплатившим в порядке, установленном Пользовательским соглашением и указанном на соответствующей странице Сайта, стоимость курса.&ZeroWidthSpace;  &ZeroWidthSpace; Доступ&ZeroWidthSpace;  &ZeroWidthSpace; предоставляется&ZeroWidthSpace;  &ZeroWidthSpace; через&ZeroWidthSpace;  &ZeroWidthSpace; личный&ZeroWidthSpace; кабинет. </div>

<h2>3. Права и обязанности Пользователя</h2>
<div>3.1. IT admin вправе удалять или изменять недопустимые материалы либо прекращать недопустимые действия, которые выявил или о которых сообщили, а также полностью или частично приостанавливать, блокировать или прекращать доступ пользователя к Услугам.</div>
<div>3.2. Запрещается использовать Услуги для распространения материалов, которые:</div>
<div>3.2.1. Содержат незаконную информацию или пропагандируют незаконную деятельность. Обратите внимание, что среди пользователей IT admin есть лица, недавно достигшие 13 лет. Запрещено размещать материалы, недопустимые для этой возрастной категории.</div>
<div>3.2.2. Содержат реальные угрозы или способствуют организации актов насилия. Запрещено размещать материалы, которые создают реальную угрозу телесных повреждений или повреждения имущества, угрозу для людей или общественной безопасности, а также способствуют нанесению ущерба или поощряют его.</div>
<div>3.2.3. Оскорбляют других пользователей. </div>
<div>3.2.4. Нарушают права интеллектуальной либо частной собственности или другие права. <br>
<i>Если вы используете или цитируете материалы, всегда ссылайтесь на правообладателя.</i></div>
<div>3.2.5. Могут быть расценены как спам. <br>
<i>Не распространяйте недопустимые рекламные, коммерческие или агитационные материалы.</i></div>
<div>3.2.6. Другим способом нарушают Условия использования IT admin.</div>
<div>3.3. Кроме того, запрещено:</div>
<div>3.3.1. Совершать действия, нарушающие местное, национальное или международное законодательство, а также ваши договорные или фидуциарные обязательства.</div>
<div>3.3.2. Разглашать пароль или предоставлять другим лицам доступ к вашему аккаунту, а также выполнять другие действия, которые подвергают риску ваш аккаунт.</div>
<div>3.3.3. Пытаться получить доступ к аккаунтам других пользователей.</div>
<div>3.3.4. Воспроизводить, передавать, продавать, перепродавать или использовать ненадлежащим образом связанные с Услугами материалы, если у вас нет на это специального разрешения.</div>
<div>3.3.5. Пытаться получить доступ к служебным разделам нашей системы, вмешиваться в их работу и использовать их, если у вас нет на это специального разрешения.</div>
<div>3.3.6. Взламывать или обходить наши меры безопасности либо аутентификации, а также другим способом проверять уязвимость наших систем или сетей, если у вас нет на это специального разрешения.</div>
<div>3.3.7. Осуществлять обратную разработку любой составляющей Услуг.</div>
<div>3.3.8. Вмешиваться в работу наших пользователей, серверов или сетей, например, отправлять вирусные программы, перегружать, рассылать спам или организовывать почтовые бомбардировки.</div>
<div>3.3.9. Использовать Услуги для распространения вредоносного ПО.</div>
<div>3.3.10. Использовать Услуги или функции IT admin для чего-либо, кроме как для прохождения онлайн-курсов или в педагогических целях.</div>
<div>3.3.11. Выдавать себя за другое физическое лицо или предоставлять ложную информацию о принадлежности к юридическому лицу.</div>
<div>3.3.12. Помогать другим лицам выполнять любые действия из этого перечня или призывать к ним.</div>
<div>3.4. С помощью Услуг Пользователь может открывать и/или использовать материалы, предоставленные на Сайте, другими пользователями и/или третьими сторонами, а также ссылки на их сайты, услуги и социальные сети. IT admin не исключает, что сторонние материалы, предоставляемые в рамках Услуг или размещенные в других местах, могут содержать недостоверную или неправомерную информацию, а также вредоносное или другое нежелательное ПО, которое может причинить вред вашему компьютеру, мобильному устройству или файлам на них. IT admin не несет никакой ответственности и не предоставляет гарантий относительно доступа к сторонним материалам и их использования либо невозможностью такого доступа или использования.</div>
<div>3.5. На Сайте могут предоставляться ссылки на сайты сторонних организаций, которые могут быть вам интересны. Мы не раскрываем персональные данные сторонним сайтам без законных оснований. Мы не призываем к использованию сторонних сайтов и не несем ответственности за их меры по обеспечению конфиденциальности. Если вы переходите по ссылке на сторонний сайт, ознакомьтесь с его политикой конфиденциальности, чтобы узнать, как его администраторы собирают и используют персональные данные.</div>

<h2>4. Предлагаемые Услуги</h2>
<div>4.1. IT admin предлагает Услуги (онлайн-курсы/материалы.консультации), которые в том числе могут быть предоставлены образовательными партнерами IT admin (далее - Партнеры). При этом для целей предоставления наиболее качественной информации для Пользователей, IT admin вправе отменять, прерывать, переносить или изменять любые курсы, а также задания, тесты и иные дополнительные параметры курса.</div>
<div>4.2. Используя Сайт, Пользователь может предоставлять IT admin, преподавателям и/или другим пользователям доступ к созданным материалам, в том числе к домашним заданиям, ответам на тесты и экзамены, проектам, публикациям и другим выполненным заданиям (далее - Материалы). При этом Пользователь сохраняет все интеллектуальные права на такие материалы и несет за них ответственность. К Материалам Пользователя не относятся материалы курсов, предоставленные или размещенные на Сайте IT admin. </div>
<div>4.3. Пользователь для надлежащего использования Сайта предоставляет IT admin полностью передаваемую, безвозмездную, бессрочную, сублицензируемую, неисключительную, всемирную лицензию на копирование, распространение, изменение, публичное предоставление и воспроизведение Материалов пользователя, а также на создание производных работ и использование в других целях. Эта лицензия дает компании IT admin право предоставлять Материалы Пользователя Партнерам для работы с зарегистрированными студентами, учащимися, которые посещают занятия, или другими учащимися независимо от Услуг. Данные Условия не ограничивают другие юридические права IT admin на Материалы Пользователя, например по другим лицензиям. Мы оставляем за собой право удалять или изменять Материалы Пользователя по любой причине, в том числе если, на наш взгляд, они не соответствуют Условиям.</div>
<div>4.4. Для целей развития и предоставления улучшенных Услуг IT admin может использовать/анализировать информацию Пользователей об используемых Услугах.</div>

<h2>5. Оплата Услуг.</h2>
<div>5.1. IT admin устанавливает стоимость каждой Услуги путем публикации такой стоимости на Сайте с описанием этой Услуги. IT admin вправе изменять стоимость программы обучения в любое время по своему усмотрению. При этом стоимость такой программы обучения, уже оплаченная Пользователем, не меняется ни в сторону увеличения, ни в сторону уменьшения. Новая цена распространяется на правоотношения IT admin  &ZeroWidthSpace; и&ZeroWidthSpace;  &ZeroWidthSpace; Пользователя,&ZeroWidthSpace;  &ZeroWidthSpace; возникшие&ZeroWidthSpace;  &ZeroWidthSpace; после&ZeroWidthSpace;  &ZeroWidthSpace; ее&ZeroWidthSpace;  &ZeroWidthSpace; изменения.</div>
<div>5.2. По общему правилу приобретение Пользователем доступа к Услуге осуществляется на условиях предоплаты в размере полной стоимости Услуги.</div>
<div>5.3. Оплата Услуг осуществляется посредством платежной системы, под которой понимается специализированная система, осуществляющая прием платежей от Пользователей, имеющая право оказания услуг Интернет эквайринга и осуществления финансовых операций. При оплате услуг Пользователю может быть предложено осуществить оплату через платежную систему Яндекс.Касса (далее - Платежная система).</div>
<div>5.4. Для оплаты (ввода реквизитов карты Пользователя) вы будете перенаправлены на платёжный шлюз Платежной системы. Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием протокола шифрования SSL. В случае если банк Пользователя поддерживает технологию безопасного проведения интернет-платежей Verified By Visa или MasterCard SecureCode, для проведения платежа также может потребоваться ввод специального пароля. Конфиденциальность сообщаемой персональной информации обеспечивается Платежной системой. Введённая информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ, GDPR и иными применимыми правилами. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платёжных систем Visa Int. и MasterCard Europe Sprl.</div>
<div>5.5. Если не указано иное, стоимость указывается в рублях или долларах США. При этом граждане РФ оплачивают Услуги в рублях или в долларах в сумме, эквивалентной определенной за Услугу сумме в рублях Российской Федерации по курсу Центрального Банка РФ на день оплаты.<br>
<i>Вы обязаны своевременно оплачивать все сборы за Услуги и применимые налоги при помощи механизмов оплаты, связанных с соответствующими платными Услугами. Сумма оплаты может варьироваться в зависимости от вашего местоположения и других факторов, а IT admin оставляет за собой право изменять размер любых сборов в любое время по собственному усмотрению. Все изменения, обновления или корректировки вступают в силу сразу после публикации через соответствующие службы. </i></div>
<div>5.6. Вы можете запросить возврат средств за платные Услуги.</div>

<h2>6. Конфиденциальность</h2>
<div>6.1. IT admin может использовать персональную информацию пользователей Сайта, на основании <a href="/privacy-policy">Политики конфиденциальности</a>, и в связи с предоставлением им услуг и соответствующей информации, ответов на запросы (например, объявлений IT admin об открытых вакансиях, мероприятиях, или о IT admin).</div>

<h2>7. Ограничение ответственности</h2>
<div>7.1. Информация, содержащаяся на Сайте, предоставлена исключительно в информационных целях. </div>
<div>7.2. IT admin также не может гарантировать бесперебойный и безотказный доступ к Сайту и ни при каких условиях не несет ответственность за возможные убытки, возникшие у пользователей Сайта в связи с его использованием.</div>
<div>7.3. Сайт предоставляется «как есть», IT admin не несет ответственности за точное соответствие Сайта требованиям Пользователя.</div>
<div>7.4. Пользователь самостоятельно несет ответственность за любые возможные негативные последствия использования информации, полученной Пользователем с использованием Сайта. </div>
<div>7.5. Мы серьезно относимся к безопасности Пользователей и принимаем все необходимые меры, чтобы защитить ваш аккаунт и связанные с ними данные. Однако IT admin не может гарантировать абсолютную защиту от злоумышленников. Если вы обнаружите какие-либо нарушения в системе безопасности или несанкционированное использование вашего аккаунта, немедленно напишите нам по адресу info@oros-it.ru .</div>
<div>7.6. Вы обязуетесь защищать IT admin от претензий третьих сторон, освобождать от ответственности, а также возмещать расходы и ущерб, в том числе судебные издержки, связанные: а) с вашим использованием или попыткой использования Услуг с нарушением данных Условий; б) с нарушением закона или прав третьих лиц с вашей стороны; или в) с Материалами пользователя, в том числе с любыми претензиями относительно присвоения интеллектуальной собственности или нарушения других имущественных прав.</div>

<h2>8. Прочие условия</h2>
<div>8.1. Настоящее Соглашение может быть изменено IT admin без какого-либо специального уведомления, новая редакция Соглашения вступает в силу с момента ее размещения в сети Интернет по указанному в настоящем пункте адресу, если иное не предусмотрено новой редакцией Соглашения. Действующая редакция Соглашения находится на странице по адресу: <a href="/user-agreement">https://white-crows.ru/user-agreement</a>, если в Соглашении не указано иное.<br>
<i>Мы постоянно изменяем и совершенствуем Услуги. Мы можем добавлять и убирать функции и требования, а также временно или полностью прекращать предоставление всех Услуг или их части. Соответственно, IT admin может прекратить предоставлять вам Услуги по любой причине. Если мы прекратим предоставление платной Услуги, вы можете вернуть деньги в соответствии с условиями настоящего Пользовательского соглашения. Мы можем оказаться не в состоянии предоставлять Услуги в определенных регионах или странах по различным причинам, в том числе из-за применимых требований экспортного контроля или ограничений доступа к Интернету со стороны правительств. Компания IT admin, Партнеры, преподаватели, участники, подрядчики, спонсоры, другие деловые партнеры, а также их сотрудники, подрядчики и другие агенты не несут ответственности перед вами за такие действия. Вы можете в любое время прекратить использовать Услуги.</i></div>
<div>8.2. Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации. По всем вопросам, не урегулированным настоящим Соглашение, стороны руководствуются действующим законодательством Российской Федерации. </div>
<div>8.3. Если по каким-либо причинам одно или несколько условий настоящего Соглашения будут признаны недействительными или не имеющими юридической силы, это не окажет влияния на действительность или применимость остальных условий Соглашения.</div>
<div>8.4. Настоящее Соглашение может быть в любой момент расторгнуто Пользователем посредством направления IT admin уведомления о расторжении в письменной форме. IT admin вправе расторгнуть Соглашение в одностороннем порядке, уведомив Пользователя о расторжении посредством Сайта. </div>
<div>8.5. В случае если IT admin были внесены какие-либо изменения в настоящее Соглашение, с которыми Пользователь не согласен, он обязан прекратить использование Сайта.</div>
<div>8.6. Все вопросы, предложения и/или претензии, связанные с использованием/невозможностью использования Сайта, а также возможным нарушением законодательства и/или прав третьих лиц, должны направляться через форму обратной по адресу info@oros-it.ru.</div></div>
    </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import { useMeta } from 'vue-meta';

export default defineComponent({
  name: 'UserAgreementView',
  setup() {
    useMeta({ title: 'Пользовательское соглашение'})
  }
});
</script>


<style scoped>
.container {
  margin-top: 60px;
}


@media (max-width: 1024px) {
  .container {
    margin: 100px 0 0 0;
  }
}
</style>