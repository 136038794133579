<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="249" height="289" viewBox="0 0 249 289" fill="none">
<path d="M57.8432 99.3735C38.7227 98.7381 31.2467 88.1066 36.3436 82.3849C40.2087 78.0469 50.9531 69.8156 53.4345 64.1045C55.3759 70.7863 57.1796 91.2833 57.8432 99.3735Z" fill="#2C2E2B"/>
<path d="M25.2837 92.1779C18.5949 95.8735 10.8683 104.86 9.2376 102.319C6.32911 97.7866 13.3744 76.9401 13.0779 68.9312C13.1732 70.8266 15.5558 72.0903 16.8971 73.6927C20.2433 77.6919 29.0146 80.3992 31.2278 83.7877C32.8585 86.2833 29.4452 89.4635 25.2837 92.1814V92.1779Z" fill="#2C2E2B"/>
<path d="M218.408 71.0161H211.349V83.0313H218.408V71.0161Z" fill="#BB8B6C"/>
<path d="M86.5989 63.8411C73.013 53.9332 94.7807 59.1537 100.915 57.0605C101.667 63.2446 91.5652 66.5908 86.5989 63.8411Z" fill="#FDFDFD"/>
<path d="M35.4338 57.1982H26.9131V69.3334H35.4338V57.1982Z" fill="#BB8B6C"/>
<path d="M155.099 62.7387C152.201 62.2869 149.537 60.875 147.874 57.5535C148.587 53.8685 158.269 57.9559 162.021 56.0252C166.91 56.7805 158.206 63.7623 155.099 62.7422V62.7387Z" fill="#FDFDFD"/>
<path d="M32.3139 280.143C31.2832 280.122 28.7489 280.99 28.7136 279.211C28.6924 278.074 27.7323 277.143 28.4665 276.797C29.3984 276.359 32.6986 276.415 32.6986 276.415C32.6986 276.415 32.8681 279.087 32.3104 280.143H32.3139Z" fill="#F8BD84"/>
<path d="M53.4599 280.011C51.5044 280.332 51.7551 283.007 49.2948 282.595C48.6771 281.416 48.5642 277.992 49.3725 277.268C50.0184 276.689 51.7445 277.36 51.7445 277.36C51.7445 277.36 53.4705 279.086 53.4599 280.011Z" fill="#F8BD84"/>
<path d="M244.035 282.259C238.669 282.789 239.598 281.726 239.626 277.159C244.991 276.633 244.063 277.692 244.035 282.259Z" fill="white"/>
<path d="M8.55598 99.6488C10.0561 90.9939 4.71564 59.8689 13.8788 67.1577C11.3233 77.1433 17.0838 92.5576 8.55598 99.6488Z" fill="#661A2E"/>
<path d="M54.7216 67.2328C59.762 64.8078 55.4663 86.858 56.7088 93.208C49.6776 96.6247 52.2155 70.0495 54.7216 67.2328Z" fill="#661A2E"/>
<path d="M17.45 213.822C15.5722 195.121 14.3897 173.064 13.2002 153.926C19.2819 149.186 43.9441 152.532 51.1094 153.965C51.9777 190.024 53.859 228.23 53.2908 264.583C53.2096 269.327 52.1295 277.858 52.1295 277.858H49.0021C49.0021 277.858 40.6826 237.736 41.265 230.412C43.3828 208.393 35.9881 187.751 32.063 166.333C29.7934 181.024 29.1722 196.614 28.2933 211.471C30.8488 223.684 32.716 277.05 32.716 277.05C32.716 277.05 29.8887 275.966 28.0956 276.972C24.1035 255.91 21.3821 235.032 17.4429 213.829L17.45 213.822Z" fill="#F37617"/>
<path d="M135.314 160.702C140.5 160.921 144.979 159.636 149.017 159.573C166.627 159.297 174.798 154.603 178.755 158.341C182.916 162.276 182.482 175.244 186.471 199.762C189.023 219.878 192.376 240.177 194.543 260.388C195.076 269.788 200.787 277.483 186.862 275.153C175.903 277.275 181.194 268.81 178.066 261.758C176.605 253.714 156.599 133.498 156.133 176.748C154.318 202.141 153.26 227.858 151.311 253.04C148.911 268.535 157.428 277.172 137.348 274.804C132.847 275.185 134.09 273.311 133.881 270.579C136.843 234.526 134.22 197.175 135.314 160.702Z" fill="#AC2C2D"/>
<path d="M132.864 79.8217C154.988 72.4834 140.845 82.6667 155.507 85.4057C170.897 80.7041 156.21 72.554 180.956 80.6371C192.781 83.4608 190.402 99.867 194.193 109.09C189.894 110.209 185.676 111.667 181.331 112.556C177.702 123.7 181.016 138.962 179.823 151.426C180.565 155.633 180.766 160.356 180.766 160.356C180.766 160.356 152.719 158.351 143.93 160.628C140.209 159.717 129.655 163.582 131.752 157.02C131.505 142.474 131.364 127.925 131.332 113.379C126.609 105.677 115.791 81.6819 132.864 79.8217Z" fill="#2C2E2B"/>
<path d="M183.343 112.126C195.817 105.755 196.011 114.191 199.459 124.886C202.71 118.286 205.894 113.023 209.067 106.655C214.507 103.556 210.522 84.1358 214.934 92.4554C217.807 83.2604 219.201 82.8581 217.567 92.0883C221.132 82.5016 223.084 83.624 219.371 92.9001C221.234 92.1024 221.721 86.356 224.217 87.2773C224.369 88.8127 219.978 94.6861 222.491 92.4977C223.49 90.5422 225.082 92.1342 223.504 93.426C216.289 102.039 212.795 112.599 208.76 123.224C197.995 157.159 190.946 127.654 183.343 112.126Z" fill="#C68F64"/>
<path d="M194.29 279.336C194.343 277.271 193.125 276.068 191.921 275.51C191.268 274.013 189.955 272.993 188.437 272.993C187.089 272.993 185.899 273.801 185.193 275.033C183.52 275.203 183.478 276.604 182.913 278.694C179.567 288.242 198.885 289.188 194.293 279.343L194.29 279.336Z" fill="#661A2E"/>
<path d="M146.385 277.262C145.965 274.66 145.351 274.276 144.543 274.671C143.851 273.45 142.683 272.645 141.356 272.645C140.028 272.645 138.793 273.499 138.112 274.784C137.882 274.788 137.628 274.795 137.349 274.809C126.983 287.569 150.445 291.501 146.385 277.262Z" fill="#661A2E"/>
<path d="M124.473 157.068C122.394 163.965 124.212 197.102 116.164 193.184C117.498 191.415 118.314 188.909 117.421 186.918C117.135 188.401 114.145 189.848 115.186 187.367C120.756 170.816 116.072 150.658 115.045 135.24C116.584 130.157 111.007 121.975 114.766 118.703C118.804 116.215 126.679 117.268 124.473 110.784C130.657 111.839 126.697 121.696 125.998 125.568C123.478 135.727 126.538 146.577 124.477 157.068H124.473Z" fill="#F8BD84"/>
<path d="M238.485 151.712C236.649 157.805 238.255 187.066 231.146 183.607C232.325 182.047 233.045 179.83 232.255 178.072C232.004 179.382 229.364 180.66 230.282 178.468C235.199 163.851 231.062 146.047 230.158 132.433C231.517 127.943 226.808 114.71 229.911 117.831C232.385 120.319 238.509 123.492 238.149 126.948C237.122 136.799 240.306 142.443 238.485 151.709V151.712Z" fill="#F8BD84"/>
<path d="M47.3046 115.823C46.8564 162.472 70.6361 155.162 13.377 155.345C10.1508 147.534 18.8657 133.595 17.4573 123.56C17.7256 119.967 9.50485 123.158 9.07422 118.996C16.8643 119.37 9.87547 115.812 7.48938 113.829C5.14917 100.123 12.2616 93.9281 25.2686 92.1809C29.3772 96.593 35.4271 96.5895 39.6416 92.1738C52.0839 95.9859 58.9562 90.6278 65.2991 105.138C60.1599 108.915 47.9153 108.908 47.3011 115.826L47.3046 115.823Z" fill="#C68F64"/>
<path d="M3.66048 98.2304C12.9225 81.4925 6.7737 107.228 8.10793 114.337C11.5282 115.695 16.3887 119.631 9.49864 118.996C10.4552 122.204 14.945 121.311 17.6735 122.169C18.4218 132.832 12.5166 143.118 12.5024 153.725C12.4883 164.331 8.11146 164.67 11.9059 176.929C12.4354 178.937 11.1647 178.404 10.1163 177.148C9.10331 179.41 12.0259 180.84 10.9741 182.584C2.16035 171.829 8.78211 168.094 9.05743 154.614C9.49158 133.235 9.05743 134.968 9.87632 129.419C9.61159 131.219 5.5983 123.489 6.39602 124.975C5.80303 126.196 8.99389 129.514 6.48073 129.094C-2.31886 123.069 0.921421 107.231 3.66048 98.2269V98.2304ZM7.88556 121.107C8.19265 123.154 8.73622 125.109 10.0422 126.991C10.8117 124.301 8.97977 122.847 7.88556 121.107ZM4.4229 126.465C4.34525 125.572 4.37702 124.64 3.66048 123.609C3.43458 124.936 3.82991 125.734 4.4229 126.465Z" fill="#F8BD84"/>
<path d="M244.108 279.012C248.517 282.38 251.394 287.819 241.832 286.689C240.265 286.506 237.409 279.012 239.665 279.21C240.459 280.777 241.066 281.631 242.944 281.148C244.363 281.014 243.794 280.244 244.105 279.009L244.108 279.012Z" fill="#661A2E"/>
<path d="M134.567 44.2149C130.526 22.3553 131.225 17.3784 148.679 4.23375C152.795 1.33585 175.23 1.33938 166.148 4.06079C169.476 3.21719 180.722 7.84819 172.703 7.28696C179.533 10.9014 181.146 12.4262 180.856 14.7876C171.7 7.97526 179.437 20.774 177.824 25.6874C177.33 33.0469 177.055 40.3993 176.084 47.3846C174.498 47.4152 173.676 46.3704 173.617 44.2502C180.546 15.8183 159.572 26.2345 140.808 24.4873C131.708 21.72 140.268 58.0055 134.567 44.2149Z" fill="#522537"/>
<path d="M165.923 77.4002C166.001 91.5297 142.136 85.1939 145.723 77.5555C146.182 76.5778 150.195 76.5849 151.589 75.6177C161.515 69.3595 160.336 71.2479 160.329 73.4646C160.325 75.2824 161.652 76.9837 165.923 77.4002Z" fill="#C68F64"/>
<path d="M180.678 45.5352C179.562 42.7079 176.202 43.4562 176.202 43.4562C176.202 43.4562 174.638 44.2681 174.398 44.8081C174.331 44.9563 174.264 45.2246 174.201 45.5529C174.116 45.1364 174.042 44.6387 173.978 44.0421C174.631 40.343 179.386 22.2955 168.451 23.0156C158.765 23.651 134.865 14.4843 136.327 32.3376C136.464 34.0213 136.496 40.4418 136.306 44.2257H134.52C134.52 44.2257 134.551 44.2257 134.565 44.2222C129.271 39.5912 127.619 53.8618 133.086 54.9949C134.142 55.1184 135.857 53.7559 135.875 56.2303C135.762 62.0967 138.787 66.5371 144.375 68.5737C154.632 76.7944 175.189 69.9679 175.373 56.1103C179.774 53.703 182.555 52.6758 180.674 45.5387L180.678 45.5352ZM161.84 58.8387C156.153 67.3912 137.545 55.3478 160.894 57.4057C162.125 56.9009 162.574 57.8469 161.84 58.8387Z" fill="#C68F64"/>
<path d="M166.025 34.2716C174.839 36.6683 165.912 37.4978 162.132 36.8236C156.534 35.8706 163.491 33.2092 166.025 34.2716Z" fill="#522A2F"/>
<path d="M147.664 34.3924C156.298 35.2254 146.545 37.6079 143.076 37.2479C138.229 35.5537 145.765 33.81 147.664 34.3924Z" fill="#522A2F"/>
<path d="M162.462 41.4013C163.364 46.5269 173.24 39.9248 165.07 39.4595C162.26 39.5364 162.872 40.6665 162.462 41.4013Z" fill="#2C2E2B"/>
<path d="M149.524 41.4022C148.622 46.5279 138.746 39.9258 146.916 39.4604C149.726 39.5374 149.115 40.6675 149.524 41.4022Z" fill="#2C2E2B"/>
<path d="M151.89 48.0526C153.947 51.1876 156.451 50.4312 159.056 48.8486C156.264 51.7163 153.516 52.4791 151.89 48.0526Z" fill="#9B6E50"/>
<path d="M123.543 89.6988C132.501 124.703 133.821 108.138 113.92 120.969C116.995 134.855 115.103 149.27 115.424 163.392C111.552 170.653 80.3034 164.19 70.1943 166.276C63.7067 164.829 68.8954 143.802 67.1376 136.976C66.0751 126.644 68.9801 114.566 65.5457 105.134C63.0784 102.713 56.6543 92.9179 56.6543 92.9179L56.8908 81.1075C56.8908 81.1075 62.6301 80.0027 65.4327 81.1887C71.7791 83.8783 78.7786 81.4146 82.6719 83.1794C86.5651 84.9443 84.525 91.1566 92.3504 90.7013C101.316 90.1789 96.6319 72.862 109.678 87.8916C111.651 90.1647 112.353 85.7526 118.594 86.6033C120.549 87.3939 121.788 88.8093 123.543 89.7059V89.6988Z" fill="#98CA3B"/>
<path d="M116.605 86.1477C116.576 90.1646 123.558 94.8697 119.623 98.3041C118.906 102.617 118.465 102.134 117.318 98.1417C116.368 95.3709 118.56 103.316 115.634 102.988C114.476 100.891 115.284 97.5911 113.643 95.2862C112.718 97.224 114.639 100.383 112.718 101.52C109.976 100.969 112.824 91.1176 109.107 95.5015C103.947 96.5604 110.901 90.1928 109.125 88.1773C107.886 85.2724 101.776 80.0837 99.5984 83.3875C99.196 93.5072 82.9946 92.9389 82.6699 83.1722C79.6767 81.5697 73.5138 84.7359 69.377 82.2121C81.0215 76.7093 111.515 68.9686 116.601 86.1477H116.605Z" fill="#F8BD84"/>
<path d="M96.3391 73.2405C95.8803 73.657 96.3603 76.7984 95.7532 77.2149C93.3742 78.8457 89.375 79.5304 87.5819 77.7832C86.6218 76.8479 89.195 72.4745 86.8301 72.4745C89.5197 72.4745 95.792 71.0203 96.3391 73.2405Z" fill="#F8BD84"/>
<path d="M160.569 70.7371C160.11 71.263 160.59 75.2198 159.983 75.7458C157.604 77.8036 153.605 78.6648 151.811 76.4623C150.851 75.2834 153.424 69.7735 151.06 69.7735C153.749 69.7735 160.022 67.9415 160.569 70.7371Z" fill="#C68F64"/>
<path d="M70.1923 166.269C82.038 164.691 103.28 166.265 111.314 166.015C112.217 165.987 115.316 172.813 111.967 194.8C109.909 223.119 115.81 254.526 111.091 279.802C105.782 279.142 95.1192 284.084 96.7499 275.126C94.3638 250.453 92.5637 138.412 88.4162 206.067C87.0114 226.535 86.4043 247.534 85.9454 268.112C88.4339 282.499 82.864 280.265 70.8665 280.215C67.8768 243.072 68.4416 203.748 70.1958 166.269H70.1923Z" fill="#034B6B"/>
<path d="M112.48 44.8782C112.321 44.9241 110.531 45.9336 110.44 46.4419C110.397 46.6784 116.225 22.8246 104.905 22.228C84.2596 24.1129 69.4665 17.5688 71.9832 42.8134C70.9667 43.4452 69.7383 44.2394 69.0642 44.677C65.8309 43.5581 63.7661 53.6143 67.5605 55.8486C72.5833 56.8899 71.5597 56.5087 72.188 61.5138C75.2165 70.3593 87.3658 74.715 96.0842 73.1513C102.226 69.1592 110.853 68.005 110.965 59.0607C110.874 54.6167 118.304 57.2676 116.966 50.2469C116.885 45.616 113.232 44.6594 112.48 44.8782ZM88.28 49.7634C87.8493 53.8755 90.3696 54.8073 93.6204 54.9627C88.5412 55.3156 86.6422 53.5049 88.28 49.7634ZM96.6736 61.2561C94.0616 66.8931 86.6175 64.2705 84.9232 59.0854C87.0375 57.7935 101.711 56.0498 96.6736 61.2561Z" fill="#F8BD84"/>
<path d="M83.1585 38.2493C81.263 38.0058 72.5305 40.3142 76.9885 36.5727C81.2665 33.576 94.0124 37.7869 83.1585 38.2493Z" fill="#2C2E2B"/>
<path d="M100.611 38.2493C102.506 38.0058 111.239 40.3142 106.781 36.5727C102.503 33.576 89.7567 37.7869 100.611 38.2493Z" fill="#2C2E2B"/>
<path d="M102.449 43.1378C102.901 47.1688 98.337 47.2535 98.6158 43.0319C98.577 39.9928 102.777 39.8764 102.449 43.1378Z" fill="#2C2E2B"/>
<path d="M84.1306 43.0643C84.5365 46.6505 80.4103 47.6812 80.2797 43.3926C80.0043 39.8628 84.5294 40.0817 84.1306 43.0643Z" fill="#2C2E2B"/>
<path d="M93.6228 54.9664C88.5435 55.3193 86.6445 53.5086 88.2823 49.7671C87.8517 53.8792 90.3719 54.8111 93.6228 54.9664Z" fill="#BB8B6C"/>
<path d="M57.1785 63.2891C56.1831 66.4164 56.0631 67.3235 53.0946 66.2505C49.6531 77.4044 34.5247 90.4467 22.386 80.9765C17.5468 77.8492 14.6559 73.0558 12.6864 67.8601C12.1322 66.8894 12.0475 67.8036 11.5392 66.2576C10.0885 71.118 8.24244 62.5266 8.13655 62.1737C7.24353 59.2687 9.20959 57.1191 9.4249 57.3062C10.9356 58.6298 11.4298 60.4865 11.7686 59.0287C12.7887 54.6201 11.0839 40.953 21.4118 38.4187C39.4769 35.3831 56.1937 35.8455 53.0875 57.6945C55.1065 52.9187 57.895 59.9358 57.1856 63.2855L57.1785 63.2891ZM29.5867 66.84C29.4808 66.254 31.4151 61.4748 30.8503 58.7993C30.6879 61.4113 27.7442 65.1316 29.5867 66.84ZM41.6971 48.436C40.5217 48.2348 35.6154 49.0714 38.0721 50.5503C40.5888 50.3774 43.5679 49.3855 45.6645 51.2492C50.6379 52.054 43.9809 47.3559 41.6971 48.4395V48.436ZM23.2226 48.4254C21.3342 48.496 17.2362 48.6866 17.3385 51.2421C19.7105 51.4716 22.8873 49.1949 25.9087 50.5327C29.4772 50.1938 24.811 47.8254 23.2226 48.4254ZM32.1316 67.3659C31.7398 67.0835 31.3798 67.1047 30.9244 67.3624C31.3939 67.5847 31.7539 67.5918 32.1316 67.3659Z" fill="#F8BD84"/>
<path d="M56.7547 59.1526C48.0009 59.1985 55.2122 46.0679 43.1829 40.6145C28.6298 32.9409 16.1064 38.7684 13.3426 51.1825C12.6896 54.1157 12.0719 59.2796 12.6861 62.5835C6.35024 57.5465 8.60926 48.7611 9.07165 40.7274C20.9668 1.70284 65.5084 22.8776 56.7547 59.1526Z" fill="#661A2E"/>
<path d="M32.2686 71.0383C36.9066 70.35 40.8705 72.0442 34.997 74.2927C28.4847 76.4776 22.0253 69.6158 32.2686 71.0383Z" fill="#FDFDFD"/>
<path d="M41.757 58.9758C46.4727 56.9851 52.2791 62.7032 45.0538 63.9951C42.3853 64.934 38.5979 60.9207 41.757 58.9758Z" fill="#F8BD84"/>
<path d="M22.9561 58.9481C25.6951 62.8096 21.5195 64.9874 18.0039 63.7626C13.5635 60.5152 19.7617 57.2891 22.9561 58.9481Z" fill="#F8BD84"/>
<path d="M41.6887 48.4367C43.976 47.3531 50.6295 52.0476 45.6596 51.2464C43.5665 49.3827 40.5839 50.371 38.0672 50.5475C35.6105 49.0685 40.5168 48.2355 41.6887 48.4367Z" fill="#724D23"/>
<path d="M23.2228 48.4227C24.8077 47.8261 29.4775 50.1911 25.909 50.5299C22.8875 49.1921 19.7143 51.4688 17.3388 51.2394C17.2364 48.6803 21.3344 48.4933 23.2228 48.4191V48.4227Z" fill="#724D23"/>
<path d="M22.4414 58.07C21.9437 57.7912 20.7754 56.7676 22.1943 55.1474C21.2272 54.0567 18.7246 54.0673 18.7246 54.0673C18.7246 54.0673 22.8967 53.7567 23.8604 54.1803C25.7311 55.338 25.0569 58.7972 22.4379 58.0736L22.4414 58.07Z" fill="#2C2E2B"/>
<path d="M41.1024 58.07C41.6 57.7912 42.7684 56.7676 41.3494 55.1474C42.3166 54.0567 44.8192 54.0673 44.8192 54.0673C44.8192 54.0673 40.647 53.7567 39.6834 54.1803C37.8127 55.338 38.4868 58.7972 41.1059 58.0736L41.1024 58.07Z" fill="#2C2E2B"/>
<path d="M55.6331 67.231C55.6013 68.297 53.8647 68.6429 53.6953 67.8346C52.5799 66.2144 55.3754 65.3461 55.6331 67.231Z" fill="#FDFDFD"/>
<path d="M12.2092 67.231C12.1775 68.297 10.4409 68.6429 10.2714 67.8346C9.15604 66.2144 11.9516 65.3461 12.2092 67.231Z" fill="#FDFDFD"/>
<path d="M28.6602 83.9537C33.39 84.7302 36.8809 83.3042 36.8809 83.3042C36.8809 83.3042 34.0183 88.4541 39.6588 92.1744C35.4443 96.5901 29.3944 96.5936 25.2822 92.1779C28.0283 91.8744 28.9037 86.368 28.6602 83.9537Z" fill="#F8BD84"/>
<path d="M123.135 284.032C117.95 282.539 110.813 278.353 105.239 280.46C86.4825 279.129 134.787 296.135 123.135 284.032Z" fill="#661A2E"/>
<path d="M80.8403 279.937C76.0788 280.008 64.5719 282.072 59.7326 282.941C57.4242 283.043 56.6547 285.21 57.3924 287.198C62.8388 290.985 80.6497 284.607 80.8403 279.941V279.937Z" fill="#661A2E"/>
<path d="M54.3728 281.13C54.4222 280.611 54.2492 278.641 53.9104 279.164C52.8303 280.815 49.0676 279.573 48.7429 281.055C48.4534 282.39 50.6454 285.955 56.4906 286.272C57.7013 287.695 59.9991 283.247 60.698 282.771L54.3692 281.13H54.3728Z" fill="#2C2E2B"/>
<path d="M32.3143 280.142C30.1647 281.099 27.055 274.724 27.0409 280.908C-1.02036 286.351 24.6795 289.38 31.919 282.8C32.4202 282.345 32.0531 280.774 32.3143 280.142Z" fill="#2C2E2B"/>
<path d="M228.96 88.8127C223.785 93.179 218.18 96.8287 214.424 99.9701C210.584 95.1662 201.163 90.7223 197.605 86.2925C199.493 98.7453 191.266 119.581 211.325 110.132C211.763 109.857 212.165 109.582 212.536 109.31C215.278 110.958 220.453 112.469 228.963 113.93C229.962 106.024 230.029 96.6663 228.963 88.8162L228.96 88.8127Z" fill="#2C2E2B"/>
<path d="M217.772 94.6592C218.722 98.4289 218.831 107.158 218.185 109.159C216.794 113.487 211.182 113.833 209.629 109.159C209.026 107.345 210.229 98.7325 210.642 95.4604C212.799 98.5278 215.291 95.5346 217.772 94.6592Z" fill="#F8BD84"/>
<path d="M209.735 283.46C204.37 283.99 205.298 282.927 205.326 278.36C210.691 277.834 209.763 278.893 209.735 283.46Z" fill="white"/>
<path d="M210.411 279.416C208.142 280.796 205.519 279.977 205.329 278.36C203.765 265.293 200.945 229.519 200.941 221.648C199.137 203.933 196.893 185.814 195.403 167.643C194.715 158.452 219.066 165.589 226.482 163.461C241.208 164.869 218.321 216.23 229.292 228.655C234.798 244.21 243.781 259.024 244.696 274.301C244.72 274.696 245.01 276.804 244.085 277.46C236.302 283.503 233.598 263.49 229.542 258.142C216.408 233.317 213.507 206.774 213.362 179.232C210.214 212.425 213.398 246.197 210.415 279.412L210.411 279.416Z" fill="#034B6B"/>
<path d="M179.856 136.323C181.611 136.298 184.371 136.062 186.125 136.051C190.541 136.923 189.242 130.192 190.795 127.418C202.828 126.599 198.169 136.009 194.561 143.527C190.079 143.856 184.646 143.651 180.1 143.012L179.86 136.323H179.856Z" fill="#F8BD84"/>
<path d="M239.23 128.9C223.593 138.755 225.958 117.386 229.093 106.92C228.397 116.733 234.564 109.974 235.983 111.255C238.64 113.659 238.097 118.163 239.233 128.9H239.23Z" fill="#7A9E18"/>
<path d="M204.918 280.809C207.774 279.433 204.318 284.106 207.382 282.849C209.871 283.396 207.753 280.979 210.192 279.973C216.478 291.18 197.577 292.51 204.918 280.809Z" fill="#661A2E"/>
<path d="M199.867 112.347C201.25 119.901 200.022 125.033 199.881 128.661C199.779 131.224 189.571 130.253 189.211 128.03C188.935 126.332 189.507 124.249 189.973 121.507C191.102 114.874 190.958 113.173 191.191 109.647C190.781 105.588 197.947 102.171 199.867 112.347Z" fill="#7A9E18"/>
<path d="M197.169 57.8113C203.402 48.4575 218.714 47.7833 226.713 55.0969C233.243 64.0271 228.756 78.206 229.236 88.8093C221.623 101.389 205.76 100.051 197.557 88.7457C197.557 78.4672 196.756 67.998 197.165 57.8113H197.169ZM208.743 85.6537C205.425 89.6423 217.948 92.4308 218.979 86.8997C218.778 83.9524 211.38 86.4091 208.743 85.6537ZM214.514 72.4737C214.133 75.6187 215.7 79.0567 216.265 81.5592C217.722 79.6179 215.167 74.9481 214.514 72.4737Z" fill="#F8BD84"/>
<path d="M222.57 73.9883C224.695 73.233 228.761 73.6848 228.74 76.5015C227.889 81.3337 218.169 78.4711 222.57 73.9883Z" fill="#F8BD84"/>
<path d="M204.585 74.0161C207.907 78.8836 201.077 80.2673 198.754 77.6906C196.615 74.2491 202.228 73.0384 204.585 74.0161Z" fill="#F8BD84"/>
<path d="M218.408 85.3584C221.942 87.0809 215.641 90.7342 213.227 90.1729C211.165 90.6777 206.379 88.3587 208.747 85.6549C210.096 89.054 217.251 88.994 218.408 85.3584Z" fill="#AC2C2D"/>
<path d="M218.408 85.3584C217.282 89.6188 209.647 89.0011 208.747 85.6549C211.896 85.6514 215.263 85.3619 218.408 85.3584Z" fill="#FDFDFD"/>
<path d="M205.154 64.6183C212.436 66.1502 204.663 66.7714 201.977 67.0221C198.8 66.5879 204.194 64.5513 205.154 64.6183Z" fill="#522A2F"/>
<path d="M222.016 64.6183C214.734 66.1502 222.506 66.7714 225.192 67.0221C228.369 66.5879 222.976 64.5513 222.016 64.6183Z" fill="#522A2F"/>
<path d="M231.474 167.506C230.574 153.898 226.967 135.385 227.486 122.611C226.141 116.483 228.785 111.115 227.93 107.186C227.602 105.679 228.28 105.562 226.801 104.842C224.764 103.854 220.377 102.658 219.463 103.6C214.867 108.319 208.75 104.182 208.75 104.182C208.75 104.182 196.509 103.021 199.869 112.35C199.237 122.833 201.14 133.948 198.058 144.082C197.988 149.963 193.166 161.074 195.824 165.494C205.757 162.077 229.413 176.563 231.478 167.506H231.474Z" fill="#F37617"/>
<path d="M191.367 67.1268C189.514 44.1272 223.646 31.1978 233.021 52.8774C239.061 66.8409 234.401 88.0263 236.484 111.728C231.758 115.064 225.976 112.205 225.976 112.205C223.696 104.312 227.78 96.8506 228.164 89.6994C228.997 74.2639 234.807 48.4405 213.467 51.6878C190.471 46.365 203.958 111.704 201.434 112.533C197.449 113.843 191.399 112.893 191.194 111.743C189.045 99.7309 193.316 74.5498 191.367 67.1233V67.1268Z" fill="#522537"/>
<path d="M205.243 72.5906C204.717 72.3647 203.45 71.4717 204.692 69.7103C203.615 68.7256 201.127 69.0009 201.127 69.0009C201.127 69.0009 205.243 68.2526 206.249 68.5738C208.232 69.5303 207.925 73.0389 205.243 72.5941V72.5906Z" fill="#2C2E2B"/>
<path d="M222.351 72.5553C222.884 72.3506 224.186 71.5105 223.022 69.6997C224.137 68.7608 226.611 69.135 226.611 69.135C226.611 69.135 222.531 68.2173 221.514 68.4961C219.495 69.3679 219.654 72.8871 222.351 72.5553Z" fill="#2C2E2B"/>
<path d="M72.3066 33.3082C72.596 36.439 72.9419 54.7618 70.6123 46.0399C70.6688 43.8373 68.9392 44.8963 67.958 44.5892C67.7533 40.41 67.7356 36.3755 67.132 32.2705C64.5765 15.2148 70.4606 5.3881 88.3492 5.41987C91.7695 4.97159 95.5392 3.71501 97.29 0.566503C97.5547 0.0617527 97.77 -0.361814 98.2324 0.47473C99.0442 1.75602 97.9783 7.53417 99.8349 4.00092C101.815 3.89503 106.552 2.07016 108.68 4.00092C94.985 15.7019 121.758 4.04328 115.175 32.6164C114.314 38.8851 116.735 47.9636 111.236 48.7931C108.147 49.259 112.86 32.1151 109.033 28.8925C99.4501 20.8271 89.221 28.8713 79.0519 26.9688C75.1445 26.7358 71.8583 30.4456 72.3066 33.3011V33.3082Z" fill="#2C2E2B"/>
<path d="M82.2976 44.5823C82.6933 44.5823 83.0141 44.1383 83.0141 43.5905C83.0141 43.0427 82.6933 42.5986 82.2976 42.5986C81.9019 42.5986 81.5811 43.0427 81.5811 43.5905C81.5811 44.1383 81.9019 44.5823 82.2976 44.5823Z" fill="white"/>
<path d="M100.716 44.4871C101.111 44.4871 101.432 44.0431 101.432 43.4953C101.432 42.9475 101.111 42.5034 100.716 42.5034C100.32 42.5034 99.999 42.9475 99.999 43.4953C99.999 44.0431 100.32 44.4871 100.716 44.4871Z" fill="white"/>
<path d="M146.662 41.8319C146.97 41.8319 147.22 41.5807 147.22 41.2707C147.22 40.9607 146.97 40.7095 146.662 40.7095C146.354 40.7095 146.104 40.9607 146.104 41.2707C146.104 41.5807 146.354 41.8319 146.662 41.8319Z" fill="white"/>
<path d="M165.274 41.6868C165.584 41.6868 165.835 41.4498 165.835 41.1574C165.835 40.865 165.584 40.6279 165.274 40.6279C164.964 40.6279 164.713 40.865 164.713 41.1574C164.713 41.4498 164.964 41.6868 165.274 41.6868Z" fill="white"/>
<path d="M206.044 71.3553C206.354 71.3553 206.605 71.1183 206.605 70.8258C206.605 70.5334 206.354 70.2964 206.044 70.2964C205.734 70.2964 205.482 70.5334 205.482 70.8258C205.482 71.1183 205.734 71.3553 206.044 71.3553Z" fill="white"/>
<path d="M221.888 71.3553C222.198 71.3553 222.45 71.1183 222.45 70.8258C222.45 70.5334 222.198 70.2964 221.888 70.2964C221.578 70.2964 221.327 70.5334 221.327 70.8258C221.327 71.1183 221.578 71.3553 221.888 71.3553Z" fill="white"/>
<path d="M40.364 56.9715C40.6739 56.9715 40.9252 56.7345 40.9252 56.4421C40.9252 56.1496 40.6739 55.9126 40.364 55.9126C40.054 55.9126 39.8027 56.1496 39.8027 56.4421C39.8027 56.7345 40.054 56.9715 40.364 56.9715Z" fill="white"/>
<path d="M23.28 56.9715C23.5899 56.9715 23.8412 56.7345 23.8412 56.4421C23.8412 56.1496 23.5899 55.9126 23.28 55.9126C22.97 55.9126 22.7188 56.1496 22.7188 56.4421C22.7188 56.7345 22.97 56.9715 23.28 56.9715Z" fill="white"/>
<path d="M37.1972 71.3765C40.9175 72.6895 34.2817 75.4709 31.7367 75.0474C29.5624 75.4321 24.522 73.6637 27.0175 71.6024C28.4364 74.1932 35.9759 74.1473 37.1972 71.3765Z" fill="#AC2C2D"/>
<path d="M27.0154 71.6025C25.0776 71.1295 31.3005 70.3671 32.6241 70.5224C33.7536 70.3848 38.5576 70.7201 37.2586 71.4684C34.4984 71.1684 28.872 71.3413 27.0154 71.6061V71.6025Z" fill="#AC2C2D"/>
</svg>
</template>


<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'WhatIWillHaveSVG1',
  components: {
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>